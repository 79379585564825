import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { LanguageApi, type LanguageDto } from '../../../common/services'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import ListLanguage from '../components/helpers/LanguageJson.json'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface languageProp extends LanguageDto {
  country?: string
  countryCode?: string
  language?: string
}
interface languageModalProps {
  language?: LanguageDto
  open: boolean
  onClose: () => void
  isEditing: boolean
}
export default function LanguageModal ({ language, open, onClose, isEditing }: languageModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState<languageProp>({
    name: '',
    code: '',
    flag: null,
    country: '',
    countryCode: '',
    language: ''

  })
  useEffect(() => {
    if (language != null) {
      const selectedLanguage = updatedListLanguage.find((lang) => lang.code === language.code)
      if (selectedLanguage != null) {
        setInitialValues({
          name: language.name,
          code: language.code,
          countryCode: selectedLanguage.countryCode,
          flag: `/svg/${(selectedLanguage.countryCode ?? '').toLowerCase()}.svg`,
          country: selectedLanguage.country ?? '',
          id: language.id
        })
      }
    }
  }, [language])
  useEffect(() => {
    void formik.setValues(initialValues)
  }, [initialValues])
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_LanguageName_invalid) ?? '')
  })

  const handleSubmit = async (values: LanguageDto): Promise<void> => {
    console.log(values)
    try {
      setIsLoading(true)

      if (language?.id != null) {
        const response = await new LanguageApi().apiV2LanguagePut(values)

        if (response.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          onClose()
          toast.success(t(KeyTranslation.Message_Update_success))
        }
      } else {
        const response = await new LanguageApi().apiV2LanguagePost(values)

        if (response.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          onClose()
          toast.success(t(KeyTranslation.Message_CreateNew_Success))
        }
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response?.data?.error}`))
    } finally {
      setIsLoading(false)
    }
  }
  const updatedListLanguage = ListLanguage.map((language: languageProp) => {
    if ((language.code ?? '').includes('-')) {
      const countryCode = (language.code ?? '').split('-')[1]
      return { ...language, countryCode }
    } else {
      return language
    }
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalContent onClose={onClose} title={isEditing ? t(KeyTranslation.Language_Edit_Language) : t(KeyTranslation.Language_New_Language)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Country)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Country)}
                  value={formik.values.country}
                  onChange={(e) => {
                    const selectedLanguage = updatedListLanguage.find((lang) => lang.country === e.target.value)
                    if (selectedLanguage != null) {
                      void formik.setValues({
                        ...formik.values,
                        name: selectedLanguage.language,
                        code: selectedLanguage.code,
                        country: selectedLanguage.country,
                        flag: `/svg/${(selectedLanguage.countryCode ?? '').toLowerCase()}.svg`,
                        countryCode: selectedLanguage.countryCode
                      })
                    }
                  }}
                >
                  {ListLanguage?.map((language: languageProp, index: number) => (
                    <MenuItem key={index} value={language.country}>
                      {language.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={5}>
              <TextField
                value={formik.values.name}
                label={t(KeyTranslation.Language_LanguageName)}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  void formik.setFieldValue('name', e.target.value)
                }}
              />
            </Box>
            <Box my={5}>
              <TextField
                value={formik.values.code}
                label={t(KeyTranslation.Header_CountryCode)}
                variant="outlined"
                fullWidth
                disabled
              />
            </Box>
            {(formik.values.countryCode !== '') && (
              <Box sx={{ textAlign: 'center', mb: -5 }}>
                <img src={`/svg/${(formik.values.countryCode ?? '').toLowerCase()}.svg`} alt={formik.values.country ?? ''} className="img" />
              </Box>
            )}
            <Box display="flex" justifyContent="end" gap={4} mt={5}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
