/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Alert } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

interface ErrorAlertProps {
  errors: any
  touched: any
}

export default function AlertProduct ({ errors, touched }: ErrorAlertProps): JSX.Element {
  return (
    <div>
      {Object.keys(errors).map((errorKey) => (
        <div key={errorKey}>
          {Object.keys(errors[errorKey]).map((fieldKey) => (
            (Boolean(errors[errorKey][fieldKey])) && Object.keys(touched).length > 0
              ? (
              <Alert sx={{ py: 0 }} key={fieldKey} severity="error">
                {t(`${errors[errorKey][fieldKey]}`)}
              </Alert>
                )
              : null
          ))}
        </div>
      ))}
    </div>
  )
}
