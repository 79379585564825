/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BooleanApiResponse } from '../models';
// @ts-ignore
import { Int32ApiResponse } from '../models';
// @ts-ignore
import { ProductCreateDto } from '../models';
// @ts-ignore
import { ProductDtoApiResponse } from '../models';
// @ts-ignore
import { ProductDtoIEnumerableApiResponse } from '../models';
// @ts-ignore
import { ProductInfoDtoIEnumerableApiResponse } from '../models';
// @ts-ignore
import { ProductPagingRequestDto } from '../models';
// @ts-ignore
import { SelectionProductIEnumerableApiResponse } from '../models';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetByIdGet: async (productId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetComponentsGet: async (countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product/GetComponents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (standardId !== undefined) {
                localVarQueryParameter['StandardId'] = standardId;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['UnitId'] = unitId;
            }

            if (systemTypeId !== undefined) {
                localVarQueryParameter['SystemTypeId'] = systemTypeId;
            }

            if (applicationLocationId !== undefined) {
                localVarQueryParameter['ApplicationLocationId'] = applicationLocationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetFilterProductsGet: async (countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product/GetFilterProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (standardId !== undefined) {
                localVarQueryParameter['StandardId'] = standardId;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['UnitId'] = unitId;
            }

            if (systemTypeId !== undefined) {
                localVarQueryParameter['SystemTypeId'] = systemTypeId;
            }

            if (applicationLocationId !== undefined) {
                localVarQueryParameter['ApplicationLocationId'] = applicationLocationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductPagingRequestDto} [productPagingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetProductsByPagingPost: async (productPagingRequestDto?: ProductPagingRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product/GetProductsByPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPagingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV2ProductIdDelete', 'id', id)
            const localVarPath = `/api/v2/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductPost: async (productCreateDto?: ProductCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductPut: async (productCreateDto?: ProductCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductToggleFavoritePut: async (productId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Product/ToggleFavorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductGetByIdGet(productId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductGetByIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductGetComponentsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionProductIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductGetComponentsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductGetFilterProductsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionProductIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductGetFilterProductsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductPagingRequestDto} [productPagingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductGetProductsByPagingPost(productPagingRequestDto?: ProductPagingRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductGetProductsByPagingPost(productPagingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductPost(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductPost(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductPut(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductPut(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProductToggleFavoritePut(productId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProductToggleFavoritePut(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGet(options?: any): AxiosPromise<ProductDtoIEnumerableApiResponse> {
            return localVarFp.apiV2ProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetByIdGet(productId?: number, options?: any): AxiosPromise<ProductDtoApiResponse> {
            return localVarFp.apiV2ProductGetByIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetComponentsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: any): AxiosPromise<SelectionProductIEnumerableApiResponse> {
            return localVarFp.apiV2ProductGetComponentsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {number} [standardId] 
         * @param {number} [unitId] 
         * @param {number} [systemTypeId] 
         * @param {number} [applicationLocationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetFilterProductsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: any): AxiosPromise<SelectionProductIEnumerableApiResponse> {
            return localVarFp.apiV2ProductGetFilterProductsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductPagingRequestDto} [productPagingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductGetProductsByPagingPost(productPagingRequestDto?: ProductPagingRequestDto, options?: any): AxiosPromise<ProductInfoDtoIEnumerableApiResponse> {
            return localVarFp.apiV2ProductGetProductsByPagingPost(productPagingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductIdDelete(id: number, options?: any): AxiosPromise<BooleanApiResponse> {
            return localVarFp.apiV2ProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductPost(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<Int32ApiResponse> {
            return localVarFp.apiV2ProductPost(productCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductPut(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<BooleanApiResponse> {
            return localVarFp.apiV2ProductPut(productCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProductToggleFavoritePut(productId?: number, options?: any): AxiosPromise<BooleanApiResponse> {
            return localVarFp.apiV2ProductToggleFavoritePut(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductGetByIdGet(productId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductGetByIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [countryId] 
     * @param {number} [standardId] 
     * @param {number} [unitId] 
     * @param {number} [systemTypeId] 
     * @param {number} [applicationLocationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductGetComponentsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductGetComponentsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [countryId] 
     * @param {number} [standardId] 
     * @param {number} [unitId] 
     * @param {number} [systemTypeId] 
     * @param {number} [applicationLocationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductGetFilterProductsGet(countryId?: number, standardId?: number, unitId?: number, systemTypeId?: number, applicationLocationId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductGetFilterProductsGet(countryId, standardId, unitId, systemTypeId, applicationLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductPagingRequestDto} [productPagingRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductGetProductsByPagingPost(productPagingRequestDto?: ProductPagingRequestDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductGetProductsByPagingPost(productPagingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductPost(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductPost(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductPut(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductPut(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiV2ProductToggleFavoritePut(productId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiV2ProductToggleFavoritePut(productId, options).then((request) => request(this.axios, this.basePath));
    }
}
