import React from 'react'
import ReactDOM from 'react-dom/client'
import Main from './layouts/main'
import reportWebVitals from './reportWebVitals'

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import './scss/style.scss'
import MMMTheme from './common/3mTheme'
import './common/api'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import './common/i18n'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import { LicenseInfo } from '@mui/x-license-pro'
import { Login, Projects, ProjectDetail, Notes, Revisions, Reports, DocumentProject, OverviewSystem, Languages, Translations, Products, Regions, Countries, Documents, ReportTemplate, Standards, Systems } from './pages/index'

LicenseInfo.setLicenseKey(
  'ac4670d20df712b31ce552e0b8292713Tz02MzQ2NyxFPTE3MTIxMjgzMzM3NzcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)
const router = createBrowserRouter([
  {
    path: '/login/:token',
    element: <Login />
  },
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '/',
        element: <Projects />
      },
      // {
      //   path: '/',
      //   element: <Dashboard />
      // },
      // {
      //   path: '/projects',
      //   element: <Projects />
      // },
      {
        path: '/projects/:id',
        element: <ProjectDetail />
      },
      {
        path: '/projects/:id/notes',
        element: <Notes />
      },
      {
        path: '/projects/:id/revisions',
        element: <Revisions />
      },
      {
        path: '/projects/:id/reports',
        element: <Reports />
      },
      {
        path: '/projects/:id/documents',
        element: <DocumentProject />
      },
      {
        path: '/projects/:id/system-overview/:systemId',
        element: <OverviewSystem />
      },
      {
        path: '/projects/:id/system/:systemId',
        element: <Systems />
      },
      // Settings routes
      {
        path: '/languages',
        element: <Languages />
      },
      {
        path: '/translations',
        element: <Translations />
      },
      // {
      //   path: '/organizations',
      //   element: <Organizations />
      // },
      {
        path: '/products',
        element: <Products />
      },
      {
        path: '/regions',
        element: <Regions />
      },
      {
        path: '/countries',
        element: <Countries />
      },
      // {
      //   path: '/users',
      //   element: <Users />
      // },
      // {
      //   path: '/roles',
      //   element: <Roles />
      // },
      {
        path: '/documents',
        element: <Documents />
      },
      {
        path: '/reportTemplate',
        element: <ReportTemplate />
      },
      {
        path: '/standards',
        element: <Standards />
      }
    ]
  }
])

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MMMTheme}>
        <I18nextProvider i18n={i18next}>
          <RouterProvider router={router} />
        </I18nextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
