import { Alert } from '@mui/material'
import React from 'react'

interface FormAlertProps {
  formik: any
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function FormAlert ({ formik }: FormAlertProps) {
  return <>{Object.keys(formik.errors).length > 0 && Object.keys(formik.touched).length > 0 &&
        <Alert severity="error" sx={{ m: '20px auto' }}>{
            // eslint-disable-next-line array-callback-return
            Object.keys(formik.errors).map((key, index) => {
              if ((Boolean((formik.errors)[key])) && (Boolean((formik.touched)[key]))) { return <div key={index}>{(formik.errors)[key]}</div> }
            })
        }</Alert>
    }
    </>
}
