import * as React from 'react'
import { Box, Breadcrumbs, InputAdornment, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { ProjectApi, RevisionHistoryApi, type RevisionHistoryDto } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import 'react-toastify/dist/ReactToastify.css'
import { type GridColDef } from '@mui/x-data-grid-pro'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import moment from 'moment'
import KeyTranslation from '../../common/KeyTranslation.json'

export default function Revisions (): JSX.Element {
  const { id } = useParams()
  const [paginationNote, setPaginationNote] = React.useState({
    page: 0,
    pageSize: 10
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const {
    data,
    isFetching,
    refetch,
    isLoading
  } = useQuery(
    ['/api/v2/RevisionHistory/GetRevisionPaging', id, paginationNote, searchTerm],
    async () => {
      if (id !== undefined) {
        const { data } = await new RevisionHistoryApi().apiV2RevisionHistoryGetRevisionPagingGet(
          Number(id),
          paginationNote.page + 1,
          paginationNote.pageSize, searchTerm
        )
        return data
      }
    },
    { enabled: false }
  )

  React.useEffect(() => {
    void refetch()
  }, [paginationNote, searchTerm])
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm.length === 0) {
      return data.data
    }

    return data.data?.filter((revision: RevisionHistoryDto) => {
      const searchTermLower = searchTerm.toLowerCase()
      return (
        (String(revision.revision)?.includes(searchTermLower) ?? false) ||
        (revision.editedBy?.toLowerCase().includes(searchTermLower) ?? false) ||
        (revision.createdDate?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (revision.action?.toLowerCase().includes(searchTermLower) ??
          false)
      )
    })
  }, [data, searchTerm])
  const { data: projectData, refetch: projectRefetch } = useQuery(
    ['/api/v2/Project/GetProjectById', id],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        Number(id)
      )
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void projectRefetch()
  }, [])

  const columnsDummyRevision: Array<GridColDef<RevisionHistoryDto>> = [
    {
      field: 'revision',
      headerName: t(KeyTranslation.Header_RevisionId) ?? '',
      flex: 1
    },
    {
      field: 'action',
      headerName: t(KeyTranslation.Header_RevisionAction) ?? '',
      flex: 1,
      valueGetter: (params) => {
        const revision = params.row
        const inputData = (revision.inputData != null) ? JSON.parse(revision.inputData) : null
        let status = ''

        switch (revision.action) {
          case 'CreateReport':
          case 'AddSystem':
          case 'AddNote':
            status = 'is added!'
            break
          case 'DeleteReport':
          case 'DeleteSystem':
          case 'DeleteDocument':
          case 'DeleteNote':
            status = 'is deleted!'
            break
          case 'UpdateSystem':
          case 'UpdateProject':
          case 'UploadDocument':
            status = 'is updated!'
            break
          case 'Calculation':
          case 'CalculationForAll':
          case 'CalculationForIntel':
            status = 'is calculated!'
            break
          default:
            status = ''
        }

        const actionName = revision.action ?? ''
        const itemName = inputData?.Name ?? inputData?.FileName ?? inputData?.Title
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const formattedAction = `${t(`${actionName ?? ''}`)}: ${itemName} ${status}`
        return formattedAction
      }

    },
    {
      field: 'createdDate',
      headerName: t(KeyTranslation.Header_CreatedDate) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        const inputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'
        const outputFormat = 'YYYY-MM-DD HH:mm'
        const convertedTime = moment(row.createdDate, inputFormat).format(outputFormat)
        return <div>{convertedTime}</div>
      }
    },
    {
      field: 'editedBy',
      headerName: t(KeyTranslation.Header_EditedBy) ?? '',
      width: 120
    }
  ]
  return (
    <Box>
      <Grid container spacing={2} mb={4} alignItems="center">
        <Grid xs={4}>
          <Typography variant="h1" mb={4}>
            {projectData?.data?.projectName}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant="caption">
            {t(KeyTranslation.Common_CustomerName)}:{' '}
          </Typography>
          <Typography component={'span'}>
            {projectData?.data?.customerName}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_System_Ref)}:{' '}
          </Typography>
          <Typography component={'span'}>
            {projectData?.data?.reference}
          </Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component="div"
          label={<Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>}
        />
        <Breadcrumb
          component="div"
          label={
            <Link to={`/projects/${Number(id)}`}>
              {t(KeyTranslation.Common_ProjectOverview)}
            </Link>
          }
        />
        <Breadcrumb component="div" label={t(KeyTranslation.Revisions)} />
      </Breadcrumbs>
      <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size="small"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
          <Box sx={{ mt: 5 }} className="tableTools">
            <Typography variant="h4">{t(KeyTranslation.Revision_RevisionHistory)}</Typography>
          </Box>
        <Box>
          <StyledDataGrid
            autoHeight
            loading={isLoading || isFetching}
            rows={filteredData ?? []}
            columns={columnsDummyRevision}
            rowSelection={false}
            disableColumnMenu={true}
            paginationMode="server"
            onPaginationModelChange={setPaginationNote}
            getRowId={(row) => row.revision}
            paginationModel={paginationNote}
            rowCount={data?.totals ?? 0}
          />
        </Box>
      </Box>
    </Box>
  )
}
