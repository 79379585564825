import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toBase64 } from '../../../common/helper'
import { type UploadDto } from '../../../common/services'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface DropzoneProps {
  // formik: any
  handleFileAvatarChange: (newAvatar: UploadDto) => void
  productUrl: string
  isEditing: boolean
  isCopying: boolean
}

interface FilePreview {
  preview: string
  name: string
  size: number
  type: string
}

const dropzone: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  border: '2px dashed #ccc',
  borderRadius: 4,
  cursor: 'pointer'
}

const thumbsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumb: React.CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 16,
  marginRight: 8,
  padding: 4,
  boxSizing: 'border-box'
}

const thumbInner: React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}

const img: React.CSSProperties = {
  display: 'block',
  width: '80px',
  height: 'auto',
  margin: '0px auto',
  objectFit: 'contain'
}

const modal: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

export default function Dropzone ({ handleFileAvatarChange, productUrl, isEditing, isCopying }: DropzoneProps): JSX.Element {
  const { t } = useTranslation()
  const [files, setFiles] = React.useState<FilePreview[]>([])
  const thumbs = files.map(file => (
    <Box style={thumb} key={file.name}>
      <Box style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </Box>
    </Box>
  ))

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async acceptedFiles => {
      const selectedFile = acceptedFiles[0]

      const base64String = await toBase64(selectedFile)
      const base64WithoutPrefix = base64String?.substring(base64String.indexOf(',') + 1)
      const newAvatar: UploadDto = {
        fileContent: base64WithoutPrefix,
        fileName: selectedFile.name,
        fileType: selectedFile.type
      }
      handleFileAvatarChange(newAvatar)
      setFiles([
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile)
        })
      ])
    }
  })

  useEffect(() => {
    return () => {
      files.forEach(file => { URL.revokeObjectURL(file.preview) })
    }
  }, [files])

  return (
    <Box className="container">
      <Box sx={{ marginBottom: 3 }}>
      {(isEditing || isCopying) && (productUrl != null) && <img src={productUrl} style={img} alt="images" />}
      </Box>
      <Box style={modal}>
        <Box {...getRootProps({ style: dropzone })} className="p10">
          <input {...getInputProps()} />
          <p>{t(KeyTranslation.Message_FileContent_invalid)}</p>
        </Box>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </Box>
    </Box>
  )
}
