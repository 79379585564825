import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { type UserChoicesReportProps } from './modalContainerReport'
import { useQuery } from '@tanstack/react-query'
import {
  BlobDocumentApi,
  ProjectDocumentApi,
  ReportApi,
  type ProjectDocumentDto
} from '../../../common/services'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import pdf from '../../../assets/images/mc-file-pdf.svg'
import unKnown from '../../../assets/images/mc-file-unknown.svg'
import sheet from '../../../assets/images/mc-file-spreadsheet.svg'
// eslint-disable-next-line import/no-duplicates
import png from '../../../assets/images/mc-file-image.svg'
// eslint-disable-next-line import/no-duplicates
import jpeg from '../../../assets/images/mc-file-image.svg'
import document from '../../../assets/images/mc-file-document.svg'
import plain from '../../../assets/images/mc-file-text.svg'
import CircularProgress from '@mui/material/CircularProgress'
import KeyTranslation from '../../../common/KeyTranslation.json'

const icons: Record<string, string> = {
  pdf,
  unKnown,
  sheet,
  png,
  jpeg,
  document,
  plain
}
interface AttachmentsReportModalProps {
  open: boolean
  onNextStep: () => void
  onBackStep: () => void
  saveUserChoice: (choice: any) => void
  userChoices?: UserChoicesReportProps | null
  onClose: () => void
  reportRefetch: any
}

export default function AttachmentsReportModal ({
  onBackStep,
  saveUserChoice,
  userChoices,
  onClose, reportRefetch
}: AttachmentsReportModalProps): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const projectId = Number(id.id)
  const [isLoading, setIsLoading] = useState(false)

  const [systemReportData] = React.useState({
    reportProjectDocuments: [],
    reportBlobDocuments: []
  })
  React.useEffect(() => {
    void formik.setValues({
      ...systemReportData,
      reportProjectDocuments: userChoices?.reportProjectDocuments ?? [],
      reportBlobDocuments: userChoices?.reportBlobDocuments ?? []
    })
  }, [userChoices])
  const { data: ProjectDocumentData, refetch: ProjectDocumentRefetch } =
    useQuery(
      ['/api/v2/ProjectDocument/GetByProject/{projectId}', projectId],
      async () => {
        const { data } =
          await new ProjectDocumentApi().apiV2ProjectDocumentGetByProjectProjectIdGet(
            projectId
          )
        return data
      },
      { enabled: false }
    )
  const { data: SupportingDocumentData, refetch: SupportingDocumentRefetch } =
    useQuery(
      ['/api/v2/BlobDocument/{id}', projectId],
      async () => {
        const { data } =
          await new BlobDocumentApi().apiV2BlobDocumentGetByProjectIdIdGet(
            projectId
          )
        return data
      },
      { enabled: false }
    )
  React.useEffect(() => {
    void ProjectDocumentRefetch()
    void SupportingDocumentRefetch()
  }, [])
  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const formattedNextInspectionDate =
        userChoices?.nextInspectionDate.toISOString()
      const params = {
        ...userChoices,
        nextInspectionDate: formattedNextInspectionDate
      }
      const response = await new ReportApi().apiV2ReportPost(params)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        toast.success(t(KeyTranslation.Message_CreateReport_success))
        onClose()
        window.location.href = `/projects/${projectId}/reports`
        reportRefetch()
        // revisionHistoryRefetch()
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: systemReportData,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        {isLoading && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}

        <Box py={3}>
          <Typography>
            {t(KeyTranslation.Select_The_Documents_That_You_Want_To_Include_In_The_Report)}
          </Typography>
        </Box>
        <Typography py={3} variant="h4">
          {t(KeyTranslation.Common_Photos_Documents)}
        </Typography>
        <Box>
          <Box py={3}>
            {ProjectDocumentData?.data?.map((project: ProjectDocumentDto) => (
              <MenuItem
                key={project.id}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <FormControlLabel
                    sx={{ marginRight: 0 }}
                    control={
                      <Checkbox
                        checked={formik.values.reportProjectDocuments.some(
                          (doc: any) => doc.documentId === project.id
                        )}
                        onChange={(event) => {
                          const isChecked = event.target.checked
                          const existingSelected =
                            formik.values.reportProjectDocuments

                          let newSelected
                          if (isChecked) {
                            newSelected = [
                              ...existingSelected,
                              { documentId: project.id }
                            ]
                          } else {
                            newSelected = existingSelected.filter(
                              (doc: any) => doc.documentId !== project.id
                            )
                          }

                          // Update formik values first
                          void formik.setFieldValue(
                            'reportProjectDocuments',
                            newSelected
                          )

                          saveUserChoice({
                            ...formik.values,
                            reportProjectDocuments: newSelected
                          })
                        }}
                      />
                    }
                    label=""
                  />
                  {(() => {
                    const type = project.type
                    const isImage = ['image/jpeg', 'image/png'].includes(type ?? '')
                    if (isImage) {
                      return (
                        <img
                          width={40}
                          height={40}
                          className='imageReport'
                          src={project.blobUrl ?? ''}
                          alt={project.name ?? ''}
                        />
                      )
                    } else {
                      const fileType = project.type?.split('/').pop()?.toLowerCase()
                      const iconUrl =
                        fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          ? icons.sheet
                          : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                            ? icons.document
                            : icons[fileType ?? 'unKnown'] ?? icons.unKnown

                      return (
                        <img
                          width={40}
                          height={40}
                          className='imageReport'
                          src={iconUrl}
                          alt={project.name ?? ''}
                        />
                      )
                    }
                  })()}
                  <Typography>{project.name}</Typography>
                </Box>
                {['image/jpeg', 'image/png'].some((ext) =>
                  project.type?.toLowerCase().includes(ext)
                ) && (
                    <TextField
                      style={{ width: '30%', marginLeft: '20px' }}
                      variant="outlined"
                      size="small"
                      placeholder={t(KeyTranslation.caption) ?? ''}
                      onChange={(event) => {
                        const projectDocumentId = project.id
                        const isProjectDocumentSelected =
                          // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                          formik.values.reportProjectDocuments.find(
                            (doc: any) => doc.documentId === projectDocumentId
                          )

                        let newSelected
                        if (isProjectDocumentSelected != null) {
                          newSelected = formik.values.reportProjectDocuments.map(
                            (doc: any) =>
                              doc.documentId === projectDocumentId
                                ? {
                                    ...doc,
                                    description: event.target.value
                                  }
                                : doc
                          )
                        } else {
                          const newSelectedDocument = {
                            documentId: projectDocumentId,
                            description: event.target.value
                          }
                          newSelected = [
                            ...formik.values.reportProjectDocuments,
                            newSelectedDocument
                          ]
                        }

                        // Update formik values
                        void formik.setFieldValue(
                          'reportProjectDocuments',
                          newSelected
                        )

                        // Update userChoice with the latest values
                        saveUserChoice({
                          ...formik.values,
                          reportProjectDocuments: newSelected
                        })
                      }}
                    />
                )}
              </MenuItem>
            ))}
            {SupportingDocumentData?.data
              ?.filter((blob: ProjectDocumentDto) => {
                const fileExtension = blob.type
                  ?.split('.')
                  .pop()
                  ?.toLowerCase()
                return fileExtension !== 'jpg' && fileExtension !== 'png'
              })
              .map((blob: ProjectDocumentDto) => (
                <MenuItem key={blob.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <FormControlLabel
                      sx={{ marginRight: 0 }}
                      control={
                        <Checkbox
                          checked={formik.values.reportBlobDocuments.some(
                            (selectedBlob: any) =>
                              selectedBlob.documentId === blob.id
                          )}
                          onChange={(event) => {
                            const isChecked = event.target.checked
                            const existingSelected =
                              formik.values.reportBlobDocuments

                            let newSelected
                            if (isChecked) {
                              newSelected = [
                                ...existingSelected,
                                { documentId: blob.id }
                              ]
                            } else {
                              newSelected = existingSelected.filter(
                                (doc: any) => doc.documentId !== blob.id
                              )
                            }

                            // Update formik values first
                            void formik.setFieldValue(
                              'reportBlobDocuments',
                              newSelected
                            )

                            // Update userChoice with the latest values
                            saveUserChoice({
                              ...formik.values,
                              reportBlobDocuments: newSelected
                            })
                          }}
                        />
                      }
                      label=""
                    />
                    {(() => {
                      const fileType = blob.type?.split('/').pop()?.toLowerCase()
                      const isImage = ['png', 'jpg', 'jpeg'].includes(
                        fileType ?? ''
                      )
                      if (isImage) {
                        return (
                          <img
                            width={40}
                            height={40}
                            className='imageReport'
                            src={blob.blobUrl ?? ''}
                            alt={blob.name ?? ''}
                          />
                        )
                      } else {
                        const iconUrl =
                          fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            ? icons.sheet
                            : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                              ? icons.document
                              : icons[fileType ?? 'unKnown'] ?? icons.unKnown

                        return (
                          <img
                            width={40}
                            height={40}
                            className='imageReport'
                            src={iconUrl}
                            alt={blob.name ?? ''}
                          />
                        )
                      }
                    })()}
                    <Typography>{blob.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            {(SupportingDocumentData?.data?.length === 0 && ProjectDocumentData?.data?.length === 0) && (
              <Typography variant="body2" color="textSecondary">
                {t(KeyTranslation.No_Data_Found)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          <Button onClick={onBackStep}>{t(KeyTranslation.Common_Back)}</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t(KeyTranslation.Common_Save)}
          </Button>
        </Box>
      </form>
    </>
  )
}
