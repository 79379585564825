import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Radio
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ThreeSides from '../../../assets/images/3sides.png'
import Conner from '../../../assets/images/conner.png'
import Custom from '../../../assets/images/custom.png'
import Perimeter from '../../../assets/images/perimeter.png'
import Straight from '../../../assets/images/straight.png'
import { SystemApi } from '../../../common/services'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface LayoutModalProps {
  open: boolean
  onNextStep: () => void
  onBackStep: () => void
  saveUserChoice: (choice: any) => void
  onClose: () => void
  userChoices: any
  systemRefetch: any
  revisionHistoryRefetch?: any
  projectId: number
  unitId: number | undefined
}

export default function LayoutModal ({
  onBackStep,
  saveUserChoice,
  onClose,
  userChoices,
  systemRefetch, revisionHistoryRefetch, projectId, unitId
}: LayoutModalProps): JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [projectData] = React.useState({
    layoutType: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    layoutType: Yup.string()
      .oneOf(
        ['Straight', 'Conner', '3sides', 'Perimeter', 'Custom'],
        t(KeyTranslation.Message_Layout_Invalid) ?? ''
      )
      .required(t(KeyTranslation.Message_Layout_Invalid) ?? '')
  })

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateLayoutData = (layoutType: string) => {
    let startPoint = ''
    const legs = []

    switch (layoutType) {
      case 'Straight':
        startPoint =
          '{"x":"396","y":"28","mapGroundRatio":"0.024565972222580473","originalCanvasWidth":"849","originalCanvasHeight":"849"}'
        legs.push({
          name: 'Leg 1',
          length: unitId === 2 ? 30 : 10,
          clearanceAmount: 0,
          angle: -90,
          spans: [
            {
              name: 'Sp1',
              length: unitId === 2 ? 30 : 10,
              components: []
            }
          ]
        })
        break
      case 'Conner':
        startPoint =
          '{"x":"56","y":"28","mapGroundRatio":"0.024565972222580473","originalCanvasWidth":"849","originalCanvasHeight":"849"}'
        legs.push(
          {
            name: 'Leg 1',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: -90,
            spans: [
              {
                name: 'Sp1',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 2',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp2',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          }
        )
        break
      case '3sides':
        startPoint =
          '{"x":"56","y":"28","mapGroundRatio":"0.024565972222580473","originalCanvasWidth":"849","originalCanvasHeight":"849"}'
        legs.push(
          {
            name: 'Leg 1',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: -90,
            spans: [
              {
                name: 'Sp1',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 2',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp2',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 3',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp3',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          }
        )
        break
      case 'Perimeter':
        startPoint =
          '{"x":"792","y":"28","mapGroundRatio":"0.024565972222580473","originalCanvasWidth":"849","originalCanvasHeight":"849"}'
        legs.push(
          {
            name: 'Leg 1',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 180,
            spans: [
              {
                name: 'Sp1',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 2',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp2',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 3',
            length: unitId === 2 ? 30 : 10,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp3',
                length: unitId === 2 ? 30 : 10,
                components: []
              }
            ]
          },
          {
            name: 'Leg 4',
            length: unitId === 2 ? 26 : 8,
            clearanceAmount: 0,
            angle: 90,
            spans: [
              {
                name: 'Sp4',
                length: unitId === 2 ? 26 : 8,
                components: []
              }
            ]
          }
        )
        break
      default:
        break
    }

    return {
      startPoint,
      legs
    }
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await new SystemApi().apiV2SystemPost(false, userChoices)
      const newSystemId = Number(response.data.data)
      if (response.status === 209) {
        onClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        systemRefetch()
        onClose()
        navigate(`/projects/${projectId}/system/${newSystemId}`)
      }
    } catch (ex: any) {
      console.log(ex)
    }
  }

  const formik = useFormik({
    initialValues: projectData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  const handleRadioChange = (value: string): void => {
    const layoutData = generateLayoutData(value)
    saveUserChoice(layoutData)
    void formik.setFieldValue('layoutType', value)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box mt={4} mb={7} sx={{ textAlign: 'center', position: 'relative' }}>
          <FormGroup className="containerCheckbox">
            <Box
              className="layout"
              onClick={() => {
                handleRadioChange('Straight')
              }}
            >
              <img
                src={Straight}
                alt="Background"
                style={{
                  border:
                    formik.values.layoutType === 'Straight'
                      ? '2px solid #00C8E6'
                      : 'none'
                }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formik.values.layoutType === 'Straight'}
                    onChange={() => {
                      handleRadioChange('Straight')
                    }}
                    value={'Straight'}
                    disabled={false}
                    size="small"
                  />
                }
                label={t(KeyTranslation.System_Popup_Straight)}
              />
            </Box>
            <Box
              className="layout"
              onClick={() => {
                handleRadioChange('Conner')
              }}
            >
              <img
                src={Conner}
                alt="Background"
                style={{
                  border:
                    formik.values.layoutType === 'Conner'
                      ? '2px solid #00C8E6'
                      : 'none'
                }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formik.values.layoutType === 'Conner'}
                    onChange={() => {
                      handleRadioChange('Conner')
                    }}
                    value={'Conner'}
                    disabled={false}
                    size="small"
                  />
                }
                label={t(KeyTranslation.Conner)}
              />
            </Box>
            <Box
              className="layout"
              onClick={() => {
                handleRadioChange('3sides')
              }}
            >
              <img
                src={ThreeSides}
                alt="Background"
                style={{
                  border:
                    formik.values.layoutType === '3sides'
                      ? '2px solid #00C8E6'
                      : 'none'
                }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formik.values.layoutType === '3sides'}
                    value={'3sides'}
                    disabled={false}
                    size="small"
                  />
                }
                label={t(KeyTranslation.threeSides)}
              />
            </Box>
            <Box
              className="layout"
              onClick={() => {
                handleRadioChange('Perimeter')
              }}
            >
              <img
                src={Perimeter}
                alt="Background"
                style={{
                  border:
                    formik.values.layoutType === 'Perimeter'
                      ? '2px solid #00C8E6'
                      : 'none'
                }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formik.values.layoutType === 'Perimeter'}
                    onChange={() => {
                      handleRadioChange('Perimeter')
                    }}
                    value={'Perimeter'}
                    disabled={false}
                  />
                }
                label={t(KeyTranslation.System_Popup_Perimeter)}
              />
            </Box>
            <Box
              className="layout"
              onClick={() => {
                handleRadioChange('Custom')
              }}
            >
              <img
                src={Custom}
                alt="Background"
                style={{
                  border:
                    formik.values.layoutType === 'Custom'
                      ? '2px solid #00C8E6'
                      : 'none'
                }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formik.values.layoutType === 'Custom'}
                    value={'Custom'}
                    disabled={false}
                  />
                }
                label={t(KeyTranslation.System_Popup_Custom)}
              />
            </Box>
          </FormGroup>
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          <Button onClick={onBackStep}>{t(KeyTranslation.Common_Back)}</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t(KeyTranslation.Common_Save)}
          </Button>
        </Box>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </form>
    </>
  )
}
