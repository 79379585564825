import React from 'react'
import { Box, Button, Card, CardContent, Grid, Modal, TextField, Typography } from '@mui/material'
import { ModalContent } from '../../common/components'
import { CommonApi, type ContentDto } from '../../common/services'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'

interface FeedbackModalProps {
  open: boolean
  onClose: () => void
}

export default function FeedbackModal ({ open, onClose }: FeedbackModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const [feedback, setFeedback] = React.useState('')

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const feedbackDto: ContentDto = { content: feedback }
      await new CommonApi().apiV2CommonSendFeedbackPost(feedbackDto)
      toast.success(t('Feedback submitted successfully!'))
    } catch (ex: any) {
      toast.error(t(ex.response?.data?.error))
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Feedback)}>
          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <Typography variant="h4" gutterBottom>
              {t(KeyTranslation.Information)}
              </Typography>
              <Grid container spacing={2} sx={{ my: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label={t(KeyTranslation.Feedback_Details)}
                    multiline
                    rows={4}
                    fullWidth
                    helperText={t(KeyTranslation.Please_Provide_Any_Details_For_Suggestions_Or_Issues_You_Are_Experiencing)}
                    value={feedback}
                    onChange={(event) => { setFeedback(event.target.value) }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button
                  disabled={isLoading || feedback.trim() === ''}
                  // eslint-disable-next-line no-void
                  onClick={ () => void handleSubmit()}
                  variant="contained"
                >
                  {t(KeyTranslation.Common_Submit)}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </ModalContent>
      </Box>
    </Modal>
  )
}
