import React from 'react'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import bgLocation from '../../../assets/images/roof.png'
import { useParams } from 'react-router-dom'
import { SystemApi, type ProjectDto, type SystemInfoDto } from '../../../common/services'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface LocationModalProps {
  open: boolean
  onClose: () => void
  onNextStep?: () => void
  onBackStep?: () => void
  saveUserChoice?: (choice: any) => void
  title: string
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch?: any
  userChoices?: UserChoicesProps | null
  unitId: number | undefined
}

export default function RoofModel ({
  title,
  onClose,
  onNextStep = () => {},
  onBackStep = () => {},
  saveUserChoice = () => {},
  project, systemData, systemDatarefetch, userChoices, unitId
}: LocationModalProps): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const [locationMetricState, setLocationMetricState] = React.useState<{
    edgeHeight: string | undefined
    edge: string | undefined
    ground: string | undefined
  }>({
    edgeHeight: undefined,
    edge: undefined,
    ground: undefined
  })
  React.useEffect(() => {
    if (userChoices?.locationMetrics != null) {
      const locationMetricsArrayChoices = JSON.parse(userChoices.locationMetrics)
      void formik.setValues({
        ...locationMetricState,
        edgeHeight: locationMetricsArrayChoices[0],
        edge: locationMetricsArrayChoices[1],
        ground: locationMetricsArrayChoices[2]
      })
    }
  }, [userChoices])
  React.useEffect(() => {
    const locationMetricsArray = ((systemData?.locationMetrics) != null) ? JSON.parse(systemData.locationMetrics) : null
    setLocationMetricState((prevState) => ({
      ...prevState,
      edgeHeight: locationMetricsArray?.[0] ?? undefined,
      edge: locationMetricsArray?.[1] ?? undefined,
      ground: locationMetricsArray?.[2] ?? undefined
    }))
  }, [systemData?.locationMetrics])
  const validationSchema = Yup.object().shape({
    edge: Yup.number()
      .required(t(KeyTranslation.LocationMetrics_invalid) ?? '')
      .notOneOf([0], t(KeyTranslation.LocationMetrics_invalid) ?? ''),
    ground: Yup.number()
      .required(t(KeyTranslation.LocationMetrics_invalid) ?? '')
      .notOneOf([0], t(KeyTranslation.LocationMetrics_invalid) ?? ''),
    edgeHeight: Yup.number()
      .required(t(KeyTranslation.LocationMetrics_invalid) ?? '')
      .notOneOf([0], t(KeyTranslation.LocationMetrics_invalid) ?? '')
  })
  const handleSubmit = async (): Promise<void> => {
    try {
      const unitId = project?.unitId
      const locationMetrics = [formik.values.edgeHeight, formik.values.edge, formik.values.ground]
      const locationMetricsString = JSON.stringify(locationMetrics)
      const updatedData = {
        ...systemData,
        locationMetrics: locationMetricsString
      }

      if (id.systemId != null) {
        await new SystemApi().apiV2SystemPut(unitId, updatedData)
        onClose()
        systemDatarefetch()
      } else {
        const userChoice = {
          locationMetrics: locationMetricsString
        }
        saveUserChoice(userChoice)
        onNextStep()
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  const formik = useFormik({
    initialValues: locationMetricState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <>
    {id.systemId != null &&
    (
      <Typography style={{ textAlign: 'center', marginBottom: 16 }} variant="h3">
        {title}
      </Typography>
    )
    }
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box
          mt={4}
          mb={7}
          sx={{ textAlign: 'center', position: 'relative', margin: 0 }}
        >
          <img src={bgLocation} className='img480' />
          <TextField
            size="small"
            type="number"
            className="inputRoof-bottom inputSmall"
            value={formik.values.edgeHeight}
            onChange={(event) => {
              const value = Number(event.target.value)
              let maxValue = 20
              if (unitId === 2) {
                maxValue = 60
              }
              const newValue = Math.min(value, maxValue)
              void formik.setFieldValue('edgeHeight', newValue)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unitId === 2 ? 'ft' : 'm'}</InputAdornment>
              )
            }}
            inputProps={{
              min: 0,
              max: unitId === 2 ? 60 : 20,
              step: 1
            }}
            helperText={ unitId === 2 ? 'Max: 60 ft' : 'Max: 20 m'}
          />

          <TextField
            type="number"
            size="small"
            className="inputRoof-middle inputSmall"
            value={formik.values.edge}
            onChange={(event) => {
              const value = Number(event.target.value)
              let maxValue = 20
              if (unitId === 2) {
                maxValue = 60
              }
              const newValue = Math.min(value, maxValue)
              void formik.setFieldValue('edge', newValue)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unitId === 2 ? 'ft' : 'm'}</InputAdornment>
              )
            }}
            inputProps={{
              min: 0,
              max: unitId === 2 ? 60 : 20,
              step: 1
            }}
            helperText={ unitId === 2 ? 'Max: 60 ft' : 'Max: 20 m'}
          />
          <TextField
            type="number"
            size="small"
            className="inputRoof-top inputSmall"
            value={formik.values.ground}
            onChange={(event) => {
              const value = Number(event.target.value)
              let maxValue = 20
              if (unitId === 2) {
                maxValue = 60
              }
              const newValue = Math.min(value, maxValue)
              void formik.setFieldValue('ground', newValue)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unitId === 2 ? 'ft' : 'm'}</InputAdornment>
              )
            }}
            inputProps={{
              min: 0,
              max: unitId === 2 ? 60 : 20,
              step: 1
            }}
            helperText={ unitId === 2 ? 'Max: 60 ft' : 'Max: 20 m'}
          />
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          {id.systemId != null
            ? (
            <>
              <Button
                onClick={() => {
                  onClose()
                } }
              >
                  {t(KeyTranslation.Common_Cancel)}
                </Button>
            <Button
               onClick={() => {
                 formik.handleSubmit()
               } }
              >
                {t(KeyTranslation.Save)}
              </Button>
                </>
              )
            : (
            <>
              <Button
                onClick={() => {
                  onBackStep()
                } }
              >
                  {t(KeyTranslation.Common_Back)}
                </Button>
            <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                } }
              >
                {t(KeyTranslation.next)}
              </Button>
                </>
              )}
        </Box>
      </form>
    </>
  )
}
