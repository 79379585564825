import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
interface TranslationEntry {
  key: string
  value: string
}
const options = {
  type: 'backend',
  crossDomain: false,
  allowMultiLoading: false,
  loadPath: `${
    process.env.REACT_APP_API_URL ?? ''
  }/api/v2/Language/GetTranslateDataByCode/{{lng}}`,
  parse (response: string) {
    const jsonData = JSON.parse(response)
    const data: TranslationEntry[] = jsonData.data
    const keyValuePairs: Record<string, string> = {}
    for (const entry of data) {
      keyValuePairs[entry.key] = entry.value
    }
    return keyValuePairs
  }

}

void i18n.use(HttpApi).init({
  backend: options,
  debug: false,
  initImmediate: false,
  fallbackLng: 'en-US',
  react: {
    useSuspense: false //   <---- this will do the magic
  }
})

export default i18n
