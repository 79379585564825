export const isPercentage = (val: string): boolean =>
  typeof val === 'string' && val.includes('%')

export const percentToPx = (value: any, comparativeValue: number): any => {
  if (value.indexOf('px') > -1 || value === 'auto' || (comparativeValue === 0)) { return value }
  const percent = parseInt(value)
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return (percent / 100) * comparativeValue + 'px'
}
export const pxToPercent = (value: any, comparativeValue: number): any => {
  const val = (Math.abs(value) / comparativeValue) * 100
  if (value < 0) return -1 * val
  else return Math.round(val)
}
export const getElementDimensions = (element: HTMLElement): any => {
  const computedStyle = getComputedStyle(element)

  let height = element.clientHeight
  let width = element.clientWidth // width with padding

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom)
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)

  return {
    width,
    height
  }
}
