import { InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'

export interface ToolbarTextInputProps {
  prefix?: string
  label?: string
  type: string
  onChange?: (value: any) => void
  value?: any
}
export const ToolbarTextInput = ({
  onChange,
  value,
  prefix,
  label,
  type,
  ...props
}: ToolbarTextInputProps): JSX.Element => {
  const [internalValue, setInternalValue] = useState(value)
  const [active, setActive] = useState(false)
  useEffect(() => {
    let val = value
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (type === 'color' || type === 'bg') { val = `rgba(${Object.values(value)})` }
    setInternalValue(val)
  }, [value, type])

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onClick={() => {
        setActive(true)
      }}
    >
      {(type === 'color' || type === 'bg') && active
        ? (
        <div
          className="absolute"
          style={{
            zIndex: 99999,
            top: 'calc(100% + 10px)',
            left: '-5%'
          }}
        >
          <div
            className="fixed top-0 left-0 w-full h-full cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setActive(false)
            }}
          ></div>
          <ChromePicker
            color={value}
            onChange={(color: any) => {
              if (typeof onChange === 'function') {
                onChange(color.rgb)
              }
            }}
          />
        </div>
          )
        : null}
      <TextField
        label={label} variant="outlined"
        style={{ marginTop: 20, width: '100%' }}
        value={internalValue ?? ''}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (typeof onChange === 'function') {
              onChange((e.target as any).value)
            }
          }
        }}
        onChange={(e) => {
          setInternalValue(e.target.value)
        }}
        margin="dense"
        InputProps={{
          disableUnderline: true,
          startAdornment: ['color', 'bg'].includes(type)
            ? (
            <InputAdornment
              position="start"
              style={{
                position: 'absolute',
                marginTop: '2px',
                marginRight: '8px'
              }}
            >
              <div
                className="w-2 h-2 inline-block rounded-full relative"
                style={{
                  left: '15px',
                  background: internalValue
                }}
              />
            </InputAdornment>
              )
            : null
        }}
        InputLabelProps={{
          shrink: true
        }}
        {...props}
      />
    </div>
  )
}
