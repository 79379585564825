import React from 'react'
import { Box, Modal } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { t } from 'i18next'
import WallModal from './modalWall'
import { type ProjectDto, type SystemInfoDto } from '../../../common/services'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface WallModalProps {
  open: boolean
  onClose: () => void
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch: any
  unitId: number | undefined
}

export default function ContainerWallModal ({ open, onClose, unitId, project, systemData, systemDatarefetch }: WallModalProps): JSX.Element {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Common_Location_metrics)}>
          {
            <WallModal
              open={open}
              onClose={onClose}
              title={t(KeyTranslation.System_Popup_Wall_middle)}
              project={project}
              systemData={systemData}
              systemDatarefetch={systemDatarefetch}
              unitId={unitId}
            />
          }
        </ModalContent>
      </Box>
    </Modal>
  )
}
