import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  ProductApi,
  SystemApi,
  type SystemInfoDto,
  type ProjectDto,
  type SelectionProduct
} from '../../../common/services'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface HarnessModalProps {
  open?: boolean
  onClose: () => void
  onNextStep?: () => void
  saveUserChoice?: (choice: any) => void
  onBackStep?: () => void
  title: string
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch?: any
  userChoices?: UserChoicesProps | null
  dataRroject?: ProjectDto | undefined
  onDataFetched?: (data: any) => void
  product?: SelectionProduct[] | null
  filterProducts?: SelectionProduct[] | null | undefined
  productDatarefetch?: any

}
export default function HarnessModal ({
  title,
  onClose,
  onNextStep = () => {},
  saveUserChoice = () => {},
  onBackStep = () => {},
  project,
  systemData,
  systemDatarefetch,
  userChoices,
  product, filterProducts, productDatarefetch
}: HarnessModalProps): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const [loading, setLoading] = React.useState(false)
  const [selectedProduct, setSelectedProduct] =
    React.useState<SelectionProduct | null>(null)
  const handleToggleFavorite = async (id: number): Promise<void> => {
    try {
      setLoading(true)
      await new ProductApi().apiV2ProductToggleFavoritePut(id)
      void productDatarefetch()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    if (userChoices?.harnesses != null && userChoices.harnesses.length > 0) {
      const productId = userChoices.harnesses[0].productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systeHarnessData,
          id: productId
        })
      }
    }
  }, [userChoices])
  React.useEffect(() => {
    if (systemData?.harnesses != null && systemData.harnesses.length > 0) {
      const productId = systemData.harnesses[0]?.productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systeHarnessData,
          id: productId
        })
      }
    }
  }, [systemData])
  const [systeHarnessData] = React.useState<SelectionProduct>({
    id: undefined
  })
  const validationSchema = Yup.object({
    id: Yup.number()
      .nullable()
      .required(t(KeyTranslation.Message_Harness_Invalid) ?? '')
      .typeError(t(KeyTranslation.Message_Harness_Invalid) ?? '')
  })
  const handleSubmit = async (values: any): Promise<void> => {
    try {
      setLoading(true)
      const unitId = project?.unitId
      const updatedData = {
        ...systemData,
        harnesses: [
          {
            productId: values.id
          }
        ]
      }

      if (id.systemId != null) {
        await new SystemApi().apiV2SystemPut(unitId, updatedData)
        onClose()
        systemDatarefetch()
      } else {
        const userChoice = {
          harnesses: [
            {
              productId: values.id
            }
          ]
        }
        saveUserChoice(userChoice)
        onNextStep()
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: systeHarnessData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  const handleProductSelect = (product: SelectionProduct): void => {
    setSelectedProduct(product)
    void formik.setFieldValue('id', product.id)
  }
  return (
    <>
     {id.systemId != null &&
    (
      <Typography style={{ textAlign: 'center', marginBottom: 16 }} variant="h3">
        {title}
      </Typography>
    )
    }
      <h3 style={{ textAlign: 'center' }} className='color7575'>{t(KeyTranslation.Common_Suggested_products)}</h3>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box>
          {loading
            ? (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
              )
            : (
            <Box
              mt={4}
              mb={7}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '20px',
                maxHeight: '400px',
                overflowY: 'scroll'
              }}
            >
              {(product ?? filterProducts)
                ?.filter(
                  (product: SelectionProduct) => product.productTypeId === 3
                )
                .map((product: SelectionProduct, index: number) => (
                  <MenuItem
                    key={index}
                    value={product.id ?? 0}
                    onClick={() => {
                      handleProductSelect(product)
                    }}
                    sx={{
                      flexDirection: 'column',
                      width: '30%',
                      border: selectedProduct?.id === product.id
                        ? '2px solid #00C8E6'
                        : '1px solid #ccc'
                    }}
                    className="MenuItemSystem"
                  >
                    <div></div>
                    <div className="wrap">{product.modelNo}</div>
                    <div>
                      <img
                      style={{
                        margin: '10px',
                        border: 'none'
                      }}
                        className="imgHarness"
                        src={product?.avatar ?? ''}
                        alt={product.name ?? ''}
                      />
                    </div>
                    <div className="bold">{product.name}</div>
                    <Tooltip title="Favorite">
                    <div
                      className={`favorite-product ${
                        product.isFavorite === true ? 'selected' : ''
                      }`}
                      onClick={() => {
                        void handleToggleFavorite(product.id ?? 0)
                      }}
                    ></div>
                    </Tooltip>
                  </MenuItem>
                ))}
            </Box>
              )}
        </Box>

        <Box display="flex" justifyContent="end" gap={4}>
          {id.systemId != null
            ? (
            <>
              <Button
                onClick={() => {
                  onClose()
                }}
              >
                {t(KeyTranslation.Common_Cancel)}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                {t(KeyTranslation.Save)}
              </Button>
            </>
              )
            : (
            <>
              <Button
                disabled={loading}
                onClick={() => {
                  onBackStep()
                }}
              >
                {t(KeyTranslation.Common_Back)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={loading}
              >
                {t(KeyTranslation.next)}
              </Button>
            </>
              )}
        </Box>
      </form>
    </>
  )
}
