import React, { useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ModalContent } from '../../../common/components'
import SystemModal from './modalSystem'
import ApplicationModal from './modalApplication'
import {
  FloorModel,
  FloorRaiseModal,
  HarnessModal,
  RoofModal,
  WallModal,
  TrackingSteps,
  PpeModal,
  HlleaModal,
  RopeModal,
  LayoutModal
} from './'
import { t } from 'i18next'
import TravellerModal from './modalTraveller'
import { useParams } from 'react-router-dom'
import { ProductApi, type ProjectDto, type SystemOptionDto } from '../../../common/services'
import { useTransition, animated } from 'react-spring'
import { useQuery } from '@tanstack/react-query'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface SystemModalProps {
  open: boolean
  onClose: () => void
  systemRefetch: any
  unitId: number | undefined
  dataRroject: ProjectDto | undefined
  revisionHistoryRefetch?: any
}

export interface UserChoicesProps {
  applicationLocationId: number
  name: string
  reference: string
  systemTypeId: number
  locationMetrics: string
  harnesses: SystemOptionDto[] | null
  ppEs: SystemOptionDto[] | null
  hlleAs: SystemOptionDto[] | null
  ropes: SystemOptionDto[] | null
  travellers: SystemOptionDto[] | null
  isAdjustableRopeGrab: boolean
  isFavorite: boolean
  selectedRadio: string
}

export default function ContainerModal ({
  open,
  onClose,
  systemRefetch,
  unitId, dataRroject, revisionHistoryRefetch
}: SystemModalProps): JSX.Element {
  const [step, setStep] = useState(1)
  const [userChoices, setUserChoices] = useState<UserChoicesProps | null>(null)
  const { id } = useParams()
  const projectId = Number(id)
  const filterProducts = useQuery(
    [
      '/api/v2/Product/GetFilterProducts',
      {
        countryId: dataRroject?.regionId,
        standardId: dataRroject?.standardId,
        unitId: dataRroject?.unitId,
        systemTypeId: userChoices?.systemTypeId,
        applicationLocationId: userChoices?.applicationLocationId
      }
    ],
    async () => {
      const { data } = await new ProductApi().apiV2ProductGetFilterProductsGet(
        dataRroject?.regionId,
        dataRroject?.standardId,
        dataRroject?.unitId,
        userChoices?.systemTypeId,
        userChoices?.applicationLocationId
      )
      return data
    },
    { enabled: userChoices?.systemTypeId !== undefined && userChoices?.applicationLocationId !== undefined }
  )
  React.useEffect(() => {
    if (userChoices?.systemTypeId !== undefined && userChoices?.applicationLocationId !== undefined) {
      void filterProducts.refetch()
    }
  }, [userChoices?.systemTypeId !== undefined, userChoices?.applicationLocationId !== undefined])
  const saveUserChoice = (choice: any): void => {
    setUserChoices({ ...userChoices, ...choice, projectId, numberOfUsers: 1, userWeight: unitId === 2 ? 220 : 100 })
  }
  const handleNextStep = (): void => {
    setStep((prevStep) => prevStep + 1)
  }
  const handleBackStep = (): void => {
    setStep((prevStep) => prevStep - 1)
  }
  const floor = userChoices?.applicationLocationId === 4
  const floorRaise = userChoices?.applicationLocationId === 3
  const wall = userChoices?.applicationLocationId === 2
  const roof = userChoices?.applicationLocationId === 1
  const transitions = useTransition(step, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' }
  })
  return (
    <Modal open={open}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Common_New_System)}
>         <Box className="p24">
          <TrackingSteps activeStep={step} />
          </Box>
          <Box sx={{ overflow: 'hidden' }}>
          {transitions((style, item) => (
            <animated.div style={{
              ...style
            }}>
              {step === 1 && (
                <SystemModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  saveUserChoice={saveUserChoice}
                  userChoices={userChoices}
                />
              )}
              {step === 2 && (
                <ApplicationModal
                  open={open}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  userChoices={userChoices}
                />
              )}
              {step === 3 && floor && (
                <FloorModel
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_Location_metrics)}
                  userChoices={userChoices}
                  unitId={unitId}
                />
              )}
              {step === 3 && floorRaise && (
                <FloorRaiseModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_Location_metrics)}
                  userChoices={userChoices}
                  unitId={unitId}
                />
              )}
              {step === 3 && wall && (
                <WallModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_Location_metrics)}
                  userChoices={userChoices}
                  unitId={unitId}
                />
              )}
              {step === 3 && roof && (
                <RoofModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  saveUserChoice={saveUserChoice}
                  onBackStep={handleBackStep}
                  title={t(KeyTranslation.Common_Location_metrics)}
                  userChoices={userChoices}
                  unitId={unitId}
                />
              )}
              {step === 4 && (
                <HarnessModal
                  onClose={onClose}
                  filterProducts={filterProducts.data?.data}
                  productDatarefetch={filterProducts.refetch}
                  open={open}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_Choose_Harness)}
                  userChoices={userChoices}
                  dataRroject={dataRroject}
                />
              )}
              {step === 5 && (
                <PpeModal
                  fetchedData={filterProducts.data?.data}
                  productDatarefetch={filterProducts.refetch}
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_Choose_PPE)}
                  userChoices={userChoices}
                />
              )}
              {step === 6 && (
                <RopeModal
                fetchedData={filterProducts.data?.data}
                productDatarefetch={filterProducts.refetch}
                open={open}
                onClose={onClose}
                onNextStep={handleNextStep}
                onBackStep={handleBackStep}
                saveUserChoice={saveUserChoice}
                title={t(KeyTranslation.Common_ChooseRope)}
                userChoices={userChoices}
                />
              )}
              {step === 7 && (
                <HlleaModal
                fetchedData={filterProducts.data?.data}
                productDatarefetch={filterProducts.refetch}
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  title={t(KeyTranslation.Common_ChooseHLLEA)}
                  userChoices={userChoices}
                />
              )}
              {step === 8 && (
                 <TravellerModal
                   fetchedData={filterProducts.data?.data}
                   productDatarefetch={filterProducts.refetch}
                   open={open}
                   onClose={onClose}
                   onNextStep={handleNextStep}
                   onBackStep={handleBackStep}
                   saveUserChoice={saveUserChoice}
                   title={t(KeyTranslation.Common_ChooseTraveller)}
                   userChoices={userChoices}
                 />
              )}
              {step === 9 && (
                <LayoutModal
                  userChoices={userChoices}
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  systemRefetch={systemRefetch}
                  revisionHistoryRefetch={revisionHistoryRefetch}
                  projectId={projectId}
                  unitId={unitId}
                />
              )}
            </animated.div>
          ))}
          </Box>
        </ModalContent>
      </Box>
    </Modal>
  )
}
