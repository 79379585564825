import React from 'react'
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { type StandardInfo, StandardApi } from '../../../common/services'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface standardModalProps {
  open: boolean
  onClose: () => void
  options: number[]
}
export const ProjectStandard = {
  ANSI: 1,
  CE: 2,
  ISO: 3,
  NOLIMIT: 4,
  CSA: 5
}
export default function standardModal ({ open, onClose, options }: standardModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const [standardData] = React.useState<StandardInfo>({
    name: '',
    clearanceMargin: undefined,
    harnessStretch: undefined,
    restraintUserLoad: undefined,
    standardType: undefined,
    extraPercent: undefined,
    userMass: undefined
  })
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_StandardName_invalid) ?? ''),
    clearanceMargin: Yup.string().required(t(KeyTranslation.Message_clearanceMargin_invalid) ?? ''),
    harnessStretch: Yup.string().required(t(KeyTranslation.Message_harnessStretch_invalid) ?? ''),
    restraintUserLoad: Yup.string().required(t(KeyTranslation.Message_restraintUserLoad_invalid) ?? ''),
    extraPercent: Yup.string().required(t(KeyTranslation.extraPercent_invalid) ?? '')
  })
  React.useEffect(() => {
    if (ProjectStandard != null) {
      const defaultStandardId = Object.values(ProjectStandard)[0]
      void formik.setFieldValue('standardType', defaultStandardId)
    }
  }, [ProjectStandard])

  const handleSubmit = async (values: StandardInfo): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await new StandardApi().apiV2StandardPost(values)
      if (response.status === 209) {
        onClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        onClose()
        toast.success(t(KeyTranslation.Message_CreateNew_Success))
      }
    } catch (ex: any) {
      onClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: standardData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Standard_Create_New_Standard)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={5}>
              <TextField
                value={formik.values.name}
                onChange={(e) => {
                  void formik.setFieldValue('name', e.target.value)
                }}
                label={t(KeyTranslation.Standard_StandardName)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Standard_StandardType)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Standard_StandardType)}
                  value={formik.values.standardType}
                  onChange={(event) => {
                    const selectedStandardType = Number(event.target.value)
                    void formik.setFieldValue('standardType', selectedStandardType)
                  }}
                >
                  {Object.entries(ProjectStandard)
                    .filter(([key, value]) => [1, 2, 4].includes(value))
                    .map(([key, value]) => (
                      <MenuItem key={value} value={value}>
                        {key}
                      </MenuItem>
                    ))}
                </Select>

              </FormControl>

            </Box>
            <Box mt={5}>
              <TextField
                fullWidth
                label={t(KeyTranslation.Standard_ClearanceMargin)}
                value={formik.values.clearanceMargin}
                onChange={(e) => {
                  void formik.setFieldValue('clearanceMargin', e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
              />
            </Box>
            <Box mt={5}>
              <TextField
                fullWidth
                label={t(KeyTranslation.Extra_Of_MASD)}
                value={formik.values.extraPercent}
                onChange={(e) => {
                  void formik.setFieldValue('extraPercent', e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
              />
            </Box>
            <Box mt={5}>
              <TextField
                fullWidth
                label={t(KeyTranslation.Standard_HarnessStretch)}
                value={formik.values.harnessStretch}
                onChange={(e) => {
                  void formik.setFieldValue('harnessStretch', e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
              />
            </Box>
            <Box mt={5} mb={7}>
              <TextField
                fullWidth
                label={t(KeyTranslation.Standard_RestraintUserLoad)}
                value={formik.values.restraintUserLoad}
                onChange={(e) => {
                  void formik.setFieldValue('restraintUserLoad', e.target.value)
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kn</InputAdornment>
                }}
              />
            </Box>
            <Box my={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.System_UserWeight)}</InputLabel>
                <Select
                  label={t(KeyTranslation.System_UserWeight)}
                  value={formik.values.userMass}
                  onChange={(event) => {
                    const selectedStandardType = Number(event.target.value)
                    void formik.setFieldValue('userMass', selectedStandardType)
                  }}
                >
                  {options.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>

            </Box>
            <Box display="flex" justifyContent="end" gap={4}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
