import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ProductApi, type UploadDto, type ProductTypeDto, type ProductDto, type CommonDto, type CommonModel, type ProductPropertyDto, type RegionDto, type ProductCreateDto } from '../../../common/services'
import { useTranslation } from 'react-i18next'
import { AlertProduct, ModalContent } from '../../../common/components'
import { DropzoneProduct } from '.'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface productDetailProps {
  product?: ProductDto
  open: boolean
  isEditing: boolean
  isCopying: boolean
  onClose: () => void
  handleAddNew: (message: string) => void
  productType: ProductTypeDto[]
  common: CommonModel | undefined
  regions: RegionDto[] | null | undefined
  refetch: any
}

export default function ProductDetail ({ refetch, open, onClose, common, regions, isEditing, isCopying, productType, product, handleAddNew }: productDetailProps): JSX.Element {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string[]>([])
  const [productUrl, setProductUrl] = React.useState('')
  const [adjustRopeGrab, setAdjustRopeGrab] = useState(false)
  const data = useQuery(
    ['/api/v2/Product/GetById', product],
    async () => {
      const res = await new ProductApi().apiV2ProductGetByIdGet(
        product?.id ?? 0
      )
      return res.data.data
    },
    { enabled: isEditing || isCopying }
  )
  const componentTypes = [{ name: 'Corner', value: 1, type: 'decimal' }, { name: 'Intermediary', value: 2, type: 'decimal' }, { name: 'End-points', value: 3, type: 'decimal' }]
  const componentProperties = [{ name: 'ComponentType', value: 0, type: 'decimal' }, { name: 'fromAngle', value: 0, type: 'decimal' }, { name: 'toAngle', value: 0, type: 'decimal' }]
  const PPEProperties: ProductPropertyDto[] = [
    { name: 'fmax', value: '0', type: 'decimal' },
    { name: 'favg', value: '0', type: 'decimal' },
    { name: 'xmax', value: '0', type: 'decimal' },
    { name: 'lengthoflanyard/activationdistanceofsrl', value: '0', type: 'decimal' },
    { name: 'issrl', value: 'false', type: 'boolean' },
    { name: 'lengthofsrl', value: '0', type: 'decimal' },
    { name: 'ppe_ropediameter', value: '0', type: 'decimal' },
    { name: 'ppe_ropemodulus', value: '0', type: 'decimal' }]
  const HEAProperties = [
    { name: 'tmax', value: 0, type: 'decimal' },
    { name: 'tavg', value: 0, type: 'decimal' },
    { name: 'xheamax', value: 0, type: 'decimal' },
    { name: 'pretensionforce', value: 0, type: 'decimal' }]
  const RopeProperties = [{ name: 'diameter', value: 0, type: 'decimal' }, { name: 'elasticmodulus', value: 0, type: 'decimal' }, { name: 'unitweight', value: 0, type: 'decimal' }]
  const form: ProductCreateDto = {
    product: {
      id: null,
      name: '',
      avatar: '',
      overview: '',
      productTypeId: undefined,
      hidding: false,
      modelNo: '',
      productProperties: [],
      productRegions: [],
      productUnits: [],
      productStandards: [],
      productSystemTypes: [],
      productApplicationLocations: []

    },
    avatar: {
      fileName: '',
      fileContent: '',
      fileType: ''
    }
  }
  const handleSubmit = async (values: ProductCreateDto): Promise<void> => {
    setIsSaving(true)
    setErrorMessage([])
    let combinedErrors: any[] = []
    try {
      try {
        await validationSchema.validate(values, { abortEarly: false })
      } catch (validationError: any) {
        combinedErrors = combinedErrors.concat(validationError.errors)
      }
      if (combinedErrors.length > 0) {
        setErrorMessage(combinedErrors)
      }
      if (!isEditing || isCopying) {
        if (values.avatar?.fileContent === '' && !isCopying) {
          setErrorMessage((prevErrors) => [...prevErrors, 'Avatar_invalid'])
          return
        }
        if (adjustRopeGrab) {
          if (Number(values.product?.productProperties?.[6].value) <= 0) {
            setErrorMessage((prevErrors) => [...prevErrors, 'PPE_RopeDiameter_Required'])
            return
          }
          if (Number(values.product?.productProperties?.[7].value) <= 0) {
            setErrorMessage((prevErrors) => [...prevErrors, 'PPE_RopeModulus_Required'])
            return
          }
        }
        if (!adjustRopeGrab && values.product?.productTypeId === 1) {
          values.product?.productProperties?.pop()
          values.product?.productProperties?.pop()
        }
        if (isCopying) {
          values.product?.productProperties?.forEach((property) => {
            delete property.productId
            delete property.id
          })
        }
        if (combinedErrors.length > 0) return
        const response = await new ProductApi().apiV2ProductPost(values)
        if (response.status === 209) {
          setErrorMessage([response.data.error as any])
        } else {
          onClose()
          handleAddNew(t(KeyTranslation.Message_CreateNew_Success))
          void refetch()
        }
      } else {
        if (adjustRopeGrab) {
          if (Number(values.product?.productProperties?.[6].value) <= 0) {
            setErrorMessage([...errorMessage, 'PPE_RopeDiameter_Required'])
            return
          }
          if (Number(values.product?.productProperties?.[7].value) <= 0) {
            setErrorMessage([...errorMessage, 'PPE_RopeModulus_Required'])
            return
          }
        }
        if (!adjustRopeGrab && values.product?.productTypeId === 1) {
          values.product?.productProperties?.pop()
          values.product?.productProperties?.pop()
        }
        if (combinedErrors.length > 0) return
        const response = await new ProductApi().apiV2ProductPut(values)
        if (response.status === 209) {
          setErrorMessage([response.data.error as any])
        } else {
          onClose()
          handleAddNew(t(KeyTranslation.Message_Update_success))
          void refetch()
        }
      }
    } catch (ex: any) {
      setErrorMessage([ex.response.data.error])
    } finally {
      setIsSaving(false)
    }
  }
  const validationSchema = Yup.object().shape({
    product: Yup.object().shape({
      name: Yup.string().required(t('ProductName_invalid') ?? ''),
      productTypeId: Yup.number().notOneOf([0], 'ProductType_invalid'),
      productStandards: Yup.array()
        .min(1, 'Messsage_standard_required'),
      productSystemTypes: Yup.array()
        .min(1, 'Messsage_systemType_required'),
      productApplicationLocations: Yup.array()
        .min(1, 'Messsage_location_required'),
      productUnits: Yup.array()
        .min(1, 'Messsage_unit_required'),
      productRegions: Yup.array()
        .min(1, 'Messsage_Region_required')
    })
  })
  const formik = useFormik({
    initialValues: form,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  console.log(formik.values)
  const handleAvatarChange = (newAvatar: UploadDto): void => {
    void formik.setFieldValue('avatar', newAvatar)
  }
  const onChangeProductType = (productTypeId: number): void => {
    void formik.setFieldValue('product.productTypeId', productTypeId)

    switch (productTypeId) {
      case 1:
        void formik.setFieldValue('product.productProperties', PPEProperties)
        break
      case 2:
        void formik.setFieldValue('product.productProperties', HEAProperties)
        break
      case 4:
        void formik.setFieldValue('product.productProperties', componentProperties)
        break
      case 5:
        void formik.setFieldValue('product.productProperties', RopeProperties)
        break
      default:
        break
    }
  }
  useEffect(() => {
    if (data.data !== undefined) {
      void formik.setFieldValue('product.id', data.data?.id)
      void formik.setFieldValue('product.name', data.data?.name)
      void formik.setFieldValue('product.overview', data.data?.overview)
      void formik.setFieldValue('product.productTypeId', data.data?.productTypeId)
      void formik.setFieldValue('product.hidding', data.data?.hidding)
      void formik.setFieldValue('product.modelNo', data.data?.modelNo)
      void formik.setFieldValue('product.productProperties', data.data?.productProperties)
      void formik.setFieldValue('product.productRegions', data.data?.productRegions)
      void formik.setFieldValue('product.productUnits', data.data?.productUnits)
      void formik.setFieldValue('product.productStandards', (data.data?.productStandards))
      void formik.setFieldValue('product.productSystemTypes', data.data?.productSystemTypes)
      void formik.setFieldValue('product.productApplicationLocations', data.data?.productApplicationLocations)
      void formik.setFieldValue('product.avatar', data.data?.avatar)
      if (data.data.productTypeId === 1) {
        const productProperties = data.data.productProperties ?? []
        const ropeDiameterIndex = productProperties.findIndex(property => property.name === 'ppe_ropediameter')
        const ropeModulusIndex = productProperties.findIndex(property => property.name === 'ppe_ropemodulus')
        if (ropeDiameterIndex === -1) {
          // Nếu không tìm thấy 'ppe_ropediameter', thêm nó vào mảng
          productProperties.push({ name: 'ppe_ropediameter', value: '0', type: 'decimal' })
        }
        if (ropeModulusIndex === -1) {
          // Nếu không tìm thấy 'ppe_ropemodulus', thêm nó vào mảng
          productProperties.push({ name: 'ppe_ropemodulus', value: '0', type: 'decimal' })
        }
        // Kiểm tra nếu có đủ 8 thuộc tính và thuộc tính thứ 7 > 0 thì set adjustRopeGrab thành true
        if (data.data.productTypeId === 1 && data.data?.productProperties != null && data.data?.productProperties?.length === 8 && Number(data.data?.productProperties?.[6].value) > 0) {
          setAdjustRopeGrab(true)
        } else {
          setAdjustRopeGrab(false)
        }

        // Gán lại mảng thuộc tính vào data
        data.data.productProperties = productProperties
      }

      if (isCopying) {
        void formik.setFieldValue('product.id', null)
      }
      if (data.data?.avatar != null) {
        setProductUrl(data.data?.avatar)
      }
    }
  }, [data.data !== undefined])
  useEffect(() => {
    if (formik.values.product?.productProperties?.find(property => property.name === 'issrl')?.value === 'true') {
      setAdjustRopeGrab(false)
    }
  }, [formik.values.product?.productProperties])
  return (
    <Modal open={open} onClose={() => {
      onClose()
      setAdjustRopeGrab(false)
    }}>
      <Box>
        <ModalContent
          onClose={() => {
            onClose()
            setAdjustRopeGrab(false)
          }}
          title={isEditing
            ? t(KeyTranslation.Product_Popup_Update)
            : t(KeyTranslation.Product_Popup_Add_new)}
          minHeight={500}
        >
          <form onSubmit={formik.handleSubmit} className='productForm_marginTop'>
            {errorMessage.length > 0 && errorMessage.map((error, index) => (
              <Alert key={index} severity="error">{t(error)}</Alert>
            ))}
            <AlertProduct errors={formik.errors} touched={formik.touched}></AlertProduct>
            <Grid container spacing={5} mt={0} mb={5}>
              <Grid item xs={6}>
                <TextField
                  value={formik.values.product?.name}
                  onChange={(e) => {
                    void formik.setFieldValue('product.name', e.target.value)
                  }}
                  label={t(KeyTranslation.Header_ProductName)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Header_ProductType)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Header_ProductType)}
                    value={formik.values.product?.productTypeId ?? ''}
                    onChange={(event) => {
                      const selectedProductTypeId: number = Number(event.target.value)
                      onChangeProductType(selectedProductTypeId)
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Header_ProductType)} />}
                  >
                    {productType.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')).map(
                      (productType: ProductTypeDto, index: number) => (
                        <MenuItem key={index} value={productType.id}>
                          {productType.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DropzoneProduct
              handleFileAvatarChange={handleAvatarChange}
              productUrl={productUrl}
              isEditing={isEditing}
              isCopying={isCopying}
            />
            <Grid container spacing={5} mb={5}>
              <Grid item xs={6}>
                <TextField
                  value={formik.values.product?.overview}
                  onChange={(e) => {
                    void formik.setFieldValue('product.overview', e.target.value)
                  }}
                  label={t(KeyTranslation.Product_Popup_Overview)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formik.values.product?.modelNo}
                  onChange={(e) => {
                    void formik.setFieldValue('product.modelNo', e.target.value)
                  }}
                  label={t(KeyTranslation.Common_Model_No)}
                  variant="outlined"
                  fullWidth />
              </Grid>
            </Grid>
            {formik.values.product?.productTypeId === 1 && (
              <Grid container spacing={5} mb={5}>
                {formik.values.product.productProperties?.map((property, index) => (
                  <React.Fragment key={index}>
                    {property.name === 'fmax' && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type='number'
                          value={property.value}
                          label={t(KeyTranslation.Product_fmax)}
                          onChange={(event) => {
                            const updatedProperties = [...formik.values.product?.productProperties ?? []]
                            updatedProperties[index] = { ...property, value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">kN</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                    {property.name === 'favg' && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          type='number'
                          value={property.value}
                          label={t(KeyTranslation.Product_favg)}
                          onChange={(event) => {
                            const updatedProperties = [...formik.values.product?.productProperties ?? []]
                            updatedProperties[index] = { ...property, value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">kN</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                    {property.name === 'xmax' && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={property.value}
                          type='number'
                          label={t(KeyTranslation.Product_xmax)}
                          onChange={(event) => {
                            const updatedProperties = [...formik.values.product?.productProperties ?? []]
                            updatedProperties[index] = { ...property, value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">m</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                    {(property.name === 'lengthoflanyard/activationdistanceofsrl') && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={property.value}
                          type='number'
                          label={formik.values.product?.productProperties?.find(property => property.name === 'issrl')?.value === 'true' ? t(KeyTranslation.Product_activationdistanceofsrl) : t(KeyTranslation.Product_lengthoflanyard)}
                          onChange={(event) => {
                            const updatedProperties = [...formik.values.product?.productProperties ?? []]
                            updatedProperties[index] = { ...property, value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: <InputAdornment position="end">m</InputAdornment>
                          }}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox
                      checked={formik.values.product.productProperties?.find(property => property.name === 'issrl')?.value === 'true'}
                      onChange={(event) => {
                        const checked = event.target.checked
                        const updatedProperties = [...formik.values.product?.productProperties ?? []]
                        const propertyIndex = updatedProperties.findIndex(property => property.name === 'issrl')
                        if (propertyIndex !== -1) {
                          updatedProperties[propertyIndex] = { ...updatedProperties[propertyIndex], value: String(checked) }
                          void formik.setFieldValue('product.productProperties', updatedProperties)
                        }
                      }}
                    />}
                    label={t(KeyTranslation.Product_issrl)}
                  />
                  {formik.values.product.productProperties?.find(property => property.name === 'issrl')?.value === 'false' && (
                    <FormControlLabel
                      control={<Checkbox
                        checked={adjustRopeGrab}
                        onChange={(event) => { setAdjustRopeGrab(event.target.checked) }}
                      />}
                      label={t(KeyTranslation.Product_isAdjustRopeGrab)}
                    />
                  )}
                </Grid>
                {formik.values.product.productTypeId === 1 && formik.values.product.productProperties?.find(property => property.name === 'issrl')?.value === 'true' && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.product.productProperties?.find(property => property.name === 'lengthofsrl')?.value ?? ''}
                      type='number'
                      label={t(KeyTranslation.Product_lengthofsrl)}
                      onChange={(event) => {
                        const updatedProperties = [...formik.values.product?.productProperties ?? []]
                        const propertyIndex = updatedProperties.findIndex(property => property.name === 'lengthofsrl')
                        if (propertyIndex !== -1) {
                          updatedProperties[propertyIndex] = { ...updatedProperties[propertyIndex], value: event.target.value }
                          void formik.setFieldValue('product.productProperties', updatedProperties)
                        }
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: <InputAdornment position="end">m</InputAdornment>
                      }}
                    />
                  </Grid>
                )}
                {adjustRopeGrab && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={formik.values.product.productProperties?.find(property => property.name === 'ppe_ropediameter')?.value ?? ''}
                        type='number'
                        label={t(KeyTranslation.Product_Rope_Diameter)}
                        onChange={(event) => {
                          const updatedProperties = [...formik.values.product?.productProperties ?? []]
                          const propertyIndex = updatedProperties.findIndex(property => property.name === 'ppe_ropediameter')
                          if (propertyIndex !== -1) {
                            updatedProperties[propertyIndex] = { ...updatedProperties[propertyIndex], value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: <InputAdornment position="end">mm</InputAdornment>
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={formik.values.product.productProperties?.find(property => property.name === 'ppe_ropemodulus')?.value ?? ''}
                        type='number'
                        label={t(KeyTranslation.Product_ppe_ropemodulus)}
                        onChange={(event) => {
                          const updatedProperties = [...formik.values.product?.productProperties ?? []]
                          const propertyIndex = updatedProperties.findIndex(property => property.name === 'ppe_ropemodulus')
                          if (propertyIndex !== -1) {
                            updatedProperties[propertyIndex] = { ...updatedProperties[propertyIndex], value: event.target.value }
                            void formik.setFieldValue('product.productProperties', updatedProperties)
                          }
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: <InputAdornment position="end">MPa</InputAdornment>
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {formik.values.product?.productTypeId === 2 && (
              <Grid container spacing={5} mb={5}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[0].value}
                    label={t(KeyTranslation.Product_Tmax)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[0].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">kN</InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[1].value}
                    label={t(KeyTranslation.Product_Tavg)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[1].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">kN</InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[2].value}
                    label={t(KeyTranslation.Product_XHeaMax)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[2].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[3].value}
                    label={t(KeyTranslation.Product_PretensionForce)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[3].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">kN</InputAdornment>
                      )
                    }} />
                </Grid>
              </Grid>
            )}
            {formik.values.product?.productTypeId === 4 && (
              <Grid container spacing={5} mb={5}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>{t(KeyTranslation.Product_Popup_Product_Component_Type)}</InputLabel>
                    <Select
                      value={formik.values.product.productProperties?.[0].value}
                      onChange={(event) => {
                        void formik.setFieldValue('product.productProperties[0].value', (event.target.value))
                      }}
                      input={<OutlinedInput label={t(KeyTranslation.Product_Popup_Product_Component_Type)} />}
                    >
                      {componentTypes.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[1].value}
                    label={t(KeyTranslation.Product_From_Angle)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[1].value', (event.target.value))
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[2].value}
                    label={t(KeyTranslation.Product_To_Angle)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[2].value', (event.target.value))
                    }} />
                </Grid>
              </Grid>
            )}
            {formik.values.product?.productTypeId === 5 && (
              <Grid container spacing={5} mb={5}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[0].value}
                    label={t(KeyTranslation.Product_Rope_Diameter)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[0].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">mm</InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[1].value}
                    label={t(KeyTranslation.Product_Elastic_Modulus)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[1].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">MPa</InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='number'
                    value={formik.values.product.productProperties?.[2].value}
                    label={t(KeyTranslation.Product_Rope_Unit_Weight)}
                    onChange={(event) => {
                      void formik.setFieldValue('product.productProperties[2].value', (event.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">N/m</InputAdornment>
                      )
                    }} />
                </Grid>
              </Grid>
            )
            }
            <Grid container spacing={5} mb={5}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Common_Standards)}</InputLabel>
                  <Select
                    multiple
                    value={formik.values.product?.productStandards?.map((item) => item.standardId) ?? []}
                    onChange={(event) => {
                      const selectedStandardIds = Array.isArray(event.target.value)
                        ? event.target.value.map((id) => ({ standardId: id }))
                        : []
                      void formik.setFieldValue('product.productStandards', selectedStandardIds)
                    }}
                    renderValue={(selected) => {
                      return selected
                        .map((id) => {
                          if (common?.standards != null) {
                            const selectedStandard = common?.standards.find(
                              (standard) => standard.id === id
                            )
                            return (selectedStandard != null) ? selectedStandard.name : ''
                          } else {
                            return ''
                          }
                        })
                        .join(', ')
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Common_Standards)} />}
                  >
                    {common?.standards?.map(
                      (standard: CommonDto, index: number) => (
                        <MenuItem key={index} value={standard.id}>
                          <Checkbox
                            checked={formik.values.product?.productStandards?.some(
                              (item) => item.standardId === standard.id
                            )} />
                          <ListItemText primary={standard.name ?? ''} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Action)}</InputLabel>
                  <Select
                    multiple
                    value={formik.values.product?.productSystemTypes?.map((item) => item.systemTypeId) ?? []}
                    onChange={(event) => {
                      const selectedSystemTypesId = Array.isArray(event.target.value)
                        ? event.target.value.map((id) => ({ systemTypeId: id }))
                        : []
                      void formik.setFieldValue('product.productSystemTypes', selectedSystemTypesId)
                    }}
                    renderValue={(selected) => {
                      return selected
                        .map((id) => {
                          if (common?.systemTypes != null) {
                            const selectedSystemTypes = common?.systemTypes.find(
                              (systemType) => systemType.id === id
                            )
                            return (selectedSystemTypes != null) ? selectedSystemTypes.name : ''
                          } else {
                            return ''
                          }
                        })
                        .join(', ')
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Common_Standards)} />}
                  >
                    {common?.systemTypes?.map(
                      (systemType: CommonDto, index: number) => (
                        <MenuItem key={index} value={systemType.id}>
                          <Checkbox
                            checked={formik.values.product?.productSystemTypes?.some(
                              (item) => item.systemTypeId === systemType.id
                            )} />
                          <ListItemText primary={systemType.name ?? ''} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Product_Location)}</InputLabel>
                  <Select
                    multiple
                    value={formik.values.product?.productApplicationLocations?.map((item) => item.applicationLocationId) ?? []}
                    onChange={(event) => {
                      const selectedApplicationLocationsId = Array.isArray(event.target.value)
                        ? event.target.value.map((id) => ({ applicationLocationId: id }))
                        : []
                      void formik.setFieldValue('product.productApplicationLocations', selectedApplicationLocationsId)
                    }}
                    renderValue={(selected) => {
                      return selected
                        .map((id) => {
                          if (common?.applicationLocations != null) {
                            const selectedLocations = common?.applicationLocations.find(
                              (applicationLocation) => applicationLocation.id === id
                            )
                            return (selectedLocations != null) ? selectedLocations.name : ''
                          } else {
                            return ''
                          }
                        })
                        .join(', ')
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Product_Location)} />}
                  >
                    {common?.applicationLocations?.map(
                      (applicationLocation: CommonDto, index: number) => (
                        <MenuItem key={index} value={applicationLocation.id}>
                          <Checkbox
                            checked={formik.values.product?.productApplicationLocations?.some(
                              (item) => item.applicationLocationId === applicationLocation.id
                            )} />
                          <ListItemText primary={applicationLocation.name ?? ''} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Common_Units)}</InputLabel>
                  <Select
                    multiple
                    value={formik.values.product?.productUnits?.map((item) => item.unitId) ?? []}
                    onChange={(event) => {
                      const selectedProductUnitsId = Array.isArray(event.target.value)
                        ? event.target.value.map((id) => ({ unitId: id }))
                        : []
                      void formik.setFieldValue('product.productUnits', selectedProductUnitsId)
                    }}
                    renderValue={(selected) => {
                      return selected
                        .map((id) => {
                          if (common?.units != null) {
                            const selectedUnit = common?.units.find(
                              (unit) => unit.id === id
                            )
                            return (selectedUnit != null) ? selectedUnit.name : ''
                          } else {
                            return ''
                          }
                        })
                        .join(', ')
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Common_Units)} />}
                  >
                    {common?.units?.map(
                      (systemType: CommonDto, index: number) => (
                        <MenuItem key={index} value={systemType.id}>
                          <Checkbox
                            checked={formik.values.product?.productUnits?.some(
                              (item) => item.unitId === systemType.id
                            )} />
                          <ListItemText primary={systemType.name ?? ''} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mb={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Regions)}</InputLabel>
                <Select
                  multiple
                  value={formik.values.product?.productRegions?.map((item) => item.countryId) ?? []}
                  onChange={(event) => {
                    const selectedProductUnitsId = Array.isArray(event.target.value)
                      ? event.target.value.map((id) => ({ countryId: id }))
                      : []
                    void formik.setFieldValue('product.productRegions', selectedProductUnitsId)
                  }}
                  renderValue={(selected) => {
                    return selected
                      .map((id) => {
                        if (regions != null) {
                          const selectedUnit = regions.find(
                            (unit) => unit.id === id
                          )
                          return (selectedUnit != null) ? selectedUnit.name : ''
                        } else {
                          return ''
                        }
                      })
                      .join(', ')
                  }}
                  input={<OutlinedInput label={t(KeyTranslation.Common_Regions)} />}
                >
                  {regions?.map(
                    (regions: RegionDto, index: number) => (
                      <MenuItem key={index} value={regions.id ?? 0}>
                        <Checkbox
                          checked={formik.values.product?.productRegions?.some(
                            (item) => item.countryId === regions.id
                          )} />
                        <ListItemText primary={regions.name ?? ''} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.product?.hidding === false}
                  onChange={(event) => {
                    const hidding = !event.target.checked
                    void formik.setFieldValue('product.hidding', hidding)
                  }}
                />
              }
              label={t(KeyTranslation.Header_Hidding)}
            />
            <Box display="flex" justifyContent="end" gap={4}>
              <Button
                onClick={() => {
                  onClose()
                }}
              >
                {t(KeyTranslation.Common_Cancel)}
              </Button>
              <Button
                variant="contained"
                disabled={isSaving}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Box>
    </Modal>
  )
}
