// Image.tsx
import { useEditor, useNode } from '@craftjs/core'
import React from 'react'
import styled from 'styled-components'

import { ImageSettings } from './ImageSettings'

const ImageContainer = styled.div<any>`
  width: 100%;
  height: 100%;
  img {
    width: ${props => props.width}px; // Set the width based on the 'width' prop
    height: ${props => props.height}px; // Set the height based on the 'height' prop
    object-fit: contain;
  }
`

export const Image = (props: any): JSX.Element => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled
  }))
  const {
    connectors: { connect }
  } = useNode((node) => ({
    selected: node.events.selected
  }))

  const { imageUrl, width, height, alt } = props
  return (
    <ImageContainer ref={connect as any} width={width} height={height} enabled={enabled}>
      {<img src={imageUrl} alt={alt} />}
    </ImageContainer>
  )
}

export const ImageDefaultProps = {
  src: 'https://esdsblobstorage.blob.core.windows.net/esds/ReportResources/Logo.png',
  alt: 'image default',
  width: 62,
  height: 62
}

Image.craft = {
  displayName: 'Image',
  props: {
    imageUrl: ImageDefaultProps.src,
    width: ImageDefaultProps.width,
    height: ImageDefaultProps.height,
    alt: ImageDefaultProps.alt
  },
  related: {
    toolbar: ImageSettings
  }
}
