import * as React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { NoteApi, ProjectApi, type NoteDto } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import { NoteModal } from './components'
import 'react-toastify/dist/ReactToastify.css'
import {
  type GridSortModel,
  type GridRowModesModel,
  type GridColDef,
  type GridEventListener,
  GridRowEditStopReasons,
  type GridRowId,
  GridRowModes
} from '@mui/x-data-grid-pro'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import AlertDelete from '../../common/components/AlertDelete'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import KeyTranslation from '../../common/KeyTranslation.json'

const drawerWidth = 320
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  width: 102,
  ...((open ?? false) && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  position: 'relative'
}))
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))
export default function Note (): JSX.Element {
  const { id } = useParams()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<NoteDto | undefined>(
    undefined
  )
  const handleDrawerOpen =
    (open: boolean, id: number) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return
        }

        setOpen(open)
        const selectedItem = noteData?.data?.find((item) => item.id === id)
        setSelectedItem(selectedItem)
      }
  const handleDrawerClose = (): void => {
    setOpen(false)
  }
  const [noteModalProps, setNoteModalProps] = React.useState<{
    visible: boolean
    data?: NoteDto
  }>({
    visible: false,
    data: undefined
  })
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [paginationNote, setPaginationNote] = React.useState({
    page: 0,
    pageSize: 5
  })
  const [sortBy, setSortBy] = React.useState({
    sortField: 'lastUpdatedDate',
    isDescending: true
  })
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map(item => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationNote({
        page: 0,
        pageSize: paginationNote.pageSize
      })
    }
  }
  const {
    data: noteData,
    isFetching: noteIsFetching,
    refetch: noteRefetch
  } = useQuery(
    ['/api/v2/Note/SearchNoteByPaging', id, paginationNote],
    async () => {
      if (id !== undefined) {
        const { data } = await new NoteApi().apiV2NoteSearchNoteByPagingGet(
          Number(id),
          paginationNote.page + 1,
          paginationNote.pageSize,
          '',
          sortBy.sortField,
          sortBy.isDescending
        )
        return data
      }
    },
    { enabled: false }
  )

  React.useEffect(() => {
    void noteRefetch()
  }, [paginationNote])
  const { data: projectData, refetch: projectRefetch } = useQuery(
    ['/api/v2/Project/GetProjectById', id],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        Number(id)
      )
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void projectRefetch()
  }, [])
  const [rows, setRows] = React.useState(noteData?.data)
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = React.useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        const response = await new NoteApi().apiV2NoteIdDelete(id)
        if (response.status === 209) {
          handleDialogClose()
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          await noteRefetch()
          handleDialogClose()
          handleDrawerClose()
          toast.success(t(KeyTranslation.Message_Delete_Success))
        }
      } catch (ex: any) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${ex.response.data.error}`))
      }
    },
    [noteRefetch, handleDialogClose]
  )
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows?.find((row) => row.id === id)
    if (editedRow?.id != null) {
      setRows(rows?.filter((row) => row.id !== id))
    }
  }
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ): void => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const handleRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel)
  }
  const validationSchema = Yup.object({
    title: Yup.string().required(t(KeyTranslation.Message_NoteTitle_invalid) ?? ''),
    description: Yup.string().required(t(KeyTranslation.Message_Description_invalid) ?? '')
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const processRowUpdate = async (newRow: NoteDto) => {
    try {
      setIsLoading(true)
      await validationSchema.validate(newRow)
      const updatedRow = { ...newRow, isNew: false }
      setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)))
      const response = await new NoteApi().apiV2NotePut(updatedRow)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${(response.data as any).error}`))
      } else {
        toast.success(t(KeyTranslation.Message_Update_success))
      }
      return updatedRow
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const columnsDummyNote: Array<GridColDef<NoteDto>> = [
    {
      field: 'title',
      headerName: t(KeyTranslation.Header_NoteTitle) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'description',
      headerName: t(KeyTranslation.Note_Description) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'lastUpdatedDate',
      headerName: t(KeyTranslation.Header_LastUpdatedDate) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        const inputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'
        const outputFormat = 'YYYY-MM-DD HH:mm'
        const convertedTime = moment(row.lastUpdatedDate, inputFormat).format(
          outputFormat
        )
        return <div>{convertedTime}</div>
      }
    },
    {
      field: 'submitBy',
      headerName: t(KeyTranslation.Header_SubmitBy) ?? '',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 160,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Tooltip title={t(KeyTranslation.Common_Save)} key={`${id}-save`}>
              <IconButton onClick={handleSaveClick(id)}>
                <SaveOutlinedIcon />
              </IconButton>
            </Tooltip>,
            <Tooltip title={t(KeyTranslation.Common_Cancel)} key={`${id}-cancel`}>
              <IconButton onClick={handleCancelClick(id)}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          ]
        }

        return [
          <>
          <Tooltip
              title={t(KeyTranslation.Btn_View)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton onClick={handleDrawerOpen(true, Number(id))}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_Edit)}
              key={`${id}-edit`}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton onClick={handleEditClick(id)}>
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_Delete)}
              key={`${id}-delete`}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                onClick={() => {
                  setDialogOpen({ open: true, id: Number(id) })
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        ]
      }
    }
  ]
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Main open={open}>
          <Box>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid xs={6}>
                <Typography variant="h1" mb={4}>
                  {projectData?.data?.projectName}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography variant="caption">
                  {t(KeyTranslation.Common_CustomerName)}:{' '}
                </Typography>
                <Typography component={'span'}>
                  {projectData?.data?.customerName}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography component={'span'} variant="caption">
                  {t(KeyTranslation.Common_System_Ref)}:{' '}
                </Typography>
                <Typography component={'span'}>
                  {projectData?.data?.reference}
                </Typography>
              </Grid>
            </Grid>
            <Breadcrumbs aria-label="breadcrumb">
              <Breadcrumb
                component="div"
                label={
                  <Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>
                }
              />
              <Breadcrumb
                component="div"
                label={
                  <Link to={`/projects/${Number(id)}`}>
                    {t(KeyTranslation.Common_ProjectOverview)}
                  </Link>
                }
              />
              <Breadcrumb component="div" label={t(KeyTranslation.Note_Notes)} />
            </Breadcrumbs>
            <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
              <Box className="tableTools">
                <Typography variant="h4">{t(KeyTranslation.Note_Notes)}</Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setNoteModalProps({
                      visible: true,
                      data: undefined
                    })
                  }}
                >
                  {t(KeyTranslation.Note_Create_New_Note)}
                </Button>
              </Box>
              <Box>
                <StyledDataGrid
                  autoHeight
                  loading={isLoading || noteIsFetching}
                  rows={noteData?.data ?? []}
                  columns={columnsDummyNote}
                  rowSelection={false}
                  disableColumnMenu={true}
                  paginationMode="server"
                  onPaginationModelChange={setPaginationNote}
                  getRowId={(row) => row.id}
                  rowCount={noteData?.totals ?? 0}
                  paginationModel={paginationNote}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  sortingMode="server"
                  onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
                />
              </Box>
              {noteModalProps.visible && (
                <NoteModal
                  open={noteModalProps.visible}
                  onClose={(): void => {
                    setNoteModalProps({
                      visible: false,
                      data: undefined
                    })
                  }}
                  noteRefetch={noteRefetch}
                />
              )}
              <AlertDelete
                handleDelete={() => {
                  void handleDelete(dialogOpen.id)
                }}
                dialogOpen={dialogOpen.open}
                handleDialogClose={handleDialogClose}
              />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Box>
          </Box>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth
            }
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Box
            sx={{
              marginTop: '64px',
              display: 'flex',
              justifyContent: 'space-between',
              p: 5,
              alignItems: 'center',
              height: '76px'
            }}
          >
            <Typography variant="h4" sx={{ background: '#ffff' }}>
              {t(KeyTranslation.Note_Notes)}
            </Typography>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl'
                  ? (
                  <ChevronLeftIcon />
                    )
                  : (
                  <ChevronRightIcon />
                    )}
              </IconButton>
            </DrawerHeader>
          </Box>
          <Box>
            <Box className="infoDocument">
              <Box className="properties">
                <Box className="flex">
                  <Typography className="w80px">
                    {t(KeyTranslation.Header_NoteTitle)}:
                  </Typography>
                  <Typography className="w200px">
                    {selectedItem?.title}
                  </Typography>
                </Box>
                <Box className="flex">
                  <Typography className="w80px">
                    {t(KeyTranslation.Note_Description)}:
                  </Typography>
                  <Typography className="w200px">
                    {selectedItem?.description}
                  </Typography>
                </Box>
                <Box className="flex">
                  <Typography className="w80px">
                    {t(KeyTranslation.Header_LastUpdatedDate)}:
                  </Typography>
                  <Typography className="w200px">
                    {selectedItem?.lastUpdatedDate}
                  </Typography>
                </Box>
                <Box className="flex">
                  <Typography className="w80px">
                    {t(KeyTranslation.Header_SubmitBy)}:
                  </Typography>
                  <Typography className="w200px">
                    {selectedItem?.submitBy}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  )
}
