import React, { useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { AttachmentsReportModal, GeneralReportModal, SystemReportModal, TrackingStepsReport } from './'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface SystemModalProps {
  open: boolean
  onClose: () => void
  reportRefetch?: any
  revisionHistoryRefetch?: any
}

export interface UserChoicesReportProps {
  name: string
  description: string
  nextInspectionDate: any
  reportSystems: any
  reportNotes: any
  reportProjectDocuments: any
  reportBlobDocuments: any
}

export default function ContainerModalReport ({
  open,
  onClose,
  reportRefetch, revisionHistoryRefetch
}: SystemModalProps): JSX.Element {
  const [step, setStep] = useState(1)
  const [userChoices, setUserChoices] = useState<UserChoicesReportProps | null>(null)
  const { id } = useParams()
  const projectId = Number(id)
  const saveUserChoice = (choice: any): void => {
    setUserChoices({ ...userChoices, ...choice, projectId })
  }
  const handleNextStep = (): void => {
    setStep((prevStep) => prevStep + 1)
  }
  const handleBackStep = (): void => {
    setStep((prevStep) => prevStep - 1)
  }
  const transitions = useTransition(step, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' }
  })
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.New_Report)}
>         <Box className="p24">
          <TrackingStepsReport activeStep={step} />
          </Box>
          <Box sx={{ overflow: 'hidden' }}>
          {transitions((style, item) => (
            <animated.div style={{
              ...style
            }}>
              {step === 1 && (
                <GeneralReportModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  saveUserChoice={saveUserChoice}
                  userChoices={userChoices}
                />
              )}
              {step === 2 && (
                <SystemReportModal
                  open={open}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  userChoices={userChoices}
                />
              )}
              {step === 3 && (
                <AttachmentsReportModal
                  open={open}
                  onClose={onClose}
                  onNextStep={handleNextStep}
                  onBackStep={handleBackStep}
                  saveUserChoice={saveUserChoice}
                  userChoices={userChoices}
                  reportRefetch={reportRefetch}
                />
              )}
            </animated.div>
          ))}
          </Box>
        </ModalContent>
      </Box>
    </Modal>
  )
}
