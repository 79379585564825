import React, { useCallback } from 'react'
import { Box, Button, InputAdornment, TextField, Tooltip, Typography, IconButton, type TablePaginationProps } from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { StandardApi, type StandardInfo } from '../../common/services'
import {
  useQuery
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { StandardModal } from './components'
import { ProjectStandard } from './components/modal'
import AlertDelete from '../../common/components/AlertDelete'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { type GridColDef, GridRowModes, type GridEventListener, GridRowEditStopReasons, type GridRowId, type GridRowModesModel, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import * as Yup from 'yup'
import MuiPagination from '@mui/material/Pagination'

const options = [100, 140]
export default function SystemDetail (): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(false)
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  )
  const { data, isFetching, refetch } = useQuery(['/api/v2/Standard'], async () => {
    const { data } = await new StandardApi().apiV2StandardGet()
    return data
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
  }, [])
  const [rows, setRows] = React.useState(data?.data)
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDelete = async (id: number): Promise<void> => {
    try {
      const responese = await new StandardApi().apiV2StandardIdDelete(id)
      if (responese.status === 209) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${responese.data.error}`))
      } else {
        await refetch()
        handleDialogClose()
        toast.success(t(KeyTranslation.Message_Delete_Success))
      }
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }

  const [standardModalProps, setStandardModalProps] = React.useState<{ visible: boolean, data?: StandardInfo, isEditing: boolean }>({
    visible: false,
    data: undefined,
    isEditing: false
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm.length === 0) {
      return data.data
    }

    return data.data?.filter((standard: StandardInfo) => {
      const searchTermLower = searchTerm.toLowerCase()
      return ((standard.name?.toLowerCase().includes(searchTermLower)) ?? false)
    })
  }, [data, searchTerm])
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows?.find((row) => row.id === id)
    if (editedRow?.id != null) {
      setRows(rows?.filter((row) => row.id !== id))
    }
  }
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ): void => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const handleRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel)
  }
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_StandardName_invalid) ?? ''),
    clearanceMargin: Yup.string().required(t(KeyTranslation.Message_clearanceMargin_invalid) ?? ''),
    harnessStretch: Yup.string().required(t(KeyTranslation.Message_harnessStretch_invalid) ?? ''),
    restraintUserLoad: Yup.string().required(t(KeyTranslation.Message_restraintUserLoad_invalid) ?? '')
  })
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const processRowUpdate = async (newRow: StandardInfo) => {
    try {
      setIsLoading(true)
      await validationSchema.validate(newRow)
      const updatedRow = { ...newRow, isNew: false }
      setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)))
      const respone = await new StandardApi().apiV2StandardPut(updatedRow)
      if (respone.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${respone.data.error}`))
      } else {
        toast.success(t(KeyTranslation.Message_Update_success))
      }
      return updatedRow
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }
  const columnsDummy: Array<GridColDef<StandardInfo>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Standard_StandardName) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'standardType',
      headerName: t(KeyTranslation.Standard_StandardType) ?? '',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueGetter: (params) => params.row.standardType,
      valueOptions: Object.entries(ProjectStandard).map(([key, value]) => ({
        label: key,
        value,
        key: value
      }))
    },
    {
      field: 'clearanceMargin',
      headerName: `${t(KeyTranslation.Standard_ClearanceMargin)} (m)` ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'extraPercent',
      headerName: `${t(KeyTranslation.Extra_Of_MASD)} (%)` ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'harnessStretch',
      headerName: `${t(KeyTranslation.Standard_HarnessStretch)} (m)` ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'restraintUserLoad',
      headerName: `${t(KeyTranslation.Standard_RestraintUserLoad)} (kn)` ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'userMass',
      headerName: `${t(KeyTranslation.System_UserWeight)} (kg)` ?? '',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueGetter: (params) => params.row.userMass,
      valueOptions: options.map((value, index) => ({
        value,
        key: index,
        label: value
      }))
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Tooltip title={t(KeyTranslation.Common_Save)} key={id}>
              <IconButton onClick={handleSaveClick(id)} >
                <SaveOutlinedIcon/>
              </IconButton>
            </Tooltip>,
            <Tooltip title={t(KeyTranslation.Common_Cancel)} key={id}>
              <IconButton onClick={handleCancelClick(id)} >
                <CloseOutlinedIcon/>
              </IconButton>
            </Tooltip>
          ]
        }
        return [
          <><Tooltip title={t(KeyTranslation.Btn_Edit)} key={`${id}-edit`}>
          <IconButton onClick={handleEditClick(id)}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t(KeyTranslation.Btn_Delete)} key={`${id}-delete`}>
              <IconButton onClick={() => { setDialogOpen({ open: true, id: Number(id) }) }}>
                <DeleteOutlinedIcon/>
              </IconButton>
        </Tooltip></>
        ]
      }

    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext()
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }
  return (
    <Box>
      <Typography variant='h1'>{t(KeyTranslation.Common_Standard)}</Typography>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5 }} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size='small'
            value={searchTerm}
            onChange={(event) => { setSearchTerm(event.target.value) }}
          />
          <Button variant='contained' onClick={() => {
            setStandardModalProps({
              visible: true,
              data: undefined,
              isEditing: false
            })
          }} >{t(KeyTranslation.Standard_New_Standard)}</Button>
        </Box>
        <StyledDataGrid
          autoHeight
          loading={isLoading || isFetching}
          rows={filteredData ?? []}
          columns={columnsDummy}
          rowSelection={false}
          disableColumnMenu={true}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            pagination: CustomPagination
          }}
        />
        {standardModalProps.visible &&
          <StandardModal open={standardModalProps.visible} options={options} onClose={(): void => {
            void refetch()
            setStandardModalProps({
              visible: false,
              data: undefined,
              isEditing: false
            })
          }} />
        }
      <AlertDelete handleDelete={() => { void handleDelete(dialogOpen.id) }} dialogOpen={dialogOpen.open} handleDialogClose={handleDialogClose} />
      </Box>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </Box>
  )
}
