import React from 'react'
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { LanguageApi, type BlobDocumentDto, type LanguageDto, CommonApi, type CommonDto, ProductApi, BlobDocumentApi, type DocumentInfo, type UploadDto, type ProductDto } from '../../../common/services'
import { useQuery } from '@tanstack/react-query'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import DropzoneDocument from './DropzoneDocument'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface documentModalProps {
  document?: BlobDocumentDto
  open: boolean
  onClose: () => void
  isEditing: boolean
}
const ITEM_HEIGHT = 50
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 250
    }
  }
}
const defaultfileDocument: UploadDto = {
  fileContent: '',
  fileType: '',
  fileName: ''
}
export default function DocumentModal ({ document, open, onClose, isEditing }: documentModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = React.useState(false)
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [filteredProducts, setFilteredProducts] = React.useState<ProductDto[]>([])
  const [fileDocument, setFileDocument] = React.useState<UploadDto>(defaultfileDocument)

  const { data, refetch } = useQuery(['/api/v2/Language'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGet()
    return data
  }, { enabled: false })
  const common = useQuery(['/api/v2/Common'], async () => {
    const res = await new CommonApi().apiV2CommonGet()
    return res.data
  }, { enabled: false })
  const product = useQuery(['/api/v2/User/GetRoleList'], async () => {
    const { data } = await new ProductApi().apiV2ProductGet()
    return data
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
    void common.refetch()
    void product.refetch()
  }, [])
  const [documentData] = React.useState<BlobDocumentDto>((document != null)
    ? document
    : {
        languageId: 0,
        unitId: 0,
        standardId: 0,
        documentProducts: [],
        name: '',
        type: '',
        blobUrl: null
      })
  React.useEffect(() => {
    if (!isEditing && data?.data != null && data.data.length > 0) {
      const defaultLanguageId = data.data[0].id ?? 0
      onChangeLanguage(defaultLanguageId)
      void formik.setFieldValue('languageId', defaultLanguageId)
    }
    if (!isEditing && common?.data?.data?.units != null && common?.data?.data?.units.length > 0) {
      const defaultUnitId = common?.data?.data?.units[0].id ?? 0
      onChangeUnit(defaultUnitId)
      void formik.setFieldValue('unitId', defaultUnitId)
    }
    if (!isEditing && common?.data?.data?.standards != null && common?.data?.data?.standards.length > 0) {
      const defaultStandardId = common?.data?.data?.standards[0].id ?? 0
      onChangeStandard(defaultStandardId)
      void formik.setFieldValue('standardId', defaultStandardId)
    }
  }, [common?.data?.data, data?.data, isEditing])

  const handleSelectAll = (): void => {
    setSelectAll(!selectAll)

    if (!selectAll) {
      const allProductIds = product.data?.data?.map((p) => p.id) ?? []
      const selectedProducts = allProductIds.map((productId) => ({
        productId
      }))
      void formik.setFieldValue('documentProducts', selectedProducts)
    } else {
      void formik.setFieldValue('documentProducts', [])
    }
  }
  const handleFileDocumentChange = (newFileDocument: UploadDto): void => {
    setFileDocument(newFileDocument)
  }
  const handleSearchChange = (event: any): void => {
    setSearchKeyword(event.target.value)
  }

  const onChangeLanguage = (languageId: number): void => {
    const selectedLanguage = data?.data?.find((language) => language.id === languageId)
    if (selectedLanguage?.id != null) {
      void formik.setFieldValue('languageId', selectedLanguage.id)
    }
  }
  const onChangeUnit = (unitId: number): void => {
    const selectedUnit = common.data?.data?.units?.find((unit) => unit === unitId)
    if (selectedUnit?.id != null) {
      void formik.setFieldValue('unitId', selectedUnit.id)
    }
  }
  const onChangeStandard = (standartId: number): void => {
    const selectedStandardId = common.data?.data?.standards?.find((standart) => standart === standartId)
    if (selectedStandardId?.id != null) {
      void formik.setFieldValue('standartId', selectedStandardId.id)
    }
  }

  const handleSubmit = async (values: BlobDocumentDto): Promise<void> => {
    try {
      const Params: DocumentInfo = {
        blobDocument: values,
        fileDocument
      }
      setIsLoading(true)

      if (values.id != null) {
        const response = await new BlobDocumentApi().apiV2BlobDocumentPut(Params)

        if (response.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${response.data.error}`))
        } else {
          onClose()
          toast.success(t(KeyTranslation.Message_Update_success))
        }
      } else {
        const response = await new BlobDocumentApi().apiV2BlobDocumentPost(Params)
        if (response.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${response.data.error}`))
        } else {
          onClose()
          toast.success(t(KeyTranslation.Message_CreateNew_Success))
        }
      }
      onClose()
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response?.data?.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const validationSchema = Yup.object({
    documentProducts: Yup.array()
      .min(1, t(KeyTranslation.Messsage_Product_required) ?? '')
      .required(t(KeyTranslation.Messsage_Product_required) ?? ''),
    blobUrl: Yup.mixed().required(t(KeyTranslation.Message_FileContent_invalid) ?? '')
  })

  const formik = useFormik({
    initialValues: documentData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  React.useEffect(() => {
    const filtered = product.data?.data?.filter((item) => item.name?.toLowerCase().includes(searchKeyword.toLowerCase())) ?? []
    setFilteredProducts(filtered)
  }, [searchKeyword, product.data])

  const renderProductItem = ({ index, key, style }: any): JSX.Element | null => {
    if (index === 0) {
      return <MenuItem>
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
        />
        <ListItemText primary={t(KeyTranslation.Common_Checkall) ?? ''} />
      </MenuItem>
    }
    const productItem = filteredProducts[index - 1]

    if (productItem != null) {
      return (
        <>
          <MenuItem key={key} value={productItem.id ?? 0} style={style}>
            <Checkbox
              checked={formik.values.documentProducts?.find((p) => p.productId === productItem.id) !== undefined}
              onChange={(event) => {
                const productId = productItem.id ?? 0
                const selectedProducts = formik.values.documentProducts ?? []

                if (event.target.checked) {
                  // Add the product to the selected products list
                  const newProduct = { productId }
                  const updatedProducts = [...selectedProducts, newProduct]
                  void formik.setFieldValue('documentProducts', updatedProducts)
                } else {
                  // Remove the product from the selected products list
                  const updatedProducts = selectedProducts.filter((p) => p.productId !== productId)
                  void formik.setFieldValue('documentProducts', updatedProducts)
                }
              }}
            />
            <ListItemText
              className='listItemProduct'
              primary={productItem.name ?? ''}
            />
          </MenuItem>
        </>
      )
    }

    return null
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box>
        <ModalContent onClose={onClose} title={isEditing ? t(KeyTranslation.Document_EditDocument) : t(KeyTranslation.Document_CreateDocument)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Grid container spacing={5} mb={5} mt={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Header_UnitName)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Header_UnitName)}
                    value={formik.values.unitId}
                    onChange={(event) => {
                      const selectedUnitId = Number(event.target.value)
                      onChangeUnit(selectedUnitId)
                      void formik.setFieldValue('unitId', selectedUnitId)
                    }}

                  >
                    {common?.data?.data?.units?.map((unit: CommonDto, index: number) => (
                      <MenuItem key={index} value={unit.id ?? 0}>
                        {unit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Common_Language)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Common_Language)}
                    value={formik.values.languageId}
                    onChange={(event) => {
                      const selectedLanguageId = Number(event.target.value)
                      onChangeLanguage(selectedLanguageId)
                      void formik.setFieldValue('languageId', selectedLanguageId)
                    }}

                  >
                    {data?.data?.map((language: LanguageDto, index: number) => (
                      <MenuItem key={index} value={language.id}>
                        {language.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={5} mb={5}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(KeyTranslation.Common_Standard)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Common_Standard)}
                    value={formik.values.standardId}
                    onChange={(event) => {
                      const selectedStandardId = Number(event.target.value)
                      onChangeStandard(selectedStandardId)
                      void formik.setFieldValue('standardId', selectedStandardId)
                    }}

                  >
                    {common?.data?.data?.standards?.map((standard: CommonDto, index: number) => (
                      <MenuItem key={index} value={standard.id ?? 0}>
                        {standard.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={5} mb={5} >
              <Grid item xs={12} >
                <FormControl fullWidth >
                  <InputLabel>{t(KeyTranslation.Document_Products)}</InputLabel>
                  <Select
                    className='customSelect'
                    multiple
                    value={formik.values.documentProducts ?? []}
                    renderValue={(selected) => {
                      return selected?.map((p): string => {
                        const producted = product.data?.data?.find(s => s.id === p.productId)
                        if (producted != null) { return producted.name ?? '' }
                        return ''
                      }).join(', ')
                    }}
                    input={<OutlinedInput label={t(KeyTranslation.Document_Products)} />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      onKeyDown={(event) => { event.stopPropagation() }}
                    >
                      <TextField
                        size='small'
                        fullWidth
                        placeholder={t(KeyTranslation.Common_Search) ?? ''}
                        value={searchKeyword}
                        onChange={handleSearchChange}
                      />
                    </MenuItem>
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <List
                          width={Number(width)}
                          height={165}
                          itemSize={50}
                          itemCount={filteredProducts.length + 1 ?? 0}
                        >
                          {renderProductItem}
                        </List>
                      )}
                    </AutoSizer>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DropzoneDocument formik={formik} handleFileDocumentChange={handleFileDocumentChange} />

            <Box display="flex" justifyContent="end" gap={4}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
