import React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { Breadcrumb } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import {
  CommonApi,
  type CommonDto,
  ProductApi,
  ProjectApi,
  SystemApi,
  type LegDto,
  type SelectionProduct
} from '../../common/services'
import bgFloor from '../../../src/assets/images/floor.png'
import bgFloorRaise from '../../../src/assets/images/floorRaise.png'
import bgWall from '../../../src/assets/images/wall.png'
import bgRoof from '../../../src/assets/images/roof.png'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { ToastContainer, toast } from 'react-toastify'

import {
  ContainerFloorModal,
  ContainerFloorRaiseModal,
  ContainerHarnessModal,
  ContainerHlleaModal,
  ContainerPpeModal,
  ContainerRoofModal,
  ContainerRopeModal,
  ContainerTravellerModal,
  ContainerWallModal,
  InformationModal
} from './components'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import KeyTranslation from '../../common/KeyTranslation.json'

export default function OverviewSystem (): JSX.Element {
  const id = useParams()
  const projectId = Number(id.id)
  const systemId = Number(id.systemId)
  const [isHarnessOpen, setIsHarnessOpen] = React.useState(false)
  const [isPpeOpen, setIsPpeOpen] = React.useState(false)
  const [isHlleaOpen, setIsHlleaOpen] = React.useState(false)
  const [isRopeOpen, setIsRopeOpen] = React.useState(false)
  const [isTravellerOpen, setIsTravellerOpen] = React.useState(false)
  const [isInformationOpen, setIsInformationOpen] = React.useState(false)
  const [isReportsOpen, setIsReportsOpen] = React.useState(false)
  const handleOpenReports = (): void => {
    setIsReportsOpen(true)
  }
  const handleOpenInformation = (): void => {
    setIsInformationOpen(true)
  }
  const handleOpenHarness = (): void => {
    setIsHarnessOpen(true)
  }
  const handleOpenPpe = (): void => {
    setIsPpeOpen(true)
  }
  const handleOpenHllea = (): void => {
    setIsHlleaOpen(true)
  }
  const handleOpenRope = (): void => {
    setIsRopeOpen(true)
  }
  const handleOpenTraveller = (): void => {
    setIsTravellerOpen(true)
  }
  const { data: systemData, refetch: systemRefetch } = useQuery(
    ['/api/v2/System/{id}', systemId],
    async () => {
      const { data } = await new SystemApi().apiV2SystemIdGet(systemId)
      return data
    },
    { enabled: false }
  )
  const { data: projectData, refetch: projectRefetch } = useQuery(
    ['/api/v2/Project/GetProjectById', projectId],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  const { data: common, refetch: commonRefetch } = useQuery(
    ['/api/v2/Common'],
    async () => {
      const { data } = await new CommonApi().apiV2CommonGet()
      return data
    },
    { enabled: false }
  )
  const { data: product, refetch: productRefetch } = useQuery(
    [
      '/api/v2/Product/GetFilterProducts',
      {
        countryId: projectData?.data?.regionId,
        standardId: projectData?.data?.standardId,
        unitId: projectData?.data?.unitId,
        systemTypeId: systemData?.data?.systemTypeId,
        applicationLocationId: systemData?.data?.applicationLocationId
      }
    ],
    async () => {
      if (((systemData?.data) != null) && ((projectData?.data) != null)) {
        const { data } = await new ProductApi().apiV2ProductGetFilterProductsGet(
          projectData?.data?.regionId,
          projectData?.data?.standardId,
          projectData?.data?.unitId,
          systemData?.data?.systemTypeId,
          systemData?.data?.applicationLocationId
        )
        return data
      }
      // Return null or handle the case when dependencies are not available yet
      return null
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void commonRefetch()
    void projectRefetch()
    void systemRefetch()
  }, [])
  React.useEffect(() => {
    if (((systemData?.data) != null) && ((projectData?.data) != null)) {
      void productRefetch()
    }
  }, [systemData, projectData])

  const handleDuplicate = async (): Promise<void> => {
    const systemDataOverView = systemData?.data
    const updatedSystem = { ...systemDataOverView }
    if (updatedSystem.legs != null) {
      updatedSystem.legs = updatedSystem.legs.map((leg) => {
        const { id, ...legWithoutId } = leg
        if (legWithoutId.spans != null) {
          legWithoutId.spans = legWithoutId.spans.map((span) => {
            const { id, ...spanWithoutId } = span
            return spanWithoutId
          })
        }
        return legWithoutId
      })
    }
    const randomreference = uuidv4()

    try {
      const updatedSystemData = {
        ...updatedSystem,
        reference: randomreference
      }

      await new SystemApi().apiV2SystemPost(true, updatedSystemData)
      toast.success(t(KeyTranslation.Message_Duplicate_success))
      window.location.href = `/projects/${projectId}`
    } catch (ex: any) {
      console.log(ex)
    }
  }
  const extractComponents = (data: LegDto[] | null | undefined): number[] => {
    const allComponents: number[] = []
    data?.forEach(leg => {
      leg?.spans?.forEach(span => {
        span?.components?.forEach(component => {
          allComponents.push(component.productId ?? 0)
        })
      })
    })
    return allComponents
  }

  const getProductsById = (products: SelectionProduct[] | null | undefined, productIds: number[]): SelectionProduct[] | undefined => {
    return products?.filter((product) => productIds.includes(product.id ?? 0))
  }
  const getMatchProducts = getProductsById(product?.data, extractComponents(systemData?.data?.legs))
  const harnessesProductId = systemData?.data?.harnesses?.[0]?.productId ?? ''
  const filteredHarnessesProduct = product?.data?.find(
    (item) => item.id === harnessesProductId
  )
  const ppeProductId = systemData?.data?.ppEs?.[0]?.productId ?? ''
  const filteredPpeProduct = product?.data?.find(
    (item) => item.id === ppeProductId
  )
  const cableProductId = systemData?.data?.ropes?.[0]?.productId ?? ''
  const filteredCableProduct = product?.data?.find(
    (item) => item.id === cableProductId
  )
  const hlleaProductId = systemData?.data?.hlleAs?.[0]?.productId ?? ''
  const hlleaProductIdDampener = systemData?.data?.hlleAs?.[1]?.productId ?? ''
  const hlleaNumber = systemData?.data?.hlleAs?.[0]?.number ?? ''
  const productIds = systemData?.data?.hlleAs?.map(item => item.productId)
  const numberOfProductIds = (productIds != null) ? productIds.length : 0
  const filteredHlleaProduct = product?.data?.find(
    (item) => item.id === hlleaProductId
  )
  const filteredHlleaDampener = product?.data?.find(
    (item) => item.id === hlleaProductIdDampener
  )
  const travellerProductId = systemData?.data?.travellers?.[0]?.productId ?? ''
  const filteredTravellerProduct = product?.data?.find(
    (item) => item.id === travellerProductId
  )
  const locationMetricsArray =
    systemData?.data?.locationMetrics != null
      ? JSON.parse(systemData?.data?.locationMetrics)
      : []
  const floor = systemData?.data?.applicationLocationId === 4
  const floorRaise = systemData?.data?.applicationLocationId === 3
  const wall = systemData?.data?.applicationLocationId === 2
  const roof = systemData?.data?.applicationLocationId === 1
  return (
    <Box>
      <Grid container spacing={2} mb={4} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h1" mb={4}>
            {projectData?.data?.projectName}
          </Typography>
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} item xs={4}>
          <Typography variant="caption">
            {t(KeyTranslation.Common_CustomerName)}:{' '}
          </Typography>
          <Typography component={'span'}>
            {projectData?.data?.customerName}
          </Typography>
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} item xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_System_Ref)}:{' '}
          </Typography>
          <Typography component={'span'}>
            {systemData?.data?.reference}
          </Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component="div"
          label={<Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>}
        />
        <Breadcrumb
          component="div"
          label={
            <Link to={`/projects/${projectId}`}>
              {t(KeyTranslation.Common_ProjectOverview)}
            </Link>
          }
        />
        <Breadcrumb component="div" label={t(KeyTranslation.Common_SystemOverview)} />
      </Breadcrumbs>
      <Box sx={{ my: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h1">{t(KeyTranslation.Common_SystemOverview)}</Typography>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Button variant="outlined" startIcon={<EditOutlinedIcon />}>
              <Link to={`/projects/${projectId}/system/${systemId}`}>
                {t(KeyTranslation.Btn_EditLayout)}
              </Link>
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                void handleDuplicate()
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t(KeyTranslation.Common_Duplicate)}
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={5} alignItems="start" >
        <Grid item xs={12} md={6}>
          <Box className="main">
            <Box className="tableTools">
              <Typography variant="h4">
                {t(KeyTranslation.System_BasicInformation)}
              </Typography>
              <IconButton onClick={handleOpenInformation}>
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Grid py={3} container spacing={2}>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="caption">{t(KeyTranslation.Common_Name)}:</Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="subtitle1">
                  {systemData?.data?.name}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="caption">
                  {t(KeyTranslation.Common_System_Ref)}:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="subtitle1" className='wordWrap'>
                  {systemData?.data?.reference}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="caption">
                  {t(KeyTranslation.Common_Application_type)}:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="subtitle1">
                  {systemData?.data?.systemTypeName}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                <Typography variant="caption">
                  {t(KeyTranslation.Common_Application_location)}:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ minHeight: '30px' }}>
                {common?.data?.applicationLocations?.map(
                  (system: CommonDto, index: number) =>
                    system.id === systemData?.data?.applicationLocationId && (
                      <Typography key={index} variant="subtitle1">
                        {system.name}
                      </Typography>
                    )
                )}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
            <Box className="tableTools">
              <Typography variant="h4">
                {t(KeyTranslation.Common_Location_metrics)}
              </Typography>
              <IconButton onClick={handleOpenReports}>
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Grid py={3} container spacing={2}>
              {floorRaise || wall || roof
                ? (
                  <>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="caption">{t(KeyTranslation.Common_Anchor_height)}:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="subtitle1">
                        {locationMetricsArray[2]}{projectData?.data?.unitId === 2 ? ' ft' : ' m'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="caption">
                        {t(KeyTranslation.Common_Distance_from_edge)}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="subtitle1">
                        {locationMetricsArray[1]}{projectData?.data?.unitId === 2 ? ' ft' : ' m'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="caption">
                        {t(KeyTranslation.Common_Distance_from_ground)}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ minHeight: '30px' }}>
                      <Typography variant="subtitle1">
                        {locationMetricsArray[0]}{projectData?.data?.unitId === 2 ? ' ft' : ' m'}
                      </Typography>
                    </Grid>
                  </>
                  )
                : null}
              {floor && (
                <>
                  <Grid item xs={6} sx={{ minHeight: '30px' }}>
                    <Typography variant="caption">
                      {t(KeyTranslation.Common_Distance_from_edge)}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ minHeight: '30px' }}>
                    <Typography variant="subtitle1">
                      {locationMetricsArray[1]}{projectData?.data?.unitId === 2 ? ' ft' : ' m'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ minHeight: '30px' }}>
                    <Typography variant="caption">
                      {t(KeyTranslation.Common_Distance_from_ground)}:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ minHeight: '30px' }}>
                    <Typography variant="subtitle1">
                      {locationMetricsArray[0]}{projectData?.data?.unitId === 2 ? ' ft' : ' m'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              {floor && (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    size="small"
                    className=" floor-bottom inputSmall"
                    value={locationMetricsArray[0]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" floor-middle inputSmall"
                    value={locationMetricsArray[1]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <img className="imgApplication" src={bgFloor} />
                </Box>
              )}
              {floorRaise && (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    size="small"
                    className=" floorRaise-bottom inputSmall"
                    value={locationMetricsArray[0]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" floorRaise-middle inputSmall"
                    value={locationMetricsArray[1]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" floorRaise-top inputSmall"
                    value={locationMetricsArray[2]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <img className="imgApplication" src={bgFloorRaise} />
                </Box>
              )}
              {wall && (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    size="small"
                    className=" wall-bottom inputSmall"
                    value={locationMetricsArray[0]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" wall-middle inputSmall"
                    value={locationMetricsArray[1]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" wall-top inputSmall"
                    value={locationMetricsArray[2]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <img className="imgApplication" src={bgWall} />
                </Box>
              )}
              {roof && (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    size="small"
                    className=" roof-bottom inputSmall"
                    value={locationMetricsArray[0]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" roof-middle inputSmall"
                    value={locationMetricsArray[1]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    size="small"
                    className=" roof-top inputSmall"
                    value={locationMetricsArray[2]}
                    inputProps={{
                      readOnly: true
                    }}
                  />
                  <img className="imgApplication" src={bgRoof} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="main">
            <Box className="tableTools">
              <Typography variant="h4">
                {t(KeyTranslation.System_BaseComponents)}
              </Typography>
            </Box>
            <Box py={3}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="caption">
                    {t(KeyTranslation.Common_Harness)}:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {filteredHarnessesProduct?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <img
                    className="img"
                    src={filteredHarnessesProduct?.avatar ?? ''}
                  />
                </Grid>
                <Grid item xs={1} className='pdBtnBaseCpn'>
                  <IconButton onClick={handleOpenHarness}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="caption">
                    {t(KeyTranslation.System_PPE)}:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {filteredPpeProduct?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box className="ppe">
                    {filteredPpeProduct?.overview}
                    <img
                      className="img"
                      src={filteredPpeProduct?.avatar ?? ''}
                    />
                  </Box>
                </Grid>
                <Grid item xs={1} className='pdBtnBaseCpn'>
                  <IconButton onClick={handleOpenPpe}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="caption">
                    {t(KeyTranslation.System_Cable)}:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {filteredCableProduct?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <img
                    className="img"
                    src={filteredCableProduct?.avatar ?? ''}
                  />
                </Grid>
                <Grid item xs={1} className='pdBtnBaseCpn'>
                  <IconButton onClick={handleOpenRope}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
                {hlleaNumber === 1 && numberOfProductIds === 1 && (
                    <><Grid item xs={2}>
                      <Typography variant="caption">
                        {t(KeyTranslation.System_EnergyAbsorber)}
                      </Typography>
                    </Grid><Grid item xs={5}>
                        <Typography variant="subtitle1">
                          {filteredHlleaProduct?.name}
                        </Typography>
                      </Grid><Grid item xs={4}>
                        <img
                          className="img"
                          src={filteredHlleaProduct?.avatar ?? ''} />
                      </Grid><Grid item xs={1} className='pdBtnBaseCpn'>
                        <IconButton onClick={handleOpenHllea}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </Grid></>
                )}
                  {hlleaNumber === 2 && (
                    <><><Grid item xs={2}>
                    <Typography variant="caption">
                      {t(KeyTranslation.System_EnergyAbsorber)}
                    </Typography>
                  </Grid><Grid item xs={5}>
                      <Typography variant="subtitle1">
                        {filteredHlleaProduct?.name}
                      </Typography>
                    </Grid><Grid item xs={4}>
                      <img
                        className="img"
                        src={filteredHlleaProduct?.avatar ?? ''} />
                    </Grid><Grid item xs={1} className='pdBtnBaseCpn'>
                      <IconButton onClick={handleOpenHllea}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Grid></><><Grid item xs={2}>
                      <Typography variant="caption">
                      {`${t(KeyTranslation.System_EnergyAbsorber)} 2`}
                      </Typography>
                    </Grid><Grid item xs={5}>
                        <Typography variant="subtitle1">
                          {filteredHlleaProduct?.name}
                        </Typography>
                      </Grid><Grid item xs={4}>
                        <img
                          className="img"
                          src={filteredHlleaProduct?.avatar ?? ''} />
                      </Grid><Grid item xs={1} className='pdBtnBaseCpn'>
                        <IconButton onClick={handleOpenHllea}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </Grid></></>
                  )}
                  {hlleaNumber === 1 && numberOfProductIds === 2 && (
                    <><><Grid item xs={2}>
                    <Typography variant="caption">
                      {t(KeyTranslation.System_EnergyAbsorber)}
                    </Typography>
                  </Grid><Grid item xs={5}>
                      <Typography variant="subtitle1">
                        {filteredHlleaProduct?.name}
                      </Typography>
                    </Grid><Grid item xs={4}>
                      <img
                        className="img"
                        src={filteredHlleaProduct?.avatar ?? ''} />
                    </Grid><Grid item xs={1} className='pdBtnBaseCpn'>
                      <IconButton onClick={handleOpenHllea}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Grid></><><Grid item xs={2}>
                      <Typography variant="caption">
                      {`${t(KeyTranslation.System_EnergyAbsorber)} 2`}
                      </Typography>
                    </Grid><Grid item xs={5}>
                        <Typography variant="subtitle1">
                          {filteredHlleaDampener?.name}
                        </Typography>
                      </Grid><Grid item xs={4}>
                        <img
                          className="img"
                          src={filteredHlleaDampener?.avatar ?? ''} />
                      </Grid><Grid item xs={1} className='pdBtnBaseCpn'>
                        <IconButton onClick={handleOpenHllea}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </Grid></></>
                  )}
                <Grid item xs={2}>
                  <Typography variant="caption">
                    {t(KeyTranslation.System_Traveller)}:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {filteredTravellerProduct?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <img
                    className="img"
                    src={filteredTravellerProduct?.avatar ?? ''}
                  />
                </Grid>
                <Grid item xs={1} className='pdBtnBaseCpn'>
                  <IconButton onClick={handleOpenTraveller}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
            <Box className="tableTools">
              <Typography variant="h4">{t(KeyTranslation.Common_Components)}</Typography>
              <IconButton>
                <Link className='link' to={`/projects/${projectId}/system/${systemId}`}>
                  <EditOutlinedIcon />
                </Link>
              </IconButton>
            </Box>
            <Box py={3}>
              {((getMatchProducts != null) && getMatchProducts?.length > 0)
                ? getMatchProducts.map(product => {
                  if (product?.avatar != null) {
                    return (
                      <Box key={product.id} className='components'>
                        <img width={80} src={product.avatar} alt="" />
                        <Box>
                          <Typography fontWeight={'bold'}>{product.name}</Typography>
                          <Typography>{t(KeyTranslation.Common_Model_No)}: {product.modelNo}</Typography>
                        </Box>
                      </Box>
                    )
                  }
                  return null
                })
                : <Typography sx={{ textAlign: 'center' }} variant='subtitle1'>{t(KeyTranslation.No_Data_Found)}</Typography>
              }
            </Box>
          </Box>
          {isInformationOpen && (
            <InformationModal
              open={isInformationOpen}
              onClose={(): void => {
                setIsInformationOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              common={common?.data}
              systemDatarefetch={systemRefetch}
            />
          )}
          {floor && isReportsOpen && (
            <ContainerFloorModal
              open={isReportsOpen}
              onClose={(): void => {
                setIsReportsOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              unitId={projectData?.data?.unitId}
            />
          )}
          {floorRaise && isReportsOpen && (
            <ContainerFloorRaiseModal
              open={isReportsOpen}
              onClose={(): void => {
                setIsReportsOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              unitId={projectData?.data?.unitId}
            />
          )}
          {wall && isReportsOpen && (
            <ContainerWallModal
              open={isReportsOpen}
              onClose={(): void => {
                setIsReportsOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              unitId={projectData?.data?.unitId}
            />
          )}
          {roof && isReportsOpen && (
            <ContainerRoofModal
              open={isReportsOpen}
              onClose={(): void => {
                setIsReportsOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              unitId={projectData?.data?.unitId}
            />
          )}
          {isHarnessOpen && (
            <ContainerHarnessModal
              open={isHarnessOpen}
              onClose={() => {
                setIsHarnessOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              product={product?.data}
            />
          )}
          {isPpeOpen && (
            <ContainerPpeModal
              open={isPpeOpen}
              onClose={() => {
                setIsPpeOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              product={product?.data}
            />
          )}

          {isHlleaOpen && (
            <ContainerHlleaModal
              open={isHlleaOpen}
              onClose={() => {
                setIsHlleaOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              product={product?.data}
            />
          )}
          {isRopeOpen && (
            <ContainerRopeModal
              open={isRopeOpen}
              onClose={() => {
                setIsRopeOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              product={product?.data}
            />
          )}
          {isTravellerOpen && (
            <ContainerTravellerModal
              open={isTravellerOpen}
              onClose={() => {
                setIsTravellerOpen(false)
              }}
              project={projectData?.data}
              systemData={systemData?.data}
              systemDatarefetch={systemRefetch}
              product={product?.data}
            />
          )}
        </Grid>
      </Grid>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}
