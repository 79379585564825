import * as React from 'react'
import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  NoteApi,
  type NoteDto,
  ReportApi,
  SystemApi,
  type SystemDto,
  ProjectDocumentApi,
  type ProjectDocumentDto,
  BlobDocumentApi,
  type ReportDto
} from '../../../common/services'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  MenuItem,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { t } from 'i18next'
import moment from 'moment'
import pdf from '../../../assets/images/mc-file-pdf.svg'
import unKnown from '../../../assets/images/mc-file-unknown.svg'
import sheet from '../../../assets/images/mc-file-spreadsheet.svg'
// eslint-disable-next-line import/no-duplicates
import png from '../../../assets/images/mc-file-image.svg'
// eslint-disable-next-line import/no-duplicates
import jpeg from '../../../assets/images/mc-file-image.svg'
import document from '../../../assets/images/mc-file-document.svg'
import plain from '../../../assets/images/mc-file-text.svg'
import { ToastContainer } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface ViewReportModal {
  selectedItem: ReportDto | undefined
}
const icons: Record<string, string> = {
  pdf,
  unKnown,
  sheet,
  png,
  jpeg,
  document,
  plain
}
export default function ViewReport ({
  selectedItem
}: ViewReportModal): JSX.Element {
  const id = useParams()
  const projectId = Number(id.id)
  const { data: reportData, refetch: ReportRefetch } = useQuery(
    ['/api/v2/Report/{id}', selectedItem?.id],
    async () => {
      const { data } = await new ReportApi().apiV2ReportIdGet(
        selectedItem?.id ?? 0
      )
      return data
    },
    { enabled: false }
  )
  const { data: SystemData, refetch: SystemRefetch } = useQuery(
    ['/api/v2/System/{id}', projectId],
    async () => {
      const { data } = await new SystemApi().apiV2SystemGetAllProjectIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  const { data: NoteData, refetch: NoteRefetch } = useQuery(
    ['/api/v2/Note/GetByProjectId', projectId],
    async () => {
      const { data } = await new NoteApi().apiV2NoteGetByProjectIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  const { data: ProjectDocumentData, refetch: ProjectDocumentRefetch } =
    useQuery(
      ['/api/v2/ProjectDocument/GetByProject/{projectId}', projectId],
      async () => {
        const { data } =
          await new ProjectDocumentApi().apiV2ProjectDocumentGetByProjectProjectIdGet(
            projectId
          )
        return data
      },
      { enabled: false }
    )
  const { data: SupportingDocumentData, refetch: SupportingDocumentRefetch } =
    useQuery(
      ['/api/v2/BlobDocument/{id}', projectId],
      async () => {
        const { data } =
        await new BlobDocumentApi().apiV2BlobDocumentGetByProjectIdIdGet(
          projectId
        )
        return data
      },
      { enabled: false }
    )
  React.useEffect(() => {
    if (selectedItem != null) {
      void ReportRefetch()
      void SystemRefetch()
      void NoteRefetch()
      void ProjectDocumentRefetch()
      void SupportingDocumentRefetch()
    }
  }, [selectedItem])
  const list = (
    <Box>
      <List>
        <ListItem disablePadding>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {t(KeyTranslation.General_Info)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="caption">
                    {t(KeyTranslation.Common_CustomerName)}:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    {reportData?.data?.name}
                  </Typography>
                </AccordionDetails>
                <AccordionDetails>
                  <Typography variant="caption">
                    {t(KeyTranslation.Popup_Description)}:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    {reportData?.data?.description}
                  </Typography>
                </AccordionDetails>
                <AccordionDetails>
                  <Typography variant="caption">
                    {t(KeyTranslation.Report_NextInspectionDate)}:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    {moment(reportData?.data?.nextInspectionDate).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                  </Typography>
                </AccordionDetails>
                <AccordionDetails>
                  <Typography variant="caption">
                    {t(KeyTranslation.Common_Generated_by)}:{' '}
                  </Typography>
                  <Typography component={'span'}>
                    {reportData?.data?.generatedBy}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {t(KeyTranslation.Report_Systems)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {SystemData?.data
                    ?.filter((system) =>
                      reportData?.data?.reportSystems?.some(
                        (reportSystem) => reportSystem.systemId === system.id
                      )
                    )
                    .map((system: SystemDto) => (
                      <MenuItem key={system.id}>
                        <FormControlLabel
                          control={<Checkbox checked={true} />}
                          label={system.name}
                        />
                      </MenuItem>
                    ))}
                  {SystemData?.data?.filter((system) =>
                    reportData?.data?.reportSystems?.some(
                      (reportSystem) => reportSystem.systemId === system.id
                    )
                  )?.length === 0 && (
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t(KeyTranslation.No_Data_Found)}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {t(KeyTranslation.Note_Notes)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {NoteData?.data
                    ?.filter((note) =>
                      reportData?.data?.reportNotes?.some(
                        (reportNote) => reportNote.noteId === note.id
                      )
                    )
                    .map((note: NoteDto) => (
                      <MenuItem key={note.id}>
                        <FormControlLabel
                          control={<Checkbox checked={true} />}
                          label={note.title}
                        />
                      </MenuItem>
                    ))}
                  {NoteData?.data?.filter((note) =>
                    reportData?.data?.reportNotes?.some(
                      (reportNote) => reportNote.noteId === note.id
                    )
                  ).length === 0 && (
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t(KeyTranslation.No_Data_Found)}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {t(KeyTranslation.Project_Documents)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {ProjectDocumentData?.data
                    ?.filter((project) =>
                      reportData?.data?.reportProjectDocuments?.some(
                        (reportDocument) =>
                          reportDocument.documentId === project.id
                      )
                    )
                    .map((project: ProjectDocumentDto) => (
                      <MenuItem key={project.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox checked={true} />}
                            label=""
                            sx={{ marginRight: 0 }}
                          />
                         {(() => {
                           const fileType = project.type?.split('/').pop()?.toLowerCase()
                           const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                             ? icons.sheet
                             : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                               ? icons.document
                               : icons[fileType ?? 'unKnown'] ?? icons.unKnown
                           return (
                        <>
                            <img
                              width={'100%'}
                              height={30}
                              style={{ objectFit: 'contain' }}
                              src={iconUrl}
                              alt={project.name ?? ''}
                            />
                        </>
                           )
                         })()}
                          <Typography>{project.name}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  {ProjectDocumentData?.data?.filter((document) =>
                    reportData?.data?.reportProjectDocuments?.some(
                      (reportDocument) =>
                        reportDocument.documentId === document.id
                    )
                  ).length === 0 && (
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t(KeyTranslation.No_Data_Found)}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: '600' }}>
                    {t(KeyTranslation.Report_Supportingdocument)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                {SupportingDocumentData?.data
                  ?.filter((project) =>
                    reportData?.data?.reportBlobDocuments?.some(
                      (reportDocument) =>
                        reportDocument.documentId === project.id
                    )
                  )
                  .map((project: ProjectDocumentDto) => (
                      <MenuItem key={project.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox checked={true} />}
                            label=""
                            sx={{ marginRight: 0 }}
                          />
                          {(() => {
                            const fileType = project.type?.split('/').pop()?.toLowerCase()
                            const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                              ? icons.sheet
                              : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
                                ? icons.document
                                : icons[fileType ?? 'unKnown'] ?? icons.unKnown
                            return (
                        <>
                            <img
                              width={'100%'}
                              height={30}
                              style={{ objectFit: 'contain' }}
                              src={iconUrl}
                              alt={project.name ?? ''}
                            />
                        </>
                            )
                          })()}
                          <Typography>{project.name}</Typography>
                        </Box>
                      </MenuItem>
                  ))}
                  {SupportingDocumentData?.data?.filter((project) =>
                    reportData?.data?.reportBlobDocuments?.some(
                      (reportProjectDocument) =>
                        reportProjectDocument.documentId === project.id
                    )
                  ).length === 0 && (
                    <Typography
                      sx={{ textAlign: 'center' }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {t(KeyTranslation.No_Data_Found)}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </ListItem>
      </List>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )

  return (
    <>
      <div>{list}</div>
    </>
  )
}
