import React, { useCallback } from 'react'
import { Box, Button, IconButton, InputAdornment, type TablePaginationProps, TextField, Tooltip, Typography } from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector, type GridColDef } from '@mui/x-data-grid'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { LanguageApi, type LanguageDto } from '../../common/services'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import {
  useQuery
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { LanguageModal } from './components'
import AlertDelete from '../../common/components/AlertDelete'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import ListLanguage from './components/helpers/LanguageJson.json'
import MuiPagination from '@mui/material/Pagination'

export default function SystemDetail (): JSX.Element {
  const { t } = useTranslation()
  const { data, isLoading, isFetching, refetch } = useQuery(['/api/v2/Language'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGet()
    return data
  })
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDelete = async (id: number): Promise<void> => {
    try {
      const response = await new LanguageApi().apiV2LanguageIdDelete(id)
      if (response.status === 209) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        await refetch()
        handleDialogClose()
        toast.success(t(KeyTranslation.Message_Delete_Success))
      }
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }

  const [languageModalProps, setLanguageModalProps] = React.useState<{ visible: boolean, data?: LanguageDto, isEditing: boolean }>({
    visible: false,
    data: undefined,
    isEditing: false
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm.length === 0) {
      return data.data
    }

    return data.data?.filter((language: LanguageDto) => {
      const searchTermLower = searchTerm.toLowerCase()
      const countryNameLower = ((ListLanguage.find(lang => lang.code === language.code)?.country)?.toLocaleLowerCase())

      return (
        (language.code?.toLowerCase().includes(searchTermLower) ?? false) ||
        (language.name?.toLowerCase().includes(searchTermLower) ?? false) ||
        countryNameLower?.includes(searchTermLower)
      )
    })
  }, [data, searchTerm])
  const columnsDummy: Array<GridColDef<LanguageDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Language_LanguageName) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <a style={{ cursor: 'pointer' }} onClick={() => {
            setLanguageModalProps({
              visible: true,
              data: row,
              isEditing: true
            })
          }}>{row.name}</a>
        )
      }
    },
    {
      field: 'countryName',
      headerName: t(KeyTranslation.Country_CountryName) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        // const updatedListLanguage = ListLanguage.map(language => {
        //   const { code } = language
        //   if (code.includes('-')) {
        //     const specialCode = code.split('-')[1]
        //     return { ...language, countryCode: specialCode }
        //   } else {
        //     return language
        //   }
        // })
        const languageInfo = ListLanguage.find(language => language.code === row.code)
        if (languageInfo != null) {
          return languageInfo.country
        }
        return null
      }
    },
    {
      field: 'icon',
      headerName: t(KeyTranslation.Header_Flag) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        if (row.flag != null) {
          return <img src={row.flag} alt={row.name ?? ''} height="30" width="50" style={{ objectFit: 'contain' }} />
        }
      }
    },
    {
      field: 'code',
      headerName: t(KeyTranslation.Country_CountryCode) ?? '',
      flex: 1
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 120,
      renderCell: ({ row }) => {
        return <Box display="flex" gap={3}>
          <Tooltip title={t(KeyTranslation.Btn_Edit)}>
            <IconButton onClick={() => {
              setLanguageModalProps({
                visible: true,
                data: row,
                isEditing: true
              })
            }}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Btn_Delete)}>
            <IconButton onClick={() => {
              setDialogOpen({
                open: true,
                id: row.id ?? 0
              })
            }}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext()
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }
  return (
    <Box>
      <Typography variant='h1'>{t(KeyTranslation.Common_Language)}</Typography>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5 }} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size='small'
            value={searchTerm}
            onChange={(event) => { setSearchTerm(event.target.value) }}
          />
          <Button variant='contained' onClick={() => {
            setLanguageModalProps({
              visible: true,
              data: undefined,
              isEditing: false
            })
          }} >{t(KeyTranslation.Language_New_Language)}</Button>
        </Box>
        <StyledDataGrid
          autoHeight
          loading={isLoading || isFetching}
          rows={filteredData ?? []}
          columns={columnsDummy}
          rowSelection={false}
          disableColumnMenu={true}
          slots={{
            pagination: CustomPagination
          }}
        />
        {languageModalProps.visible &&
          <LanguageModal open={languageModalProps.visible} isEditing={languageModalProps.isEditing} language={languageModalProps.data} onClose={(): void => {
            setLanguageModalProps({
              visible: false,
              data: undefined,
              isEditing: false
            })
          }} />
        }
        <AlertDelete handleDelete={() => { void handleDelete(dialogOpen.id) }} dialogOpen={dialogOpen.open} handleDialogClose={handleDialogClose} />
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}
