import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Arrow } from '../../../../public/icons/arrow.svg'
import { Typography } from '@mui/material'

const SidebarItemDiv = styled.div<{ visible?: any, height?: string }>`
  height: ${(props) =>
    (Boolean((props.visible ?? false))) && (props.height != null) && props.height !== 'full'
      ? `${props.height}`
      : 'auto'};
  flex: ${(props) =>
    (Boolean((props.visible ?? false))) && (props.height != null) && props.height === 'full' ? '1' : 'unset'};
  color: #545454;
`

const Chevron = styled.a<{ visible: boolean }>`
  transform: rotate(${(props) => (props.visible ? 180 : 0)}deg);
  svg {
    width: 8px;
    height: 8px;
  }
`

export interface SidebarItemProps {
  title: string
  height?: string
  // icon: string
  visible?: boolean
  onChange?: (bool: boolean) => void
  children?: React.ReactNode
}

const HeaderDiv = styled.div`
  color: #615c5c;
  height: 45px;
  svg {
    fill: #707070;
  }
`

export const SidebarItem: React.FC<SidebarItemProps> = ({
  visible,
  // icon,
  title,
  children,
  height,
  onChange
}) => {
  return (
    <SidebarItemDiv visible={(visible ?? false) ? 'true' : undefined} height={height} className="flex flex-col">
      <HeaderDiv
        onClick={() => {
          if (onChange != null) onChange(!(visible ?? false))
        }}
        className={`cursor-pointer bg-white border-b last:border-b-0 flex items-center px-2 ${
          (visible ?? false) ? 'shadow-sm' : ''
        }`}
      >
        <div className="flex-1 flex items-center">
          {/* {React.createElement(icon, { className: 'w-4 h-4 mr-2' })} */}
          <Typography variant='h5'>{title}</Typography>
        </div>
        <Chevron visible={visible ?? false}>
          <Arrow />
        </Chevron>
      </HeaderDiv>
      {(visible ?? false)
        ? (
        <div className="w-full flex-1 overflow-auto">{children}</div>
          )
        : null}
    </SidebarItemDiv>
  )
}
