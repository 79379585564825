import globalAxios from 'axios'
import localStorage from './localStorage'
import { type UserProfileModel } from './services'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const requestHandler = async (request: any) => {
  const storageToken: UserProfileModel = await localStorage.getItem('LOGIN')
  if (storageToken !== null) {
    request.headers.Authorization = `Bearer ${storageToken?.token ?? ''}`
  }
  return request
}

globalAxios.interceptors.request.use(
  async (request) => await requestHandler(request),
  async (requestError) => {
    return await Promise.reject(requestError)
  }
)

globalAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
      return false
    }
    return await Promise.reject(error)
  }
)
