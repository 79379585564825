/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientDtoListApiResponse } from '../models';
// @ts-ignore
import { Int32ApiResponse } from '../models';
// @ts-ignore
import { ProjectDto } from '../models';
// @ts-ignore
import { ProjectDtoApiResponse } from '../models';
// @ts-ignore
import { ProjectDtoIEnumerableApiResponse } from '../models';
// @ts-ignore
import { StringApiResponse } from '../models';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project/GetClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetProjectByIdGet: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project/GetProjectById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {number} [companyId] 
         * @param {Array<string>} [childCompanyGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetProjectsByPagingGet: async (page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, companyId?: number, childCompanyGuids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project/GetProjectsByPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (isDescending !== undefined) {
                localVarQueryParameter['IsDescending'] = isDescending;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }

            if (childCompanyGuids) {
                localVarQueryParameter['ChildCompanyGuids'] = childCompanyGuids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV2ProjectIdDelete', 'id', id)
            const localVarPath = `/api/v2/Project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectPost: async (projectDto?: ProjectDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectPut: async (projectDto?: ProjectDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectUniqueStringGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Project/UniqueString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectGetClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDtoListApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectGetClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectGetProjectByIdGet(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDtoApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectGetProjectByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {number} [companyId] 
         * @param {Array<string>} [childCompanyGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectGetProjectsByPagingGet(page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, companyId?: number, childCompanyGuids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectGetProjectsByPagingGet(page, pageSize, search, sortField, isDescending, companyId, childCompanyGuids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectPost(projectDto?: ProjectDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectPost(projectDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectPut(projectDto?: ProjectDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectPut(projectDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ProjectUniqueStringGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ProjectUniqueStringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetClientsGet(options?: any): AxiosPromise<ClientDtoListApiResponse> {
            return localVarFp.apiV2ProjectGetClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetProjectByIdGet(id?: number, options?: any): AxiosPromise<ProjectDtoApiResponse> {
            return localVarFp.apiV2ProjectGetProjectByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {number} [companyId] 
         * @param {Array<string>} [childCompanyGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectGetProjectsByPagingGet(page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, companyId?: number, childCompanyGuids?: Array<string>, options?: any): AxiosPromise<ProjectDtoIEnumerableApiResponse> {
            return localVarFp.apiV2ProjectGetProjectsByPagingGet(page, pageSize, search, sortField, isDescending, companyId, childCompanyGuids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2ProjectIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectPost(projectDto?: ProjectDto, options?: any): AxiosPromise<Int32ApiResponse> {
            return localVarFp.apiV2ProjectPost(projectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectDto} [projectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectPut(projectDto?: ProjectDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2ProjectPut(projectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ProjectUniqueStringGet(options?: any): AxiosPromise<StringApiResponse> {
            return localVarFp.apiV2ProjectUniqueStringGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectGetClientsGet(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectGetClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectGetProjectByIdGet(id?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectGetProjectByIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [sortField] 
     * @param {boolean} [isDescending] 
     * @param {number} [companyId] 
     * @param {Array<string>} [childCompanyGuids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectGetProjectsByPagingGet(page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, companyId?: number, childCompanyGuids?: Array<string>, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectGetProjectsByPagingGet(page, pageSize, search, sortField, isDescending, companyId, childCompanyGuids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectDto} [projectDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectPost(projectDto?: ProjectDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectPost(projectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectDto} [projectDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectPut(projectDto?: ProjectDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectPut(projectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiV2ProjectUniqueStringGet(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiV2ProjectUniqueStringGet(options).then((request) => request(this.axios, this.basePath));
    }
}
