import * as React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  CssBaseline,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import {
  ProjectApi,
  ReportApi,
  type ReportInfoDto,
  type ReportDto
} from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import 'react-toastify/dist/ReactToastify.css'
import { type GridSortModel, type GridColDef } from '@mui/x-data-grid'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import { ToastContainer, toast } from 'react-toastify'
import AlertDelete from '../../common/components/AlertDelete'
import JSZip from 'jszip'
import moment from 'moment'
import { ContainerModelReport, ViewReport } from './components'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import KeyTranslation from '../../common/KeyTranslation.json'

const drawerWidth = 400
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  width: 102,
  ...((open ?? false) && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  position: 'relative'
}))
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))
export default function Reports (): JSX.Element {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [visibleReport, setVisibleReport] = React.useState(false)
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<ReportDto | undefined>(
    undefined
  )
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const [paginationReport, setPaginationReport] = React.useState({
    page: 0,
    pageSize: 5
  })
  const [sortBy, setSortBy] = React.useState({
    sortField: 'createdDate',
    isDescending: true
  })
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map(item => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationReport({
        page: 0,
        pageSize: paginationReport.pageSize
      })
    }
  }
  const handleDrawerOpen =
    (open: boolean, id: number) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return
        }

        setOpen(open)
        const selectedItem = reportData?.data?.find((item) => item.id === id)
        setSelectedItem(selectedItem)
      }
  const handleDrawerClose = (): void => {
    setOpen(false)
  }
  const {
    data: reportData,
    isLoading: reportIsLoading,
    isFetching: reportIsFetching,
    refetch: reportRefetch
  } = useQuery(
    ['/api/v2/Report/SearchReportByPaging', id, paginationReport],
    async () => {
      if (id !== undefined) {
        const { data } =
          await new ReportApi().apiV2ReportSearchReportByPagingGet(
            Number(id),
            paginationReport.page + 1,
            paginationReport.pageSize,
            '',
            sortBy.sortField,
            sortBy.isDescending
          )
        return data
      }
    },
    { enabled: false }
  )
  const { data: projectData, refetch: projectRefetch } = useQuery(
    ['/api/v2/Project/GetProjectById', id],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        Number(id)
      )
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void reportRefetch()
  }, [paginationReport, sortBy])
  React.useEffect(() => {
    void projectRefetch()
  }, [])
  const handleDownloadFullReport = async (
    values: ReportInfoDto
  ): Promise<void> => {
    try {
      setLoading(true)
      const id = Number(values.id)
      const url = String(values.url)
      const response = await new ReportApi().apiV2ReportDownloadIdGet(id)
      if (response.status === 209) {
        const errorMessage = (response.data as any).error
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${errorMessage}`))
      } else {
        if (id != null) {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', values.name ?? '')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const handleDownloadQuickReport = async (
    values: ReportInfoDto
  ): Promise<void> => {
    try {
      setLoading(true)
      const id = Number(values.id)
      const response = await new ReportApi().apiV2ReportDownloadIdGet(id, {
        responseType: 'arraybuffer'
      })
      if (response.status === 209) {
        const errorBuffer = new Uint8Array(response.data as any)
        const errorText = new TextDecoder('utf-8').decode(errorBuffer)
        const errorObject = JSON.parse(errorText)
        const errorMessage = errorObject.error
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${errorMessage}`))
      } else {
        const zipArrayBuffer = response.data
        const zip = new JSZip()
        const extractedFiles = await zip.loadAsync(zipArrayBuffer as any)
        const pdfFiles = Object.values(extractedFiles.files).filter((file) =>
          file.name.toLowerCase().endsWith('.pdf')
        )

        if (pdfFiles.length > 0) {
          const downloadPromises = pdfFiles.map(async (pdfFile, index) => {
            await pdfFile.async('blob').then((fileContent) => {
              console.log(fileContent)
              const fileName = pdfFile.name
              const blob = new Blob([fileContent], { type: 'application/pdf' })
              const url = URL.createObjectURL(blob)

              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(url)
            })
          })

          await Promise.all(downloadPromises)
        }
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setLoading(false)
    }
  }

  const handleDialogClose = React.useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        setLoading(true)
        const response = await new ReportApi().apiV2ReportIdDelete(id)
        if (response.status === 209) {
          handleDialogClose()
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          await reportRefetch()
          handleDialogClose()
          handleDrawerClose()
          toast.success(t(KeyTranslation.Message_Delete_Success))
        }
      } catch (ex: any) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${ex.response.data.error}`))
      } finally {
        setLoading(false)
      }
    },
    [reportRefetch, handleDialogClose]
  )
  const columnsDummyNote: Array<GridColDef<ReportDto>> = [
    {
      field: 'reportname',
      headerName: t(KeyTranslation.Header_ReportName) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        return <Tooltip title={row.name}>
        <Typography>{row.name}</Typography>
      </Tooltip>
      }
    },
    {
      field: 'description',
      headerName: t(KeyTranslation.Header_Description) ?? '',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <Tooltip title={row.description}>
        <Typography>{row.description}</Typography>
      </Tooltip>
      }
    },
    {
      field: 'systemNames',
      headerName: t(KeyTranslation.Report_Systems) ?? '',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <Tooltip title={row.systemNames}>
        <Typography>{row.systemNames}</Typography>
      </Tooltip>
      }
    },
    {
      field: 'createdDate',
      headerName: t(KeyTranslation.Header_CreatedDate) ?? '',
      width: 150,
      renderCell: ({ row }) => {
        const convertedTime = moment(row.createdDate).format(
          'YYYY-MM-DD HH:mm'
        )
        return <Tooltip title={convertedTime}>
        <Typography>{convertedTime}</Typography>
      </Tooltip>
      }
    },
    {
      field: 'generatedby',
      headerName: t(KeyTranslation.Header_GeneratedBy) ?? '',
      width: 120,
      renderCell: ({ row }) => {
        return <Tooltip title={row.generatedBy}>
        <Typography>{row.generatedBy}</Typography>
      </Tooltip>
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 220,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" gap={3}>
            <Tooltip
              title={t(KeyTranslation.Btn_View)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton onClick={handleDrawerOpen(true, row.id ?? 0)}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_QuickReport)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                onClick={() => {
                  void handleDownloadQuickReport(row)
                }}
              >
                <ArrowDownwardOutlinedIcon></ArrowDownwardOutlinedIcon>
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_DownloadZIP)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                onClick={() => {
                  void handleDownloadFullReport(row)
                }}
              >
                <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_Delete)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                onClick={() => {
                  setDialogOpen({
                    open: true,
                    id: Number(row.id)
                  })
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ]
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Main open={open}>
          <Box>
            <Grid container spacing={2} mb={4} alignItems="center">
              <Grid xs={6}>
                <Typography variant="h1" mb={4}>
                  {projectData?.data?.projectName}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography variant="caption">
                  {t(KeyTranslation.Common_CustomerName)}:{' '}
                </Typography>
                <Typography component={'span'}>
                  {projectData?.data?.customerName}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography component={'span'} variant="caption">
                  {t(KeyTranslation.Common_System_Ref)}:{' '}
                </Typography>
                <Typography component={'span'}>
                  {projectData?.data?.reference}
                </Typography>
              </Grid>
            </Grid>
            <Breadcrumbs aria-label="breadcrumb">
              <Breadcrumb
                component="div"
                label={
                  <Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>
                }
              />
              <Breadcrumb
                component="div"
                label={
                  <Link to={`/projects/${Number(id)}`}>
                    {t(KeyTranslation.Common_ProjectOverview)}
                  </Link>
                }
              />
              <Breadcrumb component="div" label={t(KeyTranslation.Common_Report_Reports)} />
            </Breadcrumbs>
            {loading && (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              />
            )}
            <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
              <Box className="tableTools">
                <Typography variant="h4">{t(KeyTranslation.Common_Reports)}</Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setVisibleReport(true)
                  }}
                >
                  {t(KeyTranslation.New_Report)}
                </Button>
              </Box>
              <Box>
                <StyledDataGrid
                  autoHeight
                  loading={reportIsLoading || reportIsFetching}
                  rows={reportData?.data ?? []}
                  columns={columnsDummyNote}
                  rowSelection={false}
                  disableColumnMenu={true}
                  paginationMode="server"
                  onPaginationModelChange={setPaginationReport}
                  getRowId={(row) => row.id}
                  rowCount={reportData?.totals ?? 0}
                  paginationModel={paginationReport}
                  editMode="row"
                  sortingMode="server"
                  onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
                />
              </Box>
              <AlertDelete
                handleDelete={() => {
                  void handleDelete(dialogOpen.id)
                }}
                dialogOpen={dialogOpen.open}
                handleDialogClose={handleDialogClose}
              />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Box>
            {visibleReport && (
              <ContainerModelReport
                open={visibleReport}
                onClose={() => {
                  setVisibleReport(false)
                }}
                reportRefetch={reportRefetch}
              />
            )}
          </Box>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth
            }
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Box
            sx={{
              marginTop: '64px',
              display: 'flex',
              justifyContent: 'space-between',
              p: 5,
              alignItems: 'center',
              height: '76px'
            }}
          >
            <Typography variant="h4" sx={{ background: '#ffff' }}>
              {t(KeyTranslation.Common_Reports)}
            </Typography>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl'
                  ? (
                  <ChevronLeftIcon />
                    )
                  : (
                  <ChevronRightIcon />
                    )}
              </IconButton>
            </DrawerHeader>
          </Box>
          <Box>
            <ViewReport selectedItem={selectedItem} />
          </Box>
        </Drawer>
      </Box>
    </>
  )
}
