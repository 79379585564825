import React from 'react'
import { Box, Button, Modal, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { type NoteDto, NoteApi } from '../../../common/services'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface NoteModalProps {
  open: boolean
  onClose: () => void
  noteRefetch: any
  revisionHistoryRefetch?: any
}
export default function NoteModal ({ open, onClose, noteRefetch, revisionHistoryRefetch }: NoteModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const [noteData] = React.useState<NoteDto>({
    title: '',
    description: ''
  })
  const validationSchema = Yup.object({
    title: Yup.string().required(t(KeyTranslation.Message_NoteTitle_invalid) ?? ''),
    description: Yup.string().required(t(KeyTranslation.Message_Description_invalid) ?? '')
  })

  const handleSubmit = React.useCallback(async (values: NoteDto) => {
    const projectId = Number(id)
    try {
      setIsLoading(true)
      const response = await new NoteApi().apiV2NotePost({
        ...values,
        projectId
      })
      console.log(response)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        toast.success(t(KeyTranslation.Message_CreateNew_Success))
        onClose()
        noteRefetch()
        revisionHistoryRefetch()
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }, [onClose])

  const formik = useFormik({
    initialValues: noteData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Note_New_Note)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={4} mb={7}>
              <TextField
                value={formik.values.title}
                onChange={(e) => {
                  void formik.setFieldValue('title', e.target.value)
                }}
                label={t(KeyTranslation.Note_Title)}
                variant="outlined"
                fullWidth
                helperText={t(KeyTranslation.Note_About)}
              />
            </Box>
            <Box mt={4} mb={7}>
              <TextField
                value={formik.values.description}
                onChange={(e) => {
                  void formik.setFieldValue('description', e.target.value)
                }}
                label={t(KeyTranslation.Note_Description)}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            </Box>
            <Box display="flex" justifyContent="end" gap={4}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
