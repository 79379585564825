import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Box, FormControl, IconButton, InputAdornment, MenuItem, Select, type TablePaginationProps, TextField, Tooltip, Typography } from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { LanguageApi, type TranslationDto, type LanguageDto } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { t } from 'i18next'
import { GridRowModes, type GridColDef, type GridRowId, type GridRowModesModel, type GridEventListener, GridRowEditStopReasons, type GridRowModel, GridPagination, useGridApiContext, useGridSelector, gridPageCountSelector } from '@mui/x-data-grid-pro'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import MuiPagination from '@mui/material/Pagination'

export default function SystemDetail (): JSX.Element {
  const [selectedLanguage, setSelectedLanguage] = useState('en-US')
  const [searchTerm, setSearchTerm] = useState('')
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [englishTranslations, setEnglishTranslations] = useState<TranslationDto[]>([])
  const { data: languagesData, isLoading: languagesLoading, isFetching: languageFetching, refetch: refetchLanguage } = useQuery(['/api/v2/Language'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGet()
    return data
  }, { enabled: false })

  const selectedLanguageCode = useMemo(() => {
    return (selectedLanguage.length > 0) && (languagesData != null) ? languagesData.data?.find(lang => lang.code === selectedLanguage)?.code ?? '' : ''
  }, [selectedLanguage, languagesData])

  const { data: translationsData, refetch: refetchTranslations } = useQuery(['/api/v2/Language/GetTranslateDataByCode/{code}', selectedLanguageCode], async () => {
    if (selectedLanguageCode.length > 0) {
      const { data } = await new LanguageApi().apiV2LanguageGetTranslateDataByCodeCodeGet(selectedLanguageCode)
      return data
    }
    return null
  }, { enabled: false })

  const { data: englishData, refetch: refetchEnglish } = useQuery(['/api/v2/Language/GetTranslateDataByCode/{en-US}'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGetTranslateDataByCodeCodeGet('en-US')
    return data
  }, { enabled: false })
  React.useEffect(() => {
    void refetchLanguage()
    void refetchEnglish()
  }, [])

  useEffect(() => {
    if ((englishData?.data) != null) {
      setEnglishTranslations(englishData.data)
    }
  }, [englishData])

  useEffect(() => {
    if (selectedLanguageCode.length > 0) {
      void refetchTranslations()
    }
  }, [selectedLanguageCode])

  const filteredData = useMemo(() => {
    if ((translationsData?.data) == null) return []
    if (searchTerm.length === 0) return translationsData.data

    const searchTermLower = searchTerm.toLowerCase()
    return translationsData.data.filter((translation: TranslationDto) =>
      ((translation.value?.toLowerCase().includes(searchTermLower)) ?? false) || translation.key?.toLowerCase().includes(searchTermLower)
    )
  }, [translationsData, searchTerm])

  const handleLanguageChange = useCallback((code: string) => {
    setSelectedLanguage(code)
  }, [])

  const handleEditClick = useCallback((id: GridRowId) => () => {
    setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.Edit } }))
  }, [])

  const handleSaveClick = useCallback((id: GridRowId) => () => {
    setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.View } }))
  }, [])

  const handleCancelClick = useCallback((id: GridRowId) => () => {
    setRowModesModel(prev => ({ ...prev, [id]: { mode: GridRowModes.View, ignoreModifications: true } }))
  }, [])

  const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
    try {
      const response = await new LanguageApi().apiV2LanguageUpdateTranslateDataPut(newRow)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${(response.data as any).error}`))
      } else {
        toast.success(t(KeyTranslation.Message_Update_success))
        void refetchTranslations()
      }
      return newRow
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }, [refetchTranslations])

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }, [])

  const handleRowModesModelChange = useCallback((newModel: GridRowModesModel) => {
    setRowModesModel(newModel)
  }, [])

  const columnsDummy: Array<GridColDef<TranslationDto>> = [
    { field: 'key', headerName: t(KeyTranslation.Header_LanguageKey) ?? '', editable: false, flex: 1 },
    {
      field: 'englishValue',
      headerName: t(KeyTranslation.Common_English) ?? '',
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        const englishTranslation = englishTranslations.find(translation => translation.key === params.row.key)
        return (englishTranslation != null) ? englishTranslation.value : ''
      }
    },
    { field: 'value', headerName: t(KeyTranslation.Header_TranslatedText) ?? '', flex: 1, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Tooltip title={t(KeyTranslation.Save)} key={`${id}-save`}>
              <IconButton onClick={handleSaveClick(id)}>
                <SaveOutlinedIcon />
              </IconButton>
            </Tooltip>,
            <Tooltip title={t(KeyTranslation.Common_Cancel)} key={`${id}-cancel`}>
              <IconButton onClick={handleCancelClick(id)}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          ]
        }
        return [
          <Tooltip title={t(KeyTranslation.Btn_Edit)} key={`${id}-edit`}>
            <IconButton onClick={handleEditClick(id)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        ]
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext()
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }

  return (
    <Box>
      <Typography variant='h1'>{t(KeyTranslation.Menu_TraslatedLanguages)}</Typography>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5 }} display="flex" justifyContent="start" alignItems="center" gap={4}>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Select value={selectedLanguage} onChange={(e) => { handleLanguageChange(e.target.value) }}>
              {languagesData?.data?.map((language: LanguageDto) => (
                <MenuItem key={language.id} value={language.code ?? ''}>
                  <Box display="flex" alignItems="center">
                    <img width="25px" src={language.flag ?? ''} alt="" style={{ marginRight: 5 }} />
                    {language.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size='small'
            value={searchTerm}
            onChange={(e) => { setSearchTerm(e.target.value) }}
          />
        </Box>
        <StyledDataGrid
          autoHeight
          loading={languagesLoading || languageFetching}
          rows={filteredData ?? []}
          columns={columnsDummy}
          rowSelection={false}
          editMode="row"
          disableColumnMenu={true}
          getRowId={(row) => row.key}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            pagination: CustomPagination
          }}
        />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Box>
  )
}
