import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  ProductApi,
  type ProjectDto,
  type ProductDto,
  type SystemInfoDto,
  type SelectionProduct,
  SystemApi
} from '../../../common/services'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface HlleaModalProps {
  open: boolean
  onClose: () => void
  onNextStep?: () => void
  saveUserChoice?: (choice: any) => void
  onBackStep?: () => void
  title: string
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch?: any
  fetchedData?: SelectionProduct[] | null
  userChoices?: UserChoicesProps | null
  productDatarefetch?: any
  product?: SelectionProduct[] | null
}
export default function HlleaModal ({
  title,
  onClose = () => {},
  onNextStep = () => {},
  saveUserChoice = () => {},
  onBackStep = () => {},
  project,
  systemData,
  systemDatarefetch,
  fetchedData,
  userChoices,
  productDatarefetch,
  product
}: HlleaModalProps): JSX.Element {
  const id = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [selectedProduct, setSelectedProduct] =
    React.useState<SelectionProduct | undefined>(undefined)
  const [productsState, setProductsState] = useState<{
    similarProduct: SelectionProduct | undefined
    selectedProduct: SelectionProduct | undefined
  }>({
    similarProduct: undefined,
    selectedProduct: undefined
  })
  const [selectedRadio, setSelectedRadio] = useState('hlleOneEnd')
  const handleRadioChange = (event: any): void => {
    setSelectedRadio(event.target.value)
  }
  const handleToggleFavorite = async (id: number): Promise<void> => {
    try {
      setLoading(true)
      await new ProductApi().apiV2ProductToggleFavoritePut(id)
      void productDatarefetch()
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    const productIds = userChoices?.hlleAs?.map(item => item.productId)
    if (userChoices?.hlleAs != null && userChoices?.hlleAs.length > 0) {
      const productId = userChoices?.hlleAs[0].productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systemHlleaData,
          id: productId
        })
      }
    }
    if (productIds != null) {
      const similarProduct = (fetchedData ?? product)?.find(
        (p) => p.id === productIds[0]
      )
      const selectedProduct = (fetchedData ?? product)?.find(
        (p) => p.id === productIds[1]
      )

      setProductsState({ similarProduct, selectedProduct })
    }
    if (userChoices?.selectedRadio != null) {
      setSelectedRadio(userChoices.selectedRadio)
    }
  }, [userChoices])
  React.useEffect(() => {
    const hlleAsNumber = systemData?.hlleAs?.[0]?.number
    const productIds = systemData?.hlleAs?.map(item => item.productId)
    const numberOfProductIds = (productIds != null) ? productIds.length : 0
    if (systemData?.hlleAs != null && systemData?.hlleAs.length > 0) {
      const productId = systemData?.hlleAs[0]?.productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systemHlleaData,
          id: productId
        })
      }
    }
    if (productIds != null) {
      const similarProduct = (fetchedData ?? product)?.find(
        (p) => p.id === productIds[0]
      )
      const selectedProduct = (fetchedData ?? product)?.find(
        (p) => p.id === productIds[1]
      )

      setProductsState({ similarProduct, selectedProduct })
    }
    if (hlleAsNumber === 1 && numberOfProductIds === 2) {
      setSelectedRadio('hlleOneEndDampenerAtTheOther')
    } else {
      switch (hlleAsNumber) {
        case 1:
          setSelectedRadio('hlleOneEnd')
          break
        case 2:
          setSelectedRadio('hlleBothEnds')
          break
      }
    }
  }, [systemData])
  const [systemHlleaData] = React.useState<ProductDto>({
    id: undefined
  })

  const validationSchema = Yup.object({
    id: Yup.number()
      .nullable()
      .required(t(KeyTranslation.Message_Harness_Invalid) ?? '')
      .typeError(t(KeyTranslation.Message_Harness_Invalid) ?? '')
  })

  const handleSubmit = async (values: any): Promise<void> => {
    try {
      const unitId = project?.unitId
      let number = 0

      switch (selectedRadio) {
        case 'hlleOneEnd':
          number = 1
          break
        case 'hlleBothEnds':
          number = 2
          break
        case 'hlleOneEndDampenerAtTheOther':
          number = 1
          break
        default:
          break
      }

      const hlleAsArray = [
        {
          productId:
          selectedRadio === 'hlleOneEndDampenerAtTheOther'
            ? productsState.selectedProduct?.id
            : values.id,
          number
        }
      ]

      // Only include the second object when formik.values.radioSelection === 'hlleOneEndDampenerAtTheOther'
      if (selectedRadio === 'hlleOneEndDampenerAtTheOther' && productsState.similarProduct != null) {
        hlleAsArray.push({
          productId: productsState.similarProduct?.id,
          number
        })
      }

      const updatedData = {
        ...systemData,
        hlleAs: hlleAsArray
      }

      if (id.systemId != null) {
        await new SystemApi().apiV2SystemPut(unitId, updatedData)
        onClose()
        systemDatarefetch()
      } else {
        const userChoice = {
          hlleAs: hlleAsArray,
          selectedRadio
        }
        saveUserChoice(userChoice)
        onNextStep()
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: systemHlleaData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  const handleProductSelect = (productSelected: SelectionProduct): void => {
    setSelectedProduct(productSelected)
    void formik.setFieldValue('id', productSelected.id)

    if (selectedRadio === 'hlleOneEndDampenerAtTheOther') {
      const pretensionForceValue = productSelected.productProperties?.find(property => property.name === 'pretensionforce')?.value
      const isDampener = productSelected.name?.includes('Dampener') ?? false

      const similarProduct = (fetchedData ?? product)?.find((p: SelectionProduct) =>
        p.id !== productSelected.id &&
        p.productProperties?.find(property => property.name === 'pretensionforce')?.value === pretensionForceValue &&
        (isDampener ? !((p.name?.includes('Dampener')) ?? false) : p.name?.includes('Dampener'))
      )

      setProductsState({ similarProduct, selectedProduct: productSelected })
    }
  }
  return (
    <>
      {id.systemId != null && (
        <Typography
          style={{ textAlign: 'center', marginBottom: 16 }}
          variant="h3"
        >
          {title}
        </Typography>
      )}
      <Box style={{ textAlign: 'center' }}>
        <FormControl>
          <RadioGroup row sx={{ justifyContent: 'center' }} >
           <Tooltip title={t(KeyTranslation.guidance_HLLEA_at_one_end)}
           slotProps={{
             popper: {
               modifiers: [
                 {
                   name: 'offset',
                   options: {
                     offset: [0, -14]
                   }
                 }
               ]
             }
           }}>
            <FormControlLabel
              value="hlleOneEnd"
              control={<Radio />}
              label={t(KeyTranslation.HLLEA_At_One_End_Of_The_System)}
              onChange={handleRadioChange}
              checked={selectedRadio === 'hlleOneEnd'}
            />
            </Tooltip>
            <Tooltip title={t(KeyTranslation.guidance_HLLEA_at_both_ends)}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}>
            <FormControlLabel
              value="hlleBothEnds"
              control={<Radio />}
              label={t(KeyTranslation.HLLEA_At_Both_Ends_of_the_system)}
              onChange={handleRadioChange}
              checked={selectedRadio === 'hlleBothEnds'}
            />
            </Tooltip>
            <Tooltip title={t(KeyTranslation.guidance_HLLEA_at_one_end_and_dampener)}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14]
                    }
                  }
                ]
              }
            }}>
             <FormControlLabel
              value="hlleOneEndDampenerAtTheOther"
              control={<Radio />}
              label={t(KeyTranslation.HLLEA_At_One_End_And_Dampener_At_The_Other)}
              onChange={handleRadioChange}
              checked={selectedRadio === 'hlleOneEndDampenerAtTheOther'}
            />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <h3 className='color7575'>{t(KeyTranslation.Common_Suggested_products)}</h3>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box className="content">
          {loading
            ? (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
              )
            : (
            <Box
              mt={4}
              mb={7}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '20px',
                maxHeight: '400px',
                overflowY: 'scroll'
              }}
            >
              {(fetchedData ?? product)
                ?.filter(
                  (product: SelectionProduct) => product.productTypeId === 2
                ).sort((a, b) => (a?.name ?? '').localeCompare(b.name ?? ''))
                .map((product: SelectionProduct, index: number) => (
                  <MenuItem
                    key={index}
                    value={product.id ?? 0}
                    onClick={() => {
                      handleProductSelect(product)
                    }}
                    sx={{
                      flexDirection: 'column',
                      border: (selectedRadio ===
                        'hlleOneEndDampenerAtTheOther' &&
                        ((productsState.selectedProduct?.id) ===
                          product.id ||
                          productsState.similarProduct?.id ===
                            product.id)) ||
                      (selectedRadio !==
                        'hlleOneEndDampenerAtTheOther' &&
                        selectedProduct?.id === product.id)
                        ? '2px solid #00C8E6'
                        : '1px solid #ccc',
                      width: '30%'
                    }}
                    className="MenuItemSystem"
                  >
                    <span>{product?.modelNo}</span>
                    <div>
                      <img
                        style={{
                          margin: '10px',
                          border: 'none'
                        }}
                        className="imgHarness"
                        src={product?.avatar ?? ''}
                        alt={product.name ?? ''}
                      />
                    </div>
                    <div className="bold">{product.name}</div>
                    <div>{product.overview}</div>
                    <Tooltip title="Favorite">
                      <div
                        className={`favorite-product ${
                          product.isFavorite === true ? 'selected' : ''
                        }`}
                        onClick={() => {
                          void handleToggleFavorite(product.id ?? 0)
                        }}
                      ></div>
                    </Tooltip>
                  </MenuItem>
                ))}
            </Box>
              )}
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          {id.systemId != null
            ? (
            <>
              <Button
                onClick={() => {
                  onClose()
                }}
              >
                {t(KeyTranslation.Common_Cancel)}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={loading}
              >
                {t(KeyTranslation.Save)}
              </Button>
            </>
              )
            : (
            <>
              <Button
                onClick={() => {
                  onBackStep()
                }}
              >
                {t(KeyTranslation.Common_Back)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={loading}
              >
                {t(KeyTranslation.next)}
              </Button>
            </>
              )}
        </Box>
      </form>
    </>
  )
}
