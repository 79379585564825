import React, { useCallback } from 'react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, Modal, type TablePaginationProps, TextField, Tooltip, Typography } from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector, type GridColDef } from '@mui/x-data-grid'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { BlobDocumentApi, type BlobDocumentDto } from '../../common/services'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import pdf from '../../assets/images/mc-file-pdf.svg'
import unKnown from '../../assets/images/mc-file-unknown.svg'
import sheet from '../../assets/images/mc-file-spreadsheet.svg'
// eslint-disable-next-line import/no-duplicates
import png from '../../assets/images/mc-file-image.svg'
// eslint-disable-next-line import/no-duplicates
import jpeg from '../../assets/images/mc-file-image.svg'
import {
  useQuery
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DocumentModal } from './components'
import AlertDelete from '../../common/components/AlertDelete'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import MuiPagination from '@mui/material/Pagination'

const icons: Record<string, string> = {
  pdf,
  unKnown,
  sheet,
  png,
  jpeg
}
export default function SystemDetail (): JSX.Element {
  const { t } = useTranslation()
  const [imageModalOpen, setImageModalOpen] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState('')

  const handleImageClick = (imageSrc: string): void => {
    const filename = imageSrc.split('/').pop()?.replace('_xs', '') ?? ''
    const transformedImageSrc = imageSrc.replace('esdsqa/', 'esdsprivate/').replace(/\/[^/]+$/, `/${filename}`)
    setSelectedImage(transformedImageSrc)
    setImageModalOpen(true)
  }
  const [loading, setLoading] = React.useState(false)
  const { data, isLoading, isFetching, refetch } = useQuery(['/api/v2/BlobDocument'], async () => {
    const { data } = await new BlobDocumentApi().apiV2BlobDocumentGet()
    return data
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
  }, [])
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDelete = async (id: number): Promise<void> => {
    try {
      const responese = await new BlobDocumentApi().apiV2BlobDocumentIdDelete(id)
      if (responese.status === 209) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${(responese.data as any).error}`))
      } else {
        await refetch()
        handleDialogClose()
        toast.success(t(KeyTranslation.Message_Delete_Success))
      }
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }

  const handleDownload = async (blobUrl: string): Promise<void> => {
    const filename = blobUrl.split('/').pop()?.replace('_xs', '') ?? ''
    const transformedImageSrc = blobUrl.replace('esdsqa/', 'esdsprivate/').replace(/\/[^/]+$/, `/${filename}`)
    try {
      setLoading(true)
      if ((blobUrl) != null) {
        const url = transformedImageSrc
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      handleDialogClose()
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }

  const [documentModalProps, setDocumentModalProps] = React.useState<{ visible: boolean, data?: BlobDocumentDto, isEditing: boolean }>({
    visible: false,
    data: undefined,
    isEditing: false
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm.length === 0) {
      return data.data
    }

    return data.data?.filter((document: BlobDocumentDto) => {
      const searchTermLower = searchTerm.toLowerCase()
      return ((document.unitName?.toLowerCase().includes(searchTermLower)) ?? false) ||
        ((document.name?.toLowerCase().includes(searchTermLower)) ?? false) || document.standardName?.toLowerCase().includes(searchTermLower)
    })
  }, [data, searchTerm])
  const columnsDummy: Array<GridColDef<BlobDocumentDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Header_FileName) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <a style={{ cursor: 'pointer' }} onClick={() => {
            setDocumentModalProps({
              visible: true,
              data: row,
              isEditing: true
            })
          }} className='ellipsisBaseComponent'>{row.name}</a>
        )
      }
    },
    {
      field: 'languageName',
      headerName: t(KeyTranslation.Header_LaguageName) ?? '',
      flex: 1
    },
    {
      field: 'unitName',
      headerName: t(KeyTranslation.Header_UnitName) ?? '',
      flex: 1
    },
    {
      field: 'standardName',
      headerName: t(KeyTranslation.Standard_StandardName) ?? '',
      flex: 1
    },
    {
      field: 'type',
      headerName: t(KeyTranslation.File_Type) ?? '',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const fileType = row.type?.split('/').pop()?.toLowerCase()
        const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? icons.sheet : icons[fileType ?? 'unKnown'] ?? icons.unKnown
        const type = row.type
        const isImage = ['image/jpeg', 'image/png'].includes(type ?? '')
        if (isImage) {
          return <img onClick={() => { handleImageClick(row.blobUrl ?? '') }} width={50} height={30} style={{ objectFit: 'contain' }} src={row.blobUrl ?? ''} alt={row.name ?? ''} />
        } else {
          return <img width={50} height={30} style={{ objectFit: 'contain' }} src={iconUrl} alt={row.name ?? ''} />
        }
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        return <Box display="flex" gap={3}>
        <Tooltip title={t(KeyTranslation.Btn_Download)}>
          <IconButton disabled={loading} onClick={() => { void handleDownload(row.blobUrl ?? '') }}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t(KeyTranslation.Btn_Edit)}>
          <IconButton onClick={() => {
            setDocumentModalProps({
              visible: true,
              data: row,
              isEditing: true
            })
          }}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t(KeyTranslation.Btn_Delete)}>
          <IconButton onClick={() => {
            setDialogOpen({
              open: true,
              id: row.id ?? 0
            })
          }}>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext()
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }

  return (
    <Box>
      {loading && (
        <div
          className='loading'
        >
          <CircularProgress />
        </div>
      )}
      <Box>
        <Typography variant='h1'>{t(KeyTranslation.Document_Documents)}</Typography>
        <Box sx={{ mt: 5 }} className="main">
          <Box sx={{ mb: 5 }} display="flex" justifyContent="space-between">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              placeholder={t(KeyTranslation.Common_Search) ?? ''}
              size='small'
              value={searchTerm}
              onChange={(event) => { setSearchTerm(event.target.value) }}
            />
            <Button variant='contained' onClick={() => {
              setDocumentModalProps({
                visible: true,
                data: undefined,
                isEditing: false
              })
            }} >{t(KeyTranslation.Document_Newdocument)}</Button>
          </Box>
          <StyledDataGrid
            autoHeight
            loading={isLoading || isFetching}
            rows={filteredData ?? []}
            columns={columnsDummy}
            rowSelection={false}
            disableColumnMenu={true}
            slots={{
              pagination: CustomPagination
            }}
          />
          {documentModalProps.visible &&
            <DocumentModal open={documentModalProps.visible} isEditing={documentModalProps.isEditing} document={documentModalProps.data} onClose={(): void => {
              void refetch()
              setDocumentModalProps({
                visible: false,
                data: undefined,
                isEditing: false
              })
            }} />
          }
          <Modal open={imageModalOpen} onClose={() => { setImageModalOpen(false) }}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <img src={selectedImage} alt="Selected Image" width={'100%'} height={'auto'} />
            </Box>
          </Modal>
          <AlertDelete handleDelete={() => { void handleDelete(dialogOpen.id) }} dialogOpen={dialogOpen.open} handleDialogClose={handleDialogClose} />
        </Box>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Box>
  )
}
