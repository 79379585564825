import React from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { LanguageApi, type CountryGridViewModel, type LanguageDto, RegionApi, type RegionDto, CountryApi } from '../../../common/services'
import * as Yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface countryModalProps {
  open: boolean
  onClose: () => void
}
export default function CountryModal ({ open, onClose }: countryModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const { data, refetch } = useQuery(['/api/v2/Language'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGet()
    return data
  }, { enabled: false })
  const region = useQuery(['/api/v2/Region/GetAll'], async () => {
    const { data } = await new RegionApi().apiV2RegionGetAllGet()
    return data
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
    void region.refetch()
  }, [])
  const [countryData] = React.useState<CountryGridViewModel>(
    {
      name: '',
      code: '',
      displayName: '',
      languageId: 0,
      regionId: 0
    })
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_CountryName_invalid) ?? ''),
    code: Yup.string().required(t(KeyTranslation.Message_CountryCode_invalid) ?? '')
  })

  React.useEffect(() => {
    if (data?.data != null && data.data.length > 0) {
      const defaultLanguageId = data.data[0].id ?? 0
      onChangeLanguage(defaultLanguageId)
      void formik.setFieldValue('languageId', defaultLanguageId)
    }
    if (region?.data?.data != null && region.data.data.length > 0) {
      const defaultRegionId = region.data.data[0].id ?? 0
      onChangeRegion(defaultRegionId)
      void formik.setFieldValue('regionId', defaultRegionId)
    }
  }, [region?.data?.data, data?.data])

  const onChangeLanguage = (languageId: number): void => {
    const selectedLanguage = data?.data?.find((language) => language.id === languageId)
    if (selectedLanguage?.name != null) {
      void formik.setFieldValue('languageName', selectedLanguage.name)
    }
  }
  const onChangeRegion = (regionId: number): void => {
    const selectedRegion = region.data?.data?.find((region) => region.id === regionId)
    if (selectedRegion?.id != null) {
      void formik.setFieldValue('regionId', selectedRegion.id)
    }
  }

  const handleSubmit = async (values: CountryGridViewModel): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await new CountryApi().apiV2CountryPost(values)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        onClose()
        toast.success(t(KeyTranslation.Message_CreateNew_Success))
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: countryData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Country_New_Country)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={5}>
              <TextField
                value={formik.values.name}
                onChange={(e) => {
                  void formik.setFieldValue('name', e.target.value)
                }}
                label={t(KeyTranslation.Header_CountryName)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <TextField
                value={formik.values.code}
                onChange={(e) => {
                  void formik.setFieldValue('code', e.target.value)
                }}
                label={t(KeyTranslation.Header_CountryCode)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <TextField
                value={formik.values.displayName}
                onChange={(e) => {
                  void formik.setFieldValue('displayName', e.target.value)
                }}
                label={t(KeyTranslation.Country_DisplayName)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Language)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Language)}
                  value={formik.values.languageId}
                  onChange={(event) => {
                    const selectedLanguageId = Number(event.target.value)
                    onChangeLanguage(selectedLanguageId)
                    void formik.setFieldValue('languageId', selectedLanguageId)
                  }}

                >
                  {data?.data?.map((language: LanguageDto, index: number) => (
                    <MenuItem key={index} value={language.id}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={5} mb={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Regions)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Regions)}
                  value={formik.values.regionId}
                  onChange={(event) => {
                    const selectedRegionId = Number(event.target.value)
                    onChangeRegion(selectedRegionId)
                    void formik.setFieldValue('regionId', selectedRegionId)
                  }}

                >
                  {region?.data?.data?.map((region: RegionDto, index: number) => (
                    <MenuItem key={index} value={region.id ?? 0}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" gap={4}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
