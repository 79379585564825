import React from 'react'
import { Box, Modal } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { t } from 'i18next'
import FloorModel from './modalFloor'
import { type ProjectDto, type SystemInfoDto } from '../../../common/services'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface FloorModalProps {
  open: boolean
  onClose: () => void
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch: any
  unitId: number | undefined
}

export default function ContainerFloorModal ({ open, unitId, onClose, project, systemData, systemDatarefetch }: FloorModalProps): JSX.Element {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Common_Location_metrics)}>
          {
            <FloorModel
              open={open}
              onClose={onClose}
              title={t(KeyTranslation.System_Popup_Floor)}
              project={project}
              systemData={systemData}
              systemDatarefetch={systemDatarefetch}
              unitId={unitId}
            />
          }
        </ModalContent>
      </Box>
    </Modal>
  )
}
