import React from 'react'

import { capitalize, weightDescription } from '../../../utils/text'
import { ToolbarItem, ToolbarSection } from '../../editor'
import { ToolbarRadio } from '../../editor/Toolbar/ToolbarRadio'

export const TextSettings = (): JSX.Element => {
  return (
    <React.Fragment>
      <ToolbarSection
         title="Typography"
         props={['fontSize', 'fontWeight', 'textAlign']}
         summary={({ fontSize, fontWeight, textAlign }: any) => {
           const capitalizedTextAlign = typeof textAlign === 'string' ? capitalize(textAlign) : ''
           // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
           return `${fontSize ?? ''}, ${weightDescription(fontWeight)}, ${capitalizedTextAlign}`
         }}
      >
        <ToolbarItem
          full={true}
          propKey="fontSize"
          type="slider"
          label="Font Size"
        />
        <ToolbarItem propKey="textAlign" type="radio" label="Align">
          <ToolbarRadio value="left" label="Left" />
          <ToolbarRadio value="center" label="Center" />
          <ToolbarRadio value="right" label="Right" />
        </ToolbarItem>
        <ToolbarItem propKey="fontWeight" type="radio" label="Weight">
          <ToolbarRadio value="400" label="Regular" />
          <ToolbarRadio value="500" label="Medium" />
          <ToolbarRadio value="700" label="Bold" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Margin"
        props={['margin']}
        summary={({ margin }: any) => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `${margin[0] ?? 0}px ${margin[1] ?? 0}px ${margin[2] ?? 0}px ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            margin[3] ?? 0
          }px`
        }}
      >
        <ToolbarItem propKey="margin" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="margin" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="margin" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="margin" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection
         title="Appearance"
         props={['color', 'shadow']}
         summary={({ color, shadow }: any) => {
           return (
             <div className="fletext-right">
               <p
                 style={{
                   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                   color: color != null ? `rgba(${Object.values(color)})` : undefined,
                   textShadow: `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`
                 }}
                 className="text-white text-right"
               >
                 T
               </p>
             </div>
           )
         }}
      >
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
        <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        />
      </ToolbarSection>
    </React.Fragment>
  )
}
