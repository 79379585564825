import React, { useState } from 'react'
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material'
import { Breadcrumb } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ProjectApi, SystemApi } from '../../common/services'
import { t } from 'i18next'
import { Canvas } from './components'
import KeyTranslation from '../../common/KeyTranslation.json'

export default function OverviewSystem (): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)
  const id = useParams()
  const projectId = Number(id.id)
  const systemId = Number(id.systemId)
  const { data: systemData, refetch: systemRefetch, isFetched } = useQuery(
    ['/api/v2/System/{id}', systemId],
    async () => {
      const { data } = await new SystemApi().apiV2SystemIdGet(systemId)
      setIsLoading(false)
      return data
    },
    { enabled: false }
  )
  const { data: projectData, refetch: projectRefetch, isFetched: isFetchedProject } = useQuery(
    ['/api/v2/Project/GetProjectById', projectId],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void projectRefetch()
    void systemRefetch()
  }, [])
  return (
    <Box>
      {isLoading && (
        <div
          className='loading'
        >
          <CircularProgress />
        </div>
      )}
      <Box>
        <Grid
          container
          spacing={2}
          mb={4}
          alignItems="center"
          sx={{ marginLeft: 0, marginTop: 0 }}
        >
          <Grid item xs={4}>
            <Typography variant="h1" mb={4}>
              {projectData?.data?.projectName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="caption">
                {t(KeyTranslation.Common_CustomerName)}:{' '}
              </Typography>
              <Typography component={'span'}>
                {projectData?.data?.customerName}
              </Typography>
            </Box>
            <Box>
              <Typography component={'span'} variant="caption">
                {t(KeyTranslation.Header_ProjectName)}:{' '}
              </Typography>
              <Typography component={'span'}>
                {projectData?.data?.projectName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="caption">
                {t(KeyTranslation.Common_ProjectRef)}:{' '}
              </Typography>
              <Typography component={'span'}>
                {projectData?.data?.reference}
              </Typography>
            </Box>
            <Box>
              <Typography component={'span'} variant="caption">
                {t(KeyTranslation.Common_System_name)}:{' '}
              </Typography>
              <Typography component={'span'}>{systemData?.data?.name}</Typography>
              <Box>
                <Link
                  to={`/projects/${projectId ?? 0}/system-overview/${systemId}`}
                >
                  {t(KeyTranslation.Review_Edit_System)}
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Breadcrumbs aria-label="breadcrumb">
          <Breadcrumb
            component="div"
            label={<Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>}
          />
          <Breadcrumb
            component="div"
            label={
              <Link to={`/projects/${projectId}`}>
                {t(KeyTranslation.Common_ProjectOverview)}
              </Link>
            }
          />
          <Breadcrumb component="div" label={t(KeyTranslation.System_SystemLayoutEditor)} />
        </Breadcrumbs>
        {isFetched && isFetchedProject && systemData !== undefined && systemData.data !== undefined && projectData?.data !== undefined && <Canvas
          systemData={systemData.data}
          projectData={projectData.data}
          setIsLoading={setIsLoading}
        />}
      </Box>
    </Box>
  )
}
