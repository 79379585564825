import { useEditor } from '@craftjs/core'
import { Typography } from '@mui/material'
import React from 'react'

export * from './ToolbarItem'
export * from './ToolbarSection'
export * from './ToolbarTextInput'
export * from './ToolbarDropdown'

export const Toolbar = (): JSX.Element => {
  const { active, related } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first()
    return {
      active: (Boolean(currentlySelectedNodeId)) || null,
      related: Boolean(currentlySelectedNodeId) && state.nodes[currentlySelectedNodeId].related
    }
  })

  return (
    <div className="h-full">
       {active !== null && related !== false && related.toolbar != null && React.createElement(related.toolbar)}
      {active === null && (
        <div
          className="py-2 flex flex-col items-center h-full text-center px-6"
        >
          <Typography variant='subtitle2' className="pb-1 px-6">Click on a component to start editing.</Typography>
          <Typography variant='subtitle2' className="pb-1 px-6">You could also double click on the layers below to edit their names, like in Photoshop</Typography>
        </div>
      )}
    </div>
  )
}
