import { useEditor } from '@craftjs/core'
import { Layers } from '@craftjs/layers'
import React, { useState } from 'react'
import styled from 'styled-components'

import { SidebarItem } from './SidebarItem'

// import { ReactComponent as CustomizeIcon } from '../../../../public/icons/customize.svg'
// import { ReactComponent as LayerIcon } from '../../../../public/icons/layers.svg'
import { Toolbar } from '../../Toolbar'

export const SidebarDiv = styled.div<{ enabled: boolean }>`
  width: 280px;
  opacity: ${(props) => (props.enabled ? 1 : 0)};
  background: #fff;
  margin-right: ${(props) => (props.enabled ? 0 : -280)}px;
`

export const Sidebar = (): JSX.Element => {
  const [layersVisible, setLayerVisible] = useState(true)
  const [toolbarVisible, setToolbarVisible] = useState(true)
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled
  }))

  return (
    <SidebarDiv enabled={enabled} className="sidebar transition bg-white w-2">
      <div className="flex flex-col h-full">
        <SidebarItem
          title="Customize"
          height={!layersVisible ? 'full' : '55%'}
          visible={toolbarVisible}
          onChange={(val) => { setToolbarVisible(val) }}
        >
          <Toolbar />
        </SidebarItem>
        <SidebarItem
          title="Layers"
          height={'45%'}
          visible={layersVisible}
          onChange={(val) => { setLayerVisible(val) }}
        >
          <div className="">
            <Layers expandRootOnLoad={true} />
          </div>
        </SidebarItem>
      </div>
    </SidebarDiv>
  )
}
