import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface ModalHeaderProps {
  title: string
  onClose: () => void
  children: React.ReactNode
  minHeight?: number
  width?: number
}

export default function ModalContent ({ title, minHeight, onClose, width, ...otherProps }: ModalHeaderProps): JSX.Element {
  return <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ?? 860,
    bgcolor: 'background.paper',
    py: 5,
    px: 5,
    borderRadius: 4,
    minHeight,
    boxDhadow: '0px 9px 46px 8px rgba(0,0,0, 0.12), 0px 24px 38px 3px rgba(0,0,0, 0.14), 0px 11px 15px -7px rgba(0,0,0, 0.2)'
  }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant='h3'>{title}</Typography>
      <IconButton onClick={onClose} sx={{ padding: 0 }}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
    <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto', mx: -4, px: 4 }} {...otherProps} />
  </Box>
}
