import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toBase64 } from '../../../common/helper'
import { type UploadDto } from '../../../common/services'

import pdfIcon from '../../../assets/images/mc-file-pdf.svg'
import unKnown from '../../../assets/images/mc-file-unknown.svg'
import xlsxIcon from '../../../assets/images/mc-file-spreadsheet.svg'
import KeyTranslation from '../../../common/KeyTranslation.json'

const icons: Record<string, string> = {
  pdf: pdfIcon,
  unKnown,
  excel: xlsxIcon
}
interface DropzoneProps {
  formik: any
  handleFileDocumentChange: (newFileDocument: UploadDto) => void
}

interface FilePreview {
  preview: string
  name: string
  size: number
  type: string
}
const dropzone: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  border: '2px dashed #ccc',
  borderRadius: 4,
  cursor: 'pointer'
}

const thumbsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const modal: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

export default function Dropzone ({ formik, handleFileDocumentChange }: DropzoneProps): JSX.Element {
  const { t } = useTranslation()
  const [files, setFiles] = React.useState<FilePreview[]>([])
  const thumbs = files.map((file) => {
    const type = file.name?.split('.').pop()?.toLowerCase()
    const isImage = ['png', 'jpg', 'jpeg'].includes(type ?? '')

    if (isImage) {
      return (
        // eslint-disable-next-line react/jsx-key
        <img
          width={50}
          height={50}
          style={{ objectFit: 'contain' }}
          src={file.preview ?? ''}
          alt={file.name ?? ''}
        />
      )
    } else {
      const icon = icons[type ?? 'unKnown'] ?? icons.unKnown
      return (
        // eslint-disable-next-line react/jsx-key
        <img
          width={50}
          height={50}
          style={{ objectFit: 'contain' }}
          src={icon}
          alt={file.name ?? ''}
        />
      )
    }
  })

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async (acceptedFiles) => {
      const selectedFile = acceptedFiles[0]

      const base64String = await toBase64(selectedFile)
      const base64WithoutPrefix = base64String?.substring(base64String.indexOf(',') + 1)
      const newFileDocument: UploadDto = {
        fileContent: base64WithoutPrefix,
        fileName: selectedFile.name,
        fileType: selectedFile.type
      }

      void formik.setFieldValue('name', selectedFile.name)
      void formik.setFieldValue('type', selectedFile.type)
      handleFileDocumentChange(newFileDocument)

      void toBase64(selectedFile).then((data) => {
        formik.setFieldValue('blobUrl', data)
      })

      setFiles([
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile)
        })
      ])
    }
  })

  useEffect(() => {
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files])

  return (
    <Box className="container">
      <Box style={modal}>
        <Box {...getRootProps({ style: dropzone })} className="p10">
          <input {...getInputProps()} />
          <p>{t(KeyTranslation.Message_FileContent_invalid)}</p>
        </Box>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </Box>
    </Box>
  )
}
