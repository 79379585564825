import React from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { RegionApi, type RegionDto, CommonApi, StandardApi, type StandardInfo, type CommonDto } from '../../../common/services'
import * as Yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface countryModalProps {
  open: boolean
  onClose: () => void
}
export default function RegionModal ({ open, onClose }: countryModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const { data, refetch } = useQuery(['/api/v2/Standard'], async () => {
    const { data } = await new StandardApi().apiV2StandardGet()
    return data
  }, { enabled: false })
  const common = useQuery(['/api/v2/Common'], async () => {
    const res = await new CommonApi().apiV2CommonGet()
    return res.data
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
    void common.refetch()
  }, [])
  const [countryData] = React.useState<RegionDto>({
    name: '',
    code: '',
    displayName: '',
    standardId: 0,
    unitId: 0,
    dRingHeightAbovePlatform: undefined
  })
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_RegionName_invalid) ?? ''),
    code: Yup.string().required(t(KeyTranslation.Message_RegionCode_invalid) ?? ''),
    dRingHeightAbovePlatform: Yup.number()
      .typeError(t(KeyTranslation.Validate_Dring_Height) ?? '')
      .positive(t(KeyTranslation.Validate_Dring_Height) ?? '')
      .required(t(KeyTranslation.Validate_Dring_Height) ?? '')
  })

  const onChangeStandard = (standardId: number): void => {
    const selectedStandard = data?.data?.find((standard) => standard.id === standardId)
    if (selectedStandard?.id != null) {
      void formik.setFieldValue('standardId', selectedStandard.id)
    }
  }
  const onChangeUnit = (unitId: number): void => {
    const selectedUnit = common.data?.data?.units?.find((unit) => unit.id === unitId)
    if (selectedUnit?.id != null) {
      void formik.setFieldValue('unitId', selectedUnit.id)
    }
  }
  React.useEffect(() => {
    if (common.data?.data?.standards != null && common.data?.data?.standards.length > 0) {
      const defaultStandardId = common.data?.data?.standards[0].id ?? 0
      void formik.setFieldValue('standardId', defaultStandardId)
    }
    if (common.data?.data?.units != null && common.data?.data?.units.length > 0) {
      const defaultUnitdId = common.data?.data?.units[0].id ?? 0
      void formik.setFieldValue('unitId', defaultUnitdId)
    }
  }, [common.data?.data])

  const handleSubmit = async (values: RegionDto): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await new RegionApi().apiV2RegionCreatePost(values)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        onClose()
        toast.success(t(KeyTranslation.Message_CreateNew_Success))
      }
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: countryData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.Region_Create_New_Region)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={5}>
              <TextField
                value={formik.values.name}
                onChange={(e) => {
                  void formik.setFieldValue('name', e.target.value)
                }}
                label={t(KeyTranslation.Region_RegionName)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <TextField
                value={formik.values.code}
                onChange={(e) => {
                  void formik.setFieldValue('code', e.target.value)
                }}
                label={t(KeyTranslation.Region_RegionCode)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <TextField
                value={formik.values.displayName}
                onChange={(e) => {
                  void formik.setFieldValue('displayName', e.target.value)
                }}
                label={t(KeyTranslation.Region_DisplayName)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Standard)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Standard)}
                  value={formik.values.standardId}
                  onChange={(event) => {
                    const selectedStandardId = Number(event.target.value)
                    onChangeStandard(selectedStandardId)
                    void formik.setFieldValue('standardId', selectedStandardId)
                  }}

                >
                  {data?.data?.map((standard: StandardInfo, index: number) => (
                    <MenuItem key={index} value={standard.id}>
                      {standard.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Units)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Units)}
                  value={formik.values.unitId}
                  onChange={(event) => {
                    const selectedUnitId = Number(event.target.value)
                    onChangeUnit(selectedUnitId)
                    void formik.setFieldValue('unitId', selectedUnitId)
                  }}

                >
                  {common.data?.data?.units?.map((unit: CommonDto, index: number) => (
                    <MenuItem key={index} value={unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={5} mb={5}>
            <TextField
              fullWidth
              type="number"
              label={t(KeyTranslation.Header_DRingHeight)}
              value={formik.values.dRingHeightAbovePlatform}
              onChange={(e) => {
                void formik.setFieldValue('dRingHeightAbovePlatform', e.target.value)
              }}
              inputProps={{
                min: -180,
                max: 180,
                step: 1
              }}
            />
            </Box>
            <Box display="flex" justifyContent="end" gap={4}>
              <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
              <Button variant="contained" disabled={isLoading} onClick={() => { formik.handleSubmit() }}>
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Box>
    </Modal>
  )
}
