import { Element, useEditor } from '@craftjs/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as ButtonSvg } from '../../../public/icons/toolbox/button.svg'
import { ReactComponent as SquareSvg } from '../../../public/icons/toolbox/rectangle.svg'
import { ReactComponent as TypeSvg } from '../../../public/icons/toolbox/text.svg'
import { ReactComponent as YoutubeSvg } from '../../../public/icons/toolbox/video-line.svg'
import { ButtonCustoms } from '../../selectors/Button'
import { Container } from '../../selectors/Container'
import { Text } from '../../selectors/Text'
import { Image } from '../../selectors/Image'
import { Box, Tooltip, Typography } from '@mui/material'
import { SidebarItem } from './Sidebar/SidebarItem'
import { type AppEditorProps } from '../../..'
import IconError from '../../selectors/IconError/IconError'
// import { ReactComponent as CustomizeIcon } from '../../../public/icons/customize.svg'
const ToolboxDiv = styled.div<{ enabled: boolean }>`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? 'width: 0;' : '')}
  ${(props) => (!props.enabled ? 'opacity: 0;' : '')}
`

const Item = styled.a<{ move?: string }>`
  svg {
    width: 22px;
    height: 22px;
    fill: #707070;
  }
  ${(props) =>
    ((props.move ?? '').length > 0) &&
    `
    cursor: move;
  `}
`
interface ToolboxProps {
  editorData?: AppEditorProps
}

export const Toolbox = ({ editorData }: ToolboxProps): JSX.Element => {
  const {
    enabled,
    connectors: { create }
  } = useEditor((state) => ({
    enabled: state.options.enabled
  }))
  const [layersVisible] = useState(true)
  const [toolbarVisible, setToolbarVisible] = useState(false)
  const [toolbarCustom, setToolbarCustom] = useState(false)
  return (
    <ToolboxDiv
      enabled={enabled && enabled}
      className="toolbox transition w-280 h-full flex flex-col bg-white"
    >
      <SidebarItem
        // icon={CustomizeIcon as any}
        title="Basics"
        visible={toolbarVisible}
        onChange={(val) => { setToolbarVisible(val) }}
      >
        <Box className='customs'>
          <div className='w-50 t-center p10 border'
            ref={(ref: HTMLDivElement) =>
              create(
                ref,
                <Element
                  canvas
                  is={Container}
                  background={{ r: 78, g: 78, b: 78, a: 1 }}
                  color={{ r: 0, g: 0, b: 0, a: 1 }}
                  height="300px"
                  width="300px"
                ></Element>
              )
            }
          >
            <Tooltip title="Container" placement="right">
              <Item move="true">
                <SquareSvg />
                <Typography className='breakWord'>Container</Typography>
              </Item>
            </Tooltip>
          </div>
          <div className='w-50 t-center p10 border'
            ref={(ref: HTMLDivElement) =>
              create(ref, <Text text="Text" />)
            }
          >
            <Tooltip title="Text" placement="right">
              <Item move="true">
                <TypeSvg />
                <Typography className='breakWord'>Text</Typography>
              </Item>
            </Tooltip>
          </div>
          <div className='w-50 t-center p10 border' ref={(ref: HTMLDivElement) => create(ref, <ButtonCustoms />)}>
            <Tooltip title="Button" placement="right">
              <Item move="true">
                <ButtonSvg />
                <Typography className='breakWord'>Button</Typography>
              </Item>
            </Tooltip>
          </div>
          <div className='w-50 t-center p10 border' ref={(ref: HTMLDivElement) => create(ref, <Image />)}>
            <Tooltip title="Image" placement="right">
              <Item move="true">
                <YoutubeSvg />
                <Typography className='breakWord'>Image</Typography>
              </Item>
            </Tooltip>
          </div>
          <Box className='t-center w-50 p10 border'>
            <div
              ref={(ref: any) => create(ref,
                <div className="py-3 flexReport w100 clearance-remaining-warning-message-standing" style={{
                  background: 'rgb(253, 240, 240)',
                  padding: '10px',
                  marginBottom: '6px'
                }}>
                  <div style={{ color: 'red' }}>
                    <IconError></IconError>
                  </div>
                  <span style={{ color: 'red', marginLeft: '10px' }}>
                    <Text
                      dataKey="Clearance_Remaining_Warning_Message"
                      text="Report_Clearance_Remaining_Warning_Message"
                    />
                  </span>
                </div>
              )}
            >
              <Tooltip title={'Error'} placement="right">
                <Item move="true">
                  <TypeSvg />
                  <Typography className='breakWord'>Error</Typography>
                </Item>
              </Tooltip>
            </div>
          </Box>
          <Box className='t-center w-50 p10 border'>
            <div
              ref={(ref: any) => create(ref,
                <div
                className='containerError show-warning-message-standing'>
                    <IconError></IconError>
                  <span style={{ color: 'red', marginLeft: '10px' }}>
                    <Text text={`${'Report_Standing: '}${'[=Warning]'}`} />
                  </span>
                </div>
              )}
            >
              <Tooltip title={'Error'} placement="right">
                <Item move="true">
                  <TypeSvg />
                  <Typography className='breakWord'>Warning</Typography>
                </Item>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </SidebarItem>
      {editorData !== undefined && (
        <SidebarItem
          // icon={CustomizeIcon as any}
          title="Customs"
          height={!layersVisible ? 'full' : '60%'}
          visible={toolbarCustom}
          onChange={(val) => { setToolbarCustom(val) }}
        >
          <Box className='customs'>
            {editorData?.dataTagResult.data.map((item: any) => {
              // Convert item.displayName from [=UserWeight] to User_Weight
              const formattedDisplayName = item.displayName
                .replace(/^\[=/, '') // Remove '[=' from the beginning
                .replace(/\]$/, '') // Remove ']' from the end
                .replace(/([a-z])([A-Z])/g, '$1_$2') // Insert underscore between camelCase
                .replace(/\s+/g, '_') // Replace spaces with underscores
              return (
                <div
                  key={item.id}
                  className='t-center w-50 p10 border'
                  ref={(ref: any) => create(ref,
                    <div className="py-3 flexReport w100">
                      <span>
                        <Text
                          dataKey={formattedDisplayName}
                          text={item.fieldName}
                        />
                      </span>
                      <span>:</span>
                      <span className="ml5">
                        <Text text={item.displayName} />
                      </span>
                    </div>
                  )}
                >
                  <Tooltip title={formattedDisplayName} placement="right">
                    <Item move="true">
                      <TypeSvg />
                      <Typography className='breakWord'>{item.fieldName}</Typography>
                    </Item>
                  </Tooltip>
                </div>
              )
            })}
          </Box>

        </SidebarItem>
      )}

    </ToolboxDiv>
  )
}
