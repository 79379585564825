import React from 'react'

import { ToolbarSection, ToolbarItem } from '../../editor'

export const ImageSettings = (): JSX.Element => {
  return (
    <React.Fragment>
      <ToolbarSection title="Image">
        <ToolbarItem full={true} propKey="imageUrl" type="text" label="Image URL" />
        <ToolbarItem propKey="width" type="number" label="Width" />
        <ToolbarItem propKey="height" type="number" label="Height" />
      </ToolbarSection>
    </React.Fragment>
  )
}
