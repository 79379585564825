/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { AlertError, ModalContent } from '../../../common/components'
import {
  CustomerApi,
  type ProjectDto,
  RegionApi,
  LanguageApi,
  type RegionDto,
  ProjectApi,
  type CustomerDto,
  CommonApi,
  type CommonDto,
  type StandardDto,
  type ClientDto
} from '../../../common/services'
import { useFormik } from 'formik'
import { useQuery } from '@tanstack/react-query'
import localStorage from '../../../common/localStorage'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface modalProjectProps {
  open: boolean
  isEditing: boolean
  project: ProjectDto | undefined
  onClose: () => void
  refetch?: any
}

export default function modalProject ({
  open,
  onClose,
  isEditing,
  project,
  refetch
}: modalProjectProps): JSX.Element {
  const dataLocalStorage = localStorage.getItem('LOGIN')
  const companyId = dataLocalStorage.companyId
  const [isLoading, setIsLoading] = React.useState(false)
  const filter = createFilterOptions<CustomerDto>()
  const shouldHideCustomerReference = true
  const projectReference = uuidv4()
  const [projectData, setProjectData] = React.useState<ProjectDto>({
    projectName: '',
    reference: projectReference,
    customerName: '',
    customerReference: '',
    description: '',
    regionId: 0,
    standardId: 0,
    languageId: 0,
    unitId: 0,
    companyId: 0
  })
  const [options, setOptions] = React.useState<
  CustomerDto[] | null | undefined
  >([])
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false)
  const [isUnitChanged, setIsUnitChanged] = React.useState(false)
  const handleShowConfirmPopup = (): void => { setShowConfirmPopup(true) }
  const handleCloseConfirmPopup = (): void => { setShowConfirmPopup(false) }
  const handleSubmit = async (values: ProjectDto): Promise<void> => {
    setIsLoading(true)
    try {
      const updated = { ...values }
      if (values.id != null) {
        if ((options != null) && options.length > 0 && options[0].id !== undefined) {
          updated.customerId = values.customerId
        } else {
          delete updated.customerId
        }
        const response = await new ProjectApi().apiV2ProjectPut(updated)

        if (response.status === 209) {
          toast.error(t(`${(response.data as any).error}`))
        } else {
          refetch()
          toast.success(t(KeyTranslation.Message_Update_success))
          onClose()
        }
      } else {
        const response = await new ProjectApi().apiV2ProjectPost(updated)
        if (response.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          refetch()
          toast.success(t(KeyTranslation.Message_CreateNew_Success))
          onClose()
        }
      }
    } catch (ex: any) {
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }
  const validationSchema = Yup.object({
    projectName: Yup.string().required(t(KeyTranslation.projectname_invalid) ?? ''),
    customerName: Yup.string().required(
      t(KeyTranslation.Message_CustomerName_Invalid) ?? ''
    ),
    regionId: Yup.number()
      .required(t(KeyTranslation.Message_CountryRegionId_invalid) ?? '')
      .min(1, t(KeyTranslation.Message_CountryRegionId_invalid) ?? ''),
    standardId: Yup.number()
      .required(t(KeyTranslation.Message_StandardId_invalid) ?? '')
      .min(1, t(KeyTranslation.Message_StandardId_invalid) ?? ''),
    languageId: Yup.number()
      .required(t(KeyTranslation.LanguageId_invalid) ?? '')
      .min(1, t(KeyTranslation.LanguageId_invalid) ?? ''),
    unitId: Yup.number()
      .required(t(KeyTranslation.Message_UnitId_Invalid) ?? '')
      .min(1, t(KeyTranslation.Message_UnitId_Invalid) ?? '')
  })
  const formik = useFormik({
    initialValues: projectData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  const projectId = useQuery(
    ['/api/v2/Project/GetProjectById', project?.id],
    async () => {
      if (project !== undefined) {
        const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
          project.id
        )
        return data.data
      }
    },
    { enabled: project !== undefined }
  )
  const fetchCustomerOptions = async function (customerName: string, companyId: number): Promise<void> {
    try {
      const res = await new CustomerApi().apiV2CustomerGet(customerName, companyId)
      setOptions(res.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCustomerNameChange = (customerName: string): void => {
    const newCustomerName = customerName
    if (newCustomerName != null) {
      void formik.setFieldValue('customerName', newCustomerName)
    } else {
      void formik.setFieldValue('customerName', '')
    }

    if (newCustomerName !== '' && newCustomerName != null) {
      void fetchCustomerOptions(newCustomerName, companyId)
    }
  }
  const region = useQuery(['/api/v2/Region/GetAll'], async () => {
    const res = await new RegionApi().apiV2RegionGetAllGet()
    return res.data
  })
  const language = useQuery(['/api/v2/Language'], async () => {
    const { data } = await new LanguageApi().apiV2LanguageGet()
    return data
  })
  const common = useQuery(['/api/v2/Common'], async () => {
    const res = await new CommonApi().apiV2CommonGet()
    return res.data
  })
  const clients = useQuery(['/api/v2/Project/GetClients'], async () => {
    const res = await new ProjectApi().apiV2ProjectGetClientsGet()
    return res.data
  })
  useEffect(() => {
    if (projectId.data !== undefined) {
      setProjectData(projectId.data)
    }
  }, [isEditing, projectId.data])
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #cccc',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent
          onClose={onClose}
          title={
            isEditing ? t(KeyTranslation.Popup_Edit_Project) : t(KeyTranslation.Popup_Create_New_Project)
          }
        >
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={5}>
              <TextField
                value={formik.values.projectName ?? ''}
                error={
                  (formik.touched.projectName ?? false) &&
                  Boolean(formik.errors.projectName)
                }
                onChange={(e) => {
                  void formik.setFieldValue('projectName', e.target.value)
                }}
                label={t(KeyTranslation.Common_ProjectName)}
                variant="outlined"
                fullWidth
                helperText={
                  (formik.touched.projectName ?? false) &&
                    Boolean(formik.errors.projectName)
                    ? formik.errors.projectName
                    // eslint-disable-next-line operator-linebreak
                    :
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    formik.values.projectName ?? false
                      ? ''
                      : t(KeyTranslation.ProjectNameHelp)
                }
              />
            </Box>
            <Box mt={5}>
              <TextField
                value={formik.values.reference ?? projectReference}
                error={
                  (formik.touched.reference ?? false) &&
                  Boolean(formik.errors.reference)
                }
                onChange={(e) => {
                  void formik.setFieldValue('reference', e.target.value)
                }}
                label={t(KeyTranslation.Popup_Project_Reference)}
                variant="outlined"
                fullWidth
                helperText={t(KeyTranslation.ReferenceHelp)}
              />
            </Box>
            <Box mt={5}>
              <FormControl fullWidth disabled={(clients.data as any[])?.some((client: ClientDto) => client.companyId === projectData.companyId)}>
                <InputLabel>{t(KeyTranslation.client_name)}</InputLabel>
                <Select
                  label={t(KeyTranslation.client_name)}
                  value={formik.values.companyId}
                  onChange={(event) => {
                    const selectedCompanyId = Number(event.target.value)
                    void formik.setFieldValue('companyId', selectedCompanyId)
                  }}
                >
                  {(clients.data as any[])?.map(
                    (client: ClientDto, index: number) => (
                      <MenuItem key={index} value={client.companyId}>
                        {client.companyName}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box mt={5}>
              <Autocomplete
                fullWidth
                disablePortal
                isOptionEqualToValue={(option, value) => option.name === value}
                value={{ name: formik.values.customerName }}
                options={options ?? []}
                onChange={(event: any, newValue: any) => {
                  if (newValue != null) {
                    void formik.setFieldValue(
                      'customerReference',
                      newValue.reference
                    )
                    // If the customer has an ID, set customerId in the formik state
                    if (newValue.id != null) {
                      void formik.setFieldValue('customerId', newValue.id)
                    }
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  handleCustomerNameChange(newInputValue)
                }}
                className={
                  (formik.touched.customerName ?? false) &&
                    Boolean(formik.errors.customerName)
                    ? 'error-input'
                    : ''
                }
                getOptionLabel={(option) => option?.name ?? ''}
                renderInput={(params) => (
                  <TextField {...params} label={t(KeyTranslation.Common_CustomerName)} />
                )}
                renderOption={(props, option) => (
                  <React.Fragment key={option.id}>
                    {options != null && options.length > 0
                      ? (
                        <Box component="li" {...props}>
                          <Typography>{option.name}</Typography>
                        </Box>
                        )
                      : (
                        <Box component="li" {...props}>
                          <Typography width={'50%'}>
                            {t(KeyTranslation.NoRecordsFound)}
                          </Typography>
                          <Button onClick={() => { handleCustomerNameChange(option.name) }}>{t(KeyTranslation.NewCustomer)}</Button>
                        </Box>
                        )}
                  </React.Fragment>
                )}

                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  const randomreference = uuidv4()

                  const isExisting = options.some(
                    (option) => inputValue === option
                  )
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      id: undefined,
                      name: `${inputValue}`,
                      reference: randomreference
                    })
                  }
                  return filtered
                }}
              />

              <FormHelperText
                className={
                  (formik.touched.customerName ?? false) &&
                    Boolean(formik.errors.customerName)
                    ? 'error'
                    : ''
                }
                sx={{ fontSize: '11px' }}
              >
                {(formik.touched.customerName ?? false) &&
                  Boolean(formik.errors.customerName)
                  ? formik.errors.customerName
                  : formik.values.customerName !== ''
                    ? ''
                    : t(KeyTranslation.LookUpHelp)}
              </FormHelperText>
            </Box>

            {!shouldHideCustomerReference && (
              <Box mt={5}>
                <TextField
                  value={formik.values.customerReference ?? ''}
                  label={t(KeyTranslation.Popup_Customer_Reference)}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    const value = e.target.value
                    if (value.length > 0) {
                      void formik.setFieldValue('customerReference', value)
                    } else {
                      void formik.setFieldValue('customerReference', undefined)
                    }
                  }}
                  helperText={
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    formik.values.customerReference ?? false
                      ? ''
                      : t(KeyTranslation.ReferenceHelp)
                  }
                />
              </Box>
            )}
            <Box mt={5}>
              <TextField
                value={formik.values.description ?? ''}
                onChange={(e) => {
                  void formik.setFieldValue('description', e.target.value)
                }}
                label={t(KeyTranslation.Header_Description)}
                variant="outlined"
                fullWidth
                helperText={
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  formik.values.description ?? false ? '' : t(KeyTranslation.DescriptionHelp)
                }
              />
            </Box>
            {region.data?.data != null && (
              <Box mt={5}>
                <FormControl fullWidth disabled={isEditing}>
                  <InputLabel>{t(KeyTranslation.Common_Region)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Common_Region)}
                    value={formik.values.regionId}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onChange={async (event) => {
                      const selectedRegionId = Number(event.target.value)
                      const selectedRegion = region.data?.data?.find(
                        (r) => r.id === selectedRegionId
                      )
                      if (selectedRegion != null) {
                        // Use await to ensure that the state is updated before proceeding
                        await formik.setValues({
                          ...formik.values,
                          regionId: selectedRegionId,
                          standardId: Number(selectedRegion.standardId),
                          unitId: Number(selectedRegion.unitId)
                        })
                      }
                    }}
                  >
                    {region.data?.data?.map(
                      (region: RegionDto, index: number) => (
                        <MenuItem key={index} value={region.id ?? 0}>
                          {region.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
            )}
            {language.data?.data != null && (
              <Box mt={5}>
                <FormControl fullWidth disabled={isEditing}>
                  <InputLabel>{t(KeyTranslation.Common_Language)}</InputLabel>
                  <Select
                    label={t(KeyTranslation.Common_Language)}
                    value={formik.values.languageId}
                    onChange={(event) => {
                      const selectedLanguageId = Number(event.target.value)
                      void formik.setFieldValue('languageId', selectedLanguageId)
                    }}
                  >
                    {Array.from(new Set(language.data.data.map((language) => language.name))).map((uniqueName, index) => {
                      const firstMatchingLanguage = language.data?.data?.find((language) => language.name === uniqueName)
                      if (firstMatchingLanguage != null) {
                        return (
                          <MenuItem key={index} value={firstMatchingLanguage.id ?? 0}>
                            {firstMatchingLanguage.name}
                          </MenuItem>
                        )
                      }
                      return null
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box mt={5}>
              <FormControl fullWidth disabled={isEditing}>
                <InputLabel>{t(KeyTranslation.Common_Standard)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Standard)}
                  value={formik.values.standardId}
                  onChange={(event) => {
                    const selectedStandardId = Number(event.target.value)
                    void formik.setFieldValue('standardId', selectedStandardId)
                  }}
                >
                  {common.data?.data?.standards?.map(
                    (standard: StandardDto, index: number) => (
                      <MenuItem key={index} value={standard.id}>
                        {standard.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt={5}>
              <FormControl fullWidth disabled={isEditing}>
                <InputLabel>{t(KeyTranslation.Common_Unit)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Unit)}
                  value={formik.values.unitId}
                  onChange={(event) => {
                    const selectedUnitId = Number(event.target.value)
                    void formik.setFieldValue('unitId', selectedUnitId)
                    setIsUnitChanged(true)
                  }}
                >
                  {common.data?.data?.units?.map(
                    (unit: CommonDto, index: number) => (
                      <MenuItem key={index} value={unit.id}>
                        {unit.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt={5} display="flex" justifyContent="end" gap={4}>
              <Button
                onClick={() => {
                  onClose()
                }}
              >
                {t(KeyTranslation.Common_Cancel)}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  if (isUnitChanged && isEditing) {
                    handleShowConfirmPopup()
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                    void formik.handleSubmit()
                  }
                }}
              >
                {t(KeyTranslation.Common_Save)}
              </Button>

            </Box>
            <Box>
              <Modal
                open={showConfirmPopup}
                onClose={handleCloseConfirmPopup}
              >
                <Box sx={style}>
                  <Typography variant="h6" component="h2">
                    {t(KeyTranslation.Confirmation_Title)}
                  </Typography>
                  {formik.values.unitId === 1
                    ? (
                      <Typography sx={{ mt: 2 }}>
                        {t(KeyTranslation.Confirmation_Content_Change_Unit_Imperial_To_Metric)}
                      </Typography>
                      )
                    : (
                      <Typography sx={{ mt: 2 }}>
                        {t(KeyTranslation.Confirmation_Content_Change_Unit_Metric_To_Imperial)}
                      </Typography>
                      )}
                  <Box mt={2} display="flex" justifyContent="end" gap={2}>
                    <Button
                      onClick={() => { handleCloseConfirmPopup() }}
                    >
                      {t(KeyTranslation.Common_Cancel)}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        void handleSubmit(formik.values)
                      }}
                      type='submit'
                    >
                      {t(KeyTranslation.OK)}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </form>
        </ModalContent>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Modal>
  )
}
