import React from 'react'
import { Box, Button, FormControlLabel, FormGroup, FormLabel, Radio, TextField } from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { ProjectApi } from '../../../common/services'
import * as Yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface SystemModalProps {
  open: boolean
  onClose: () => void
  onNextStep: () => void
  saveUserChoice: (choice: any) => void
  userChoices?: UserChoicesProps | null
}

export default function SystemModal ({ onClose, onNextStep, saveUserChoice, userChoices }: SystemModalProps): JSX.Element {
  const { t } = useTranslation()
  const systemReference = useQuery(['/api/v2/Project/UniqueString'], async () => {
    const { data } = await new ProjectApi().apiV2ProjectUniqueStringGet()
    return data
  }, { enabled: false })

  React.useEffect(() => {
    void systemReference.refetch()
  }, [])

  const [systemData] = React.useState({
    name: '',
    reference: '',
    systemTypeId: 1
  })
  React.useEffect(() => {
    void formik.setValues({
      ...systemData,
      name: userChoices?.name ?? ''
    })
  }, [userChoices])
  React.useEffect(() => {
    if ((systemReference.data?.data) != null) {
      const systemReferenceDefault = systemReference.data.data
      void formik.setFieldValue('reference', systemReferenceDefault)
    }
  }, [systemReference.data])
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.SystemName_invalid) ?? '')
  })
  const formik = useFormik({
    initialValues: systemData,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveUserChoice(values)
      onNextStep()
    },
    validationSchema
  })

  return (
    <><form onSubmit={formik.handleSubmit}>
      <AlertError formik={formik} />
      <Box mt={4} mb={7}>
        <TextField
          label={t(KeyTranslation.Common_System_name)}
          variant="outlined"
          fullWidth
          helperText={t(KeyTranslation.Popup_Project_EmailHelp)}
          value={formik.values.name}
          onChange={(event) => {
            void formik.setFieldValue('name', event.target.value)
          } } />
      </Box>
      <Box mt={4} mb={7}>
        <TextField
          label={t(KeyTranslation.System_Popup_System_Reference)}
          fullWidth
          helperText={t(KeyTranslation.ReferenceHelp)}
          value={formik.values.reference}
          onChange={(e) => {
            void formik.setFieldValue('reference', e.target.value)
          } } />
      </Box>
      <Box mt={4} mb={7}>
        <FormLabel>{t(KeyTranslation.System_Popup_Select_System_Type)}</FormLabel>
        <FormGroup className="containerCheckbox">
          <FormControlLabel
            control={<Radio
              checked={formik.values.systemTypeId === 1}
              value={1}
              disabled={false}
              onChange={(event) => {
                void formik.setFieldValue('systemType', event.target.value)
              } } />}
            label={t(KeyTranslation.System_Popup_Uni8)} />
          <FormControlLabel
            control={<Radio checked={false} value={2} disabled={true} />}
            label={t(KeyTranslation.System_Popup_UniRail)} />
          <FormControlLabel
            control={<Radio checked={false} value={3} disabled={true} />}
            label={t(KeyTranslation.System_Popup_Roof_Safe)} />
        </FormGroup>
      </Box>
      <Box display="flex" justifyContent="end" gap={4}>
        <Button onClick={() => { onClose() } }>{t(KeyTranslation.Common_Back)}</Button>
        <Button type="submit" variant="contained" onClick={() => { formik.handleSubmit() }}>
          {t(KeyTranslation.Common_Next)}
        </Button>
      </Box>
    </form></>
  )
}
