import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { ProductApi, type ProjectDto, type ProductDto, type SystemInfoDto, type SelectionProduct, SystemApi } from '../../../common/services'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface PpeModalProps {
  open: boolean
  onClose: () => void
  onNextStep?: () => void
  saveUserChoice?: (choice: any) => void
  onBackStep?: () => void
  title: string
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch?: any
  fetchedData?: SelectionProduct[] | null
  userChoices?: UserChoicesProps | null
  product?: any
  productDatarefetch?: any
}

export default function PpeModal ({
  title,
  onClose = () => {},
  onNextStep = () => {},
  saveUserChoice = () => {},
  onBackStep = () => {},
  project, systemData, systemDatarefetch, fetchedData, userChoices, product, productDatarefetch
}: PpeModalProps): JSX.Element {
  const id = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductDto | null>(null)
  const [selectedType, setSelectedType] = React.useState<string>('Lanyard')
  const [isAdjustableRopeGrab, setIsAdjustableRopeGrab] = React.useState(false)
  const handleToggleFavorite = async (id: number): Promise<void> => {
    try {
      setLoading(true)
      await new ProductApi().apiV2ProductToggleFavoritePut(id)
      void productDatarefetch()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    if (((userChoices?.ppEs) != null) && userChoices?.ppEs.length > 0) {
      const productId = userChoices?.ppEs[0].productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systemPpeData,
          id: productId
        })
      }
    }
    if (userChoices?.isAdjustableRopeGrab != null) {
      setIsAdjustableRopeGrab(userChoices.isAdjustableRopeGrab)
    }
  }, [userChoices])
  React.useEffect(() => {
    if (((systemData?.ppEs) != null) && systemData?.ppEs.length > 0) {
      const productId = systemData?.ppEs[0]?.productId
      if (productId != null) {
        setSelectedProduct((prevState) => ({
          ...prevState,
          id: productId
        }))
        void formik.setValues({
          ...systemPpeData,
          id: productId
        })
      }
    }
  }, [systemData])
  const [systemPpeData] = React.useState<ProductDto>({
    id: undefined
  })

  const validationSchema = Yup.object({
    id: Yup.number()
      .nullable()
      .required(t(KeyTranslation.Message_Harness_Invalid) ?? '')
      .typeError(t(KeyTranslation.Message_Harness_Invalid) ?? '')
  })
  const handleSubmit = async (values: any): Promise<void> => {
    try {
      const unitId = project?.unitId
      const updatedData = {
        ...systemData,
        isAdjustableRopeGrab,
        ppEs: [
          {
            productId: values.id
          }
        ]
      }

      if (id.systemId != null) {
        setLoading(true)
        await new SystemApi().apiV2SystemPut(unitId, updatedData)
        onClose()
        systemDatarefetch()
      } else {
        const userChoice = {
          isAdjustableRopeGrab,
          ppEs: [
            {
              productId: values.id
            }
          ]
        }
        saveUserChoice(userChoice)
        onNextStep()
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: systemPpeData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  const handleProductSelect = (product: ProductDto): void => {
    setSelectedProduct(product)
    void formik.setFieldValue('id', product.id)
  }
  const handleTypeSelect = (type: string): void => {
    setSelectedType(type)
  }
  const filteredProducts =
    selectedType === 'Lanyard'
      ? (product ?? fetchedData)?.filter((product: any) => product.isSrl === false)
      : selectedType === 'SRL'
        ? (fetchedData ?? (product ?? fetchedData))?.filter((product: any) => product.isSrl === true)
        : []

  return (
    <>
        {id.systemId != null &&
    (
      <Typography style={{ textAlign: 'center', marginBottom: 16 }} variant="h3">
        {title}
      </Typography>
    )
    }
      <h3 style={{ textAlign: 'center' }} className='color7575'>{t(KeyTranslation.Common_Suggested_products)}</h3>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box sx={{ margin: '16px' }}>
          <FormControl fullWidth size="small">
            <InputLabel>{selectedType}</InputLabel>
            <Select
              value={selectedType}
              label={selectedType}
              onChange={(e) => {
                handleTypeSelect(e.target.value)
              }}
            >
              <MenuItem value={'Lanyard'}>{t(KeyTranslation.Common_Lanyard)}</MenuItem>
              <MenuItem value={'SRL'}>{t(KeyTranslation.SRL)}</MenuItem>
            </Select>
          </FormControl>
          {selectedType !== 'SRL' && <FormGroup>
            <FormControlLabel sx={{ margin: '0px auto' }}
              control={
                <Checkbox
                  checked={isAdjustableRopeGrab}
                  onChange={(event) => {
                    const checked = event.target.checked
                    setIsAdjustableRopeGrab(checked)
                  }}
                />
              }
              label={t(KeyTranslation.Product_isAdjustRopeGrab)}
            />
          </FormGroup>}
        </Box>
        <Box>
          {loading
            ? (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
              )
            : (
              <Box
              mt={4}
              mb={7}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '20px',
                maxHeight: '400px',
                overflowY: 'scroll'
              }}
            >
              {filteredProducts
                ?.filter((product: SelectionProduct) => product.productTypeId === 1)
                .map((product: SelectionProduct, index: number) => (
                  <MenuItem
                    key={index}
                    value={product.id ?? 0}
                    onClick={() => {
                      handleProductSelect(product)
                    }}
                    sx={{
                      flexDirection: 'column',
                      border: selectedProduct?.id === product.id
                        ? '2px solid #00C8E6'
                        : '1px solid #ccc',
                      width: '30%'
                    }}
                    className="MenuItemSystem"
                  >
                    <div></div>
                    <div className="wrap">{product.modelNo}</div>
                    <div>
                      <img
                      style={{
                        margin: '10px',
                        border: 'none'
                      }}
                        className="imgHarness"
                        src={product?.avatar ?? ''}
                        alt={product.name ?? ''}
                      />
                    </div>
                    <div className="bold">{product.name}</div>
                    <div className='ellip'>{product.overview}</div>
                    <Tooltip title="Favorite">
                    <div
                      className={`favorite-product ${
                        product.isFavorite === true ? 'selected' : ''
                      }`}
                      onClick={() => {
                        void handleToggleFavorite(product.id ?? 0)
                      }}
                    ></div>
                    </Tooltip>
                  </MenuItem>
                ))}
            </Box>
              )}
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          {id.systemId != null
            ? (
            <>
              <Button
                onClick={() => {
                  onClose()
                } }
              >
                  {t(KeyTranslation.Common_Cancel)}
                </Button>
            <Button
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                } }
              >
                {t(KeyTranslation.Save)}
              </Button>
                </>
              )
            : (
            <>
              <Button
                onClick={() => {
                  onBackStep()
                } }
                disabled={loading}
              >
                  {t(KeyTranslation.Common_Back)}
                </Button>
            <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit()
                } }
                disabled={loading}
              >
                {t(KeyTranslation.next)}
              </Button>
                </>
              )}
        </Box>
      </form>
    </>
  )
}
