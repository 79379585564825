import React, { useEffect, useState } from 'react'
import { Typography, Button, Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toBase64 } from '../../../common/helper'
import { ProjectDocumentApi, type UploadDto } from '../../../common/services'
import { type toast } from 'react-toastify'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LinearProgress, {
  type LinearProgressProps
} from '@mui/material/LinearProgress'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface DropzoneProps {
  projectId: number
  ProjectDocumentRefetch: any
  toast: typeof toast
}

interface FilePreview {
  preview: string
  name: string
  size: number
  type: string
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function LinearProgressWithLabel (
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
export default function Dropzone ({
  projectId,
  ProjectDocumentRefetch,
  toast
}: DropzoneProps): JSX.Element {
  const { t } = useTranslation()
  const [files, setFiles] = useState<FilePreview[]>([])
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    const selectedFile = acceptedFiles[0]

    try {
      const base64String = await toBase64(selectedFile)
      const base64WithoutPrefix = base64String?.substring(
        base64String.indexOf(',') + 1
      )

      const newFileDocument: UploadDto = {
        fileContent: base64WithoutPrefix,
        fileName: selectedFile.name,
        fileType: selectedFile.type
      }

      // Update state to show LinearProgress
      setUploadProgress(0)

      const params = { ...newFileDocument, projectId }

      await new ProjectDocumentApi().apiV2ProjectDocumentPost(params, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          setUploadProgress(progress)
        }
      })
      setUploadProgress(0)
      setFiles([
        Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile)
        })
      ])

      void ProjectDocumentRefetch()
      toast.success(t(KeyTranslation.Message_Upload_success))
    } catch (ex: any) {
      setUploadProgress(0)
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.Error}`))
    }
  }

  useEffect(() => {
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop
  })

  return (
    <>
      <Box className="upload">
        {uploadProgress > 0 && (
        <Box sx={{ width: '50%' }}>
          <LinearProgressWithLabel value={uploadProgress} />
        </Box>
        )}
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography>{t(KeyTranslation.Common_Upload)}</Typography>
          </div>
        </Button>
      </Box>
    </>
  )
}
