import React, { useCallback } from 'react'
import { Box, Button, IconButton, InputAdornment, type TablePaginationProps, TextField, Tooltip, Typography } from '@mui/material'
import { type GridColumnVisibilityModel, GridToolbar, type GridColDef, type GridSortModel, getGridSingleSelectOperators, GridPagination } from '@mui/x-data-grid'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { ProductApi, ProductTypeApi, type ProductDto, CommonApi, RegionApi, type ProductTypeDto, type CommonDto, type RegionDto, type ProductPagingRequestDto } from '../../common/services'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  useQuery
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ProductDetail } from './components'
import AlertDelete from '../../common/components/AlertDelete'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import { type ProductInfoDto } from '../../common/services/models/product-info-dto'
import { StyledDataGrid2 } from '../../common/components'
import MuiPagination from '@mui/material/Pagination'

const initialColumnVisibility: GridColumnVisibilityModel = {
  name: true,
  productTypeName: true,
  avatar: true,
  overview: true,
  modelNo: true,
  hidding: false,
  regionNames: false,
  unitNames: false,
  standardNames: false,
  applicationLocationNames: false,
  action: true,
  systemTypeNames: false
}
export default function SystemDetail (): JSX.Element {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10
  })
  const [sortBy, setSortBy] = React.useState({
    sortField: '',
    isDescending: true
  })
  const [dataProduct, setDataProduct] = React.useState<ProductInfoDto[] | null | undefined>([])
  const [columnVisibility, setColumnVisibility] = React.useState(initialColumnVisibility)
  const [filterValues, setFilterValues] = React.useState<ProductPagingRequestDto>({})
  const [filterLength, setFilterLength] = React.useState<number>()
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map((item) => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize
      })
    }
  }
  const productType = useQuery(['/api/v2/ProductType'], async () => {
    const res = await new ProductTypeApi().apiV2ProductTypeGet()
    return res.data
  }, { enabled: false })
  const common = useQuery(['/api/v2/Common'], async () => {
    const res = await new CommonApi().apiV2CommonGet()
    return res.data
  }, { enabled: false })
  const region = useQuery(['/api/v2/Region/GetAll'], async () => {
    const res = await new RegionApi().apiV2RegionGetAllGet()
    return res.data
  }, { enabled: false })
  const allProduct = useQuery(['/api/v2/Product'], async () => {
    const { data } = await new ProductApi().apiV2ProductGet()
    return data
  }, { enabled: false })
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      '/api/v2/Product/GetProductsByPaging',
      paginationModel,
      searchTerm
    ],
    async () => {
      const params: ProductPagingRequestDto = {
        page: paginationModel.pageSize === 1 ? 1 : paginationModel.page + 1,
        pageSize: paginationModel.pageSize === 1 ? 1000 : paginationModel.pageSize,
        search: searchTerm,
        active: filterValues.active ?? 1,
        sortField: sortBy.sortField,
        isDescending: sortBy.isDescending,
        productTypeId: filterValues.productTypeId,
        standardId: filterValues.standardId,
        regionId: filterValues.regionId,
        locationId: filterValues.locationId,
        unitId: filterValues.unitId,
        systemTypeId: filterValues.systemTypeId
      }

      const { data } = await new ProductApi().apiV2ProductGetProductsByPagingPost(params)
      setDataProduct(data.data)
      return data
    },
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )

  React.useEffect(() => {
    void productType.refetch()
    void common.refetch()
    void region.refetch()
    void allProduct.refetch()
  }, [])
  React.useEffect(() => {
    setPaginationModel((prev) => ({
      ...prev,
      page: 0
    }))
  }, [paginationModel.pageSize])
  React.useEffect(() => {
    void refetch()
  }, [paginationModel.page, paginationModel.pageSize, searchTerm, sortBy, filterValues, filterLength])
  React.useEffect(() => {
    if ((filterValues.productTypeId?.[0] !== undefined ||
      filterValues.standardId?.[0] !== undefined ||
      filterValues.regionId?.[0] !== undefined ||
      filterValues.locationId?.[0] !== undefined ||
      filterValues.unitId?.[0] !== undefined ||
      filterValues.systemTypeId?.[0] !== undefined)
    ) {
      void refetch()
    }
  }, [filterValues.productTypeId, filterValues.standardId, filterValues.regionId, filterValues.locationId, filterValues.unitId, filterValues.systemTypeId])
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleAddNew = (message: string): void => {
    void allProduct.refetch()
    toast.success(t(`${message}`))
  }
  const handleDelete = async (id: number): Promise<void> => {
    try {
      const response = await new ProductApi().apiV2ProductIdDelete(id)
      if (response.status === 209) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        await refetch()
        handleDialogClose()
        handleAddNew(t(KeyTranslation.Message_Delete_Success))
      }
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }
  const [productModalProps, setProductModalProps] = React.useState<{ visible: boolean, data?: ProductDto, isEditing: boolean, isCopying: boolean }>({
    visible: false,
    data: undefined,
    isEditing: false,
    isCopying: false
  })
  const columnsDummy: Array<GridColDef<ProductInfoDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Header_ProductName) ?? '',
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <a onClick={() => {
            setProductModalProps({
              visible: true,
              data: row,
              isEditing: true,
              isCopying: false
            })
          }}>{
              <Tooltip title={row.name}>
                <Typography>{row.name}</Typography>
              </Tooltip>
            }</a>
        )
      }
    },
    {
      field: 'productTypeName',
      headerName: t(KeyTranslation.Header_ProductType) ?? '',
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      width: 200,
      valueFormatter: value => value.value,
      valueOptions: productType.data?.data
        ?.map((productType: ProductTypeDto) => ({
          label: productType.name,
          value: productType.id,
          key: productType.id
        }))
        .sort((a, b) => (a.label ?? '').localeCompare(b.label ?? '')),

      renderCell: ({ row }) => {
        if (row.productTypeName != null) {
          return row.productTypeName
        }
      }
    },
    {
      field: 'avatar',
      headerName: t(KeyTranslation.Header_Avatar) ?? '',
      filterable: false,
      width: 200,
      renderCell: ({ row }) => {
        if (row.avatar != null) {
          return <img src={row.avatar} alt={row.name ?? ''} height="50" width="60" style={{ objectFit: 'contain', padding: '5px' }} />
        }
      },
      sortable: false
    },
    {
      field: 'overview',
      headerName: t(KeyTranslation.Header_overview) ?? '',
      filterable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'modelNo',
      headerName: t(KeyTranslation.Common_ModelNo) ?? '',
      filterable: false,
      width: 100
    },
    {
      field: 'hidding',
      headerName: t(KeyTranslation.Header_Hidding) ?? '',
      flex: 1,
      type: 'boolean',
      renderCell: ({ row }) => {
        if (row.hidding != null) {
          return (row.hidding) ? t('False') : t('True')
        }
      }
    },
    {
      field: 'regionNames',
      headerName: t(KeyTranslation.Common_Regions) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: region.data?.data?.map(
        (region: RegionDto) => ({
          label: region.name,
          value: region.id,
          key: region.id
        })
      ),
      renderCell: ({ row }) => {
        if (row.regionNames != null) {
          const selectedRegion = region.data?.data?.find(option => option.name === row.regionNames)
          return <Tooltip title={row.regionNames}>
            <Typography className='ellipsisBaseComponent cursor-pointer'>{(selectedRegion != null) ? selectedRegion.name : row.regionNames}</Typography>
          </Tooltip>
        }
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'unitNames',
      headerName: t(KeyTranslation.Common_Unit) ?? '',
      flex: 1,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueFormatter: value => value.value,
      valueOptions: common.data?.data?.units?.map(
        (unit: CommonDto) => ({
          label: unit.name,
          value: unit.id,
          key: unit.id
        })
      ),
      renderCell: ({ row }) => {
        if (row.unitNames != null) {
          return row.unitNames
        }
      },
      sortable: false
    },
    {
      flex: 1,
      field: 'standardNames',
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueFormatter: value => value.value,
      headerName: t(KeyTranslation.Common_Standard) ?? '',
      type: 'singleSelect',
      valueOptions: common.data?.data?.standards?.map(
        (standard: CommonDto) => ({
          label: standard.name,
          value: standard.id,
          key: standard.id
        })
      ),
      renderCell: ({ row }) => {
        if (row.standardNames != null) {
          return row.standardNames
        }
      },
      sortable: false
    },
    {
      field: 'applicationLocationNames',
      headerName: t(KeyTranslation.Application_Location) ?? '',
      flex: 1,
      valueFormatter: value => value.value,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: common.data?.data?.applicationLocations?.map(
        (applicationLocation: CommonDto) => ({
          label: applicationLocation.name,
          value: applicationLocation.id,
          key: applicationLocation.id
        })
      ),
      renderCell: ({ row }) => {
        if (row.applicationLocationNames != null) {
          return <Tooltip title={row.applicationLocationNames}>
            <Typography className='ellipsisBaseComponent cursor-pointer'>{row.applicationLocationNames}</Typography>
          </Tooltip>
        }
      },
      sortable: false
    },
    {
      field: 'systemTypeNames',
      headerName: t(KeyTranslation.Product_SytemType) ?? '',
      flex: 1,
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueFormatter: value => value?.value,
      valueOptions: common.data?.data?.systemTypes?.map(
        (systemType: CommonDto) => ({
          label: systemType.name,
          value: systemType.id,
          key: systemType.id
        })
      ),
      renderCell: ({ row }) => {
        if (row.systemTypeNames != null) {
          return <Tooltip title={row.systemTypeNames}>
            <Typography className='ellipsisBaseComponent cursor-pointer'>{row.systemTypeNames}</Typography>
          </Tooltip>
        }
      },
      sortable: false
    },
    {
      field: 'action',
      sortable: false,
      headerName: '',
      filterable: false,
      width: 160,
      renderCell: ({ row }) => {
        return <Box display="flex" gap={3}>
          <Tooltip title={t(KeyTranslation.Btn_Edit)}>
            <IconButton onClick={() => {
              setProductModalProps({
                visible: true,
                data: row,
                isEditing: true,
                isCopying: false
              })
            }}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Common_Duplicate)}>
            <IconButton onClick={() => {
              setProductModalProps({
                visible: true,
                data: row,
                isEditing: false,
                isCopying: true
              })
            }}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Btn_Delete)}>
            <IconButton onClick={() => {
              setDialogOpen({
                open: true,
                id: row.id ?? 0
              })
            }}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    }
  ]
  const handleFilterModelChange = async (model: any): Promise<void> => {
    try {
      const updatedFilterValues: ProductPagingRequestDto = {
        page: 1,
        pageSize: paginationModel.pageSize,
        active: filterValues.active
      }
      model.items.forEach((item: any) => {
        const { field, operator, value } = item
        switch (field) {
          case 'productTypeName':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.productTypeId = [value]
              }
            }
            break
          case 'regionNames':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.regionId = [value]
              }
            }
            break
          case 'standardNames':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.standardId = [value]
              }
            }
            break
          case 'unitNames':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.unitId = [value]
              }
            }
            break
          case 'systemTypeNames':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.systemTypeId = [value]
              }
            }
            break
          case 'applicationLocationNames':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.locationId = [value]
              }
            }
            break
          case 'hidding':
            if (value === 'true') {
              updatedFilterValues.active = 1
            } else if (value === 'false') {
              updatedFilterValues.active = 0
            } else {
              updatedFilterValues.active = 2
            }
            break
          default:
            break
        }
      })
      setFilterValues(updatedFilterValues)
      setFilterLength(model.items.length)
    } catch (error) {
      console.log(error)
    }
  }
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm?.length === 0) {
      return data.data
    }

    return dataProduct?.filter((product: ProductInfoDto) => {
      const searchTermLower = searchTerm?.toLowerCase()
      return ((product.name?.toLowerCase().includes(searchTermLower ?? '')) ?? false) ||
        ((product.systemTypeNames?.toLowerCase().includes(searchTermLower ?? '')) ?? false) || product.modelNo?.toLowerCase().includes(searchTermLower ?? '')
    })
  }, [data, searchTerm])
  const handleColumnVisibilityChange = (newVisibility: GridColumnVisibilityModel): any => {
    setColumnVisibility(newVisibility)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const pageCount = Math.ceil((data?.totals ?? 0) / paginationModel.pageSize)
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={paginationModel.pageSize !== 1 ? pageCount : 1}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }
  return (
    <Box>
      <Typography variant='h1'>{t(KeyTranslation.Document_Products)}</Typography>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5 }} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size='small'
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
          <Button variant='contained' onClick={() => {
            setProductModalProps({
              visible: true,
              data: undefined,
              isEditing: false,
              isCopying: false
            })
          }} >{t(KeyTranslation.New_Product)}</Button>
        </Box>
        <StyledDataGrid2
          autoHeight
          loading={isLoading || isFetching}
          rows={filteredData ?? []}
          columns={columnsDummy}
          columnVisibilityModel={columnVisibility}
          rowSelection={false}
          disableColumnMenu={true}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rowCount={data?.totals ?? 0}
          paginationModel={paginationModel}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          slots={{ toolbar: GridToolbar, pagination: CustomPagination }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFilterModelChange={handleFilterModelChange}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          filterMode='server'
          initialState={{
            filter: {
              filterModel: {
                items: [{ field: 'hidding', operator: 'is', value: true }]
              }
            }
          }}
          onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
          getRowId={(row) => row.id}
        />
        {productModalProps.visible && productType?.data?.data != null &&
          <ProductDetail refetch={refetch} open={productModalProps.visible} handleAddNew={handleAddNew} productType={productType.data.data} regions={region.data?.data} common={common.data?.data} isEditing={productModalProps.isEditing} isCopying={productModalProps.isCopying} product={productModalProps.data} onClose={(): void => {
            setProductModalProps({
              visible: false,
              data: undefined,
              isEditing: false,
              isCopying: false
            })
          }}
          ></ProductDetail>
        }
        <AlertDelete handleDelete={() => { void handleDelete(dialogOpen.id) }} dialogOpen={dialogOpen.open} handleDialogClose={handleDialogClose} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Box>
  )
}
