import { useNode } from '@craftjs/core'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
export const ToolbarSection = ({
  title,
  props,
  summary,
  children
}: any): JSX.Element => {
  const { nodeProps } = useNode((node) => ({
    nodeProps: props?.reduce((res: any, key: any) => {
      res[key] = node.data.props[key] ?? null
      return res
    }, {})
  }))
  return (
    <>
      <List sx={{ padding: 0, overflow: 'hidden' }}>
        <ListItem disablePadding>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                  >
                    {title}
                    {Boolean(summary) && Boolean(props)
                      ? (
                      <Box>
                        {summary(
                          props.reduce((acc: any, key: any) => {
                            acc[key] = nodeProps[key]
                            return acc
                          }, {})
                        )}
                      </Box>
                        )
                      : null}
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: '0px 24px 20px', border: 'none' }}
                >
                  <Grid container spacing={1}>
                    {children}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </ListItem>
      </List>
    </>
  )
}
