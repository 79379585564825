import React from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField
} from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { type CommonDto, type SystemInfoDto, SystemApi, type ProjectDto, type CommonModel } from '../../../common/services'
import * as Yup from 'yup'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface NoteModalProps {
  open: boolean
  onClose: () => void
  project?: ProjectDto
  systemData?: SystemInfoDto
  common?: CommonModel
  systemDatarefetch: any
}
export default function InformationModal ({
  open,
  onClose,
  project,
  systemData,
  common,
  systemDatarefetch
}: NoteModalProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()
  const [projectData, setProjectData] = React.useState<SystemInfoDto>({
    name: '',
    reference: '',
    systemTypeName: '',
    applicationLocationId: 0
  })
  React.useEffect(() => {
    setProjectData(prevProjectData => ({
      ...prevProjectData,
      name: systemData?.name,
      reference: systemData?.reference,
      systemTypeName: systemData?.systemTypeName,
      applicationLocationId: systemData?.applicationLocationId
    }))
  }, [systemData])
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.SystemName_invalid) ?? '')
  })

  const handleSubmit = async (values: SystemInfoDto): Promise<void> => {
    const unitId = project?.unitId
    const updatedData = {
      ...systemData,
      name: formik.values.name,
      applicationLocationId: formik.values.applicationLocationId
    }
    try {
      setIsLoading(true)
      await new SystemApi().apiV2SystemPut(unitId, updatedData)
      onClose()
      systemDatarefetch()
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: projectData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.System_BasicInformation)}>
          <form onSubmit={formik.handleSubmit}>
            <AlertError formik={formik} />
            <Box mt={8} mb={7}>
              <TextField
                value={formik.values.name}
                onChange={(e) => {
                  void formik.setFieldValue('name', e.target.value)
                }}
                label={t(KeyTranslation.Common_Name)}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={4} mb={7}>
              <TextField
                value={formik.values.reference}
                label={t(KeyTranslation.Common_System_Ref)}
                variant="outlined"
                fullWidth
                disabled
              />
            </Box>
            <Box mt={4} mb={7}>
              <TextField
                value={formik.values.systemTypeName}
                label={t(KeyTranslation.Common_Application_type)}
                variant="outlined"
                disabled
                fullWidth
              />
            </Box>
            <Box mt={4} mb={7}>
              <FormControl fullWidth>
              <InputLabel>{t(KeyTranslation.Common_Application_location)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Application_location)}
                  value={formik.values.applicationLocationId}
                  onChange={(e) => {
                    const value = Number(e.target.value)
                    void formik.setFieldValue('applicationLocationId', value)
                  }}
                >
                  {common?.applicationLocations?.map(
                    (system: CommonDto, index: number) => (
                      <MenuItem key={index} value={system.id}>
                        {system.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" gap={4}>
              <Button
                onClick={() => {
                  onClose()
                }}
              >
                {t(KeyTranslation.Common_Cancel)}
              </Button>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                {t(KeyTranslation.Common_Save)}
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Box>
    </Modal>
  )
}
