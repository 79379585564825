import React from 'react'
import { Box, Stepper, Step, StepLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface TrackingStepsProps {
  activeStep: number
}

export default function TrackingSteps ({ activeStep }: TrackingStepsProps): JSX.Element {
  const { t } = useTranslation()
  const steps = [
    t(KeyTranslation.General),
    t(KeyTranslation.Application_Location),
    t(KeyTranslation.Location_Metrics),
    t(KeyTranslation.Choose_Harness),
    t(KeyTranslation.Choose_PPE),
    t(KeyTranslation.Choose_Rope),
    t(KeyTranslation.Choose_HLLEA),
    t(KeyTranslation.Choose_Traveller),
    t(KeyTranslation.Choose_Layout)
  ]
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{index === activeStep - 1 ? label : ''}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
