import React from 'react'
import { Box, Modal } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { t } from 'i18next'
import { type SelectionProduct, type ProjectDto, type SystemInfoDto } from '../../../common/services'
import RopeModal from './modalRope'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface RopeModalProps {
  open: boolean
  onClose: () => void
  project?: ProjectDto
  systemData?: SystemInfoDto
  systemDatarefetch?: any
  product?: SelectionProduct[] | null
}

export default function ContainerRopeModal ({ open, onClose, project, systemData, systemDatarefetch, product }: RopeModalProps): JSX.Element {
  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.System_BaseComponents)}>
          {
            <RopeModal
              open={open}
              onClose={onClose}
              title={t(KeyTranslation.Common_ChooseRope)}
              project={project}
              systemData={systemData}
              systemDatarefetch={systemDatarefetch}
              product={product}
            />
          }
        </ModalContent>
      </Box>
    </Modal>
  )
}
