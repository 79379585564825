import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select
} from '@mui/material'
import { AlertError, ModalContent } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  LanguageApi,
  ReportTemplateApi,
  type LanguageDto
} from '../../../common/services'
import { useQuery } from '@tanstack/react-query'
import { useEditor } from '@craftjs/core'
import KeyTranslation from '../../../common/KeyTranslation.json'
import { type AppEditorProps } from '..'
interface reportModalProps {
  open: boolean
  onClose: (data?: AppEditorProps) => void
}
export default function ReportModal ({
  open,
  onClose
}: reportModalProps): JSX.Element {
  const { t } = useTranslation()
  const {
    actions: { deserialize }
  } = useEditor((state) => ({
    enabled: state.options.enabled
  }))
  const [loading, setLoading] = useState(false)
  const [reportData] = React.useState({
    reportTypeId: 1,
    languageCode: ''
  })
  const { data } = useQuery(
    ['/api/v2/Language'],
    async () => {
      const { data } = await new LanguageApi().apiV2LanguageGet()
      return data
    }
  )
  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      const reportTypeId = formik.values.reportTypeId
      const languageCode = formik.values.languageCode

      // Gọi cùng lúc hai hàm API và chờ cả hai hoàn thành
      const [dataTagResult, ReportTemplate] = await Promise.all([
        new ReportTemplateApi().apiV2ReportTemplateGetDataTagGet(reportTypeId),
        new ReportTemplateApi().apiV2ReportTemplateGetByReportTypeIdGet(
          reportTypeId,
          languageCode
        )
      ])
      const newJson = JSON.parse((ReportTemplate as any).data?.data.json)
      // if (newJson !== null) {
      deserialize(newJson[0])
      // }
      onClose({
        dataTagResult: dataTagResult.data,
        templateJson: newJson,
        templateHtml: JSON.parse((ReportTemplate as any).data?.data.html),
        curentPage: 0
      })
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: reportData,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })
  const onChangeLanguage = (languageCode: string): void => {
    const selectedLanguage = data?.data?.find(
      (language) => language.code === languageCode
    )
    if (selectedLanguage?.code != null) {
      void formik.setFieldValue('languageCode', selectedLanguage.code)
    }
  }
  React.useEffect(() => {
    if (data?.data != null && data.data.length > 0) {
      const dataLocalStorage = localStorage.getItem('LOGIN')
      const parsedData = JSON.parse(dataLocalStorage ?? '')
      const defaultLanguageCode = parsedData.languageCultureCode
      onChangeLanguage(defaultLanguageCode)
      void formik.setFieldValue('languageCode', defaultLanguageCode)
    }
  }, [data?.data])
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
      }}
    >
      <ModalContent
        onClose={() => {
          onClose()
        }}
        title={t(KeyTranslation.Select_Template)}
      >
        <form onSubmit={formik.handleSubmit}>
          <AlertError formik={formik} />
          <Grid container spacing={4} mb={4} mt={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Report_Type)}</InputLabel>
                <Select
                  value={formik.values.reportTypeId}
                  label={t(KeyTranslation.Report_Type)}
                >
                  <MenuItem value={1}>{t(KeyTranslation.System)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t(KeyTranslation.Common_Language)}</InputLabel>
                <Select
                  label={t(KeyTranslation.Common_Language)}
                  value={formik.values.languageCode}
                  onChange={(event) => {
                    const selectedLanguageCode = event.target.value
                    onChangeLanguage(selectedLanguageCode)
                    void formik.setFieldValue(
                      'languageCode',
                      selectedLanguageCode
                    )
                  }}
                >
                  {data?.data?.map((language: LanguageDto, index: number) => (
                    <MenuItem key={index} value={language.code ?? ''}>
                      {language.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="end" gap={4}>
            <Button
              variant="contained"
              onClick={() => {
                formik.handleSubmit()
              }}
              disabled={loading}
            >
              {t(KeyTranslation.Btn_Edit)}
            </Button>
          </Box>
        </form>
      </ModalContent>
    </Modal>
  )
}
