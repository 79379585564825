import React from 'react'

import { ContainerSettings } from './ContainerSettings'

import { Resizer } from '../Resizer'

export interface ContainerProps {
  background: Record<'r' | 'g' | 'b' | 'a', number>
  color: Record<'r' | 'g' | 'b' | 'a', number>
  flexDirection: string
  alignItems: string
  justifyContent: string
  fillSpace: string
  width: string
  height: string
  padding: string[]
  margin: string[]
  marginTop: number
  marginLeft: number
  marginBottom: number
  marginRight: number
  shadow: number
  children: React.ReactNode
  radius: number
}

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
  width: '100%',
  height: 'auto'
}

export const Container = (props: Partial<ContainerProps>): JSX.Element => {
  props = {
    ...defaultProps,
    ...props
  }
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding = defaultProps.padding,
    margin = defaultProps.margin,
    shadow,
    radius,
    children
  } = props
  return (
    <Resizer
      propKey={{ width: 'width', height: 'height' }}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        background: `rgba(${Object.values(background ?? { r: 255, g: 255, b: 255, a: 1 })})`,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        color: `rgba(${Object.values(color ?? { r: 0, g: 0, b: 0, a: 1 })})`,
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        boxShadow:
          shadow === 0
            ? 'none'
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        borderRadius: `${radius}px`,
        flex: fillSpace === 'yes' ? 1 : 'unset'
      }}
    >
      {children}
    </Resizer>
  )
}

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true
  },
  related: {
    toolbar: ContainerSettings
  }
}
