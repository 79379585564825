import React from 'react'
import { useEditor } from '@craftjs/core'
import styled from 'styled-components'
import cx from 'classnames'
import { ReactComponent as RedoSvg } from '../../../public/icons/toolbox/redo.svg'
import { ReactComponent as UndoSvg } from '../../../public/icons/toolbox/undo.svg'
import { Button, MenuItem, Select, Tooltip } from '@mui/material'
import { type PageProps, type AppEditorProps } from '../../..'
import { t } from 'i18next'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReportTemplateApi } from '../../../../../common/services'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../../../../common/KeyTranslation.json'

const HeaderDiv = styled.div`.
  width: 100%;
  height: 45px;
  z-index: 99999;
  position: relative;
  padding: 0px 10px;
  background: #d4d4d4;
  display: flex;
`

const Item = styled.a<{ disabled?: boolean }>`
  margin-right: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #707070;
  }
  ${(props) =>
    Boolean(props.disabled ?? false) &&
    `
    opacity:0.5;
    cursor: not-allowed;
  `}
`
const styles = `
.onerem{
  width: 2rem;
  height: 2rem;
}
.m-auto{
  margin: 0px auto !important;
}
.mb15{
  margin-bottom:15px;
}
.flexScreenReport{
  display: flex;
  flex-direction: row;
}
.flexColumn{
  display: flex;
  flex-direction: column;
}
.w100{
  width: 100%;
}
.w50{
  width: 50%;
}
.center {
  align-items: center;
}
.page{
  width: 960px;
  margin: 40px 0;
}
@media (max-width: 1440px) {
  .page{
    width: 650px;
    margin: 40px 10px 10px 10px
  }
  .w-calc {
    width: calc(100% - 287px);
}
.mt15{
  margin-top:15px;
}
.ml5{
  margin-left: 5px;
}
.flexReport {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
}
.w100 {
  width: 100%;
}
.w500px {
  width: 500px;
}
.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.hidden, [hidden]{
  display: none !important;
}
img,
table {
  width: 100%;
}
table,
table td {
  border: 1px solid #ccc;
}
* {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 13px;
  line-height: 1.3;
}
[hidden] {
  display: none;
}
img {
  max-width: 100%;
  height: auto;
  min-height: 40px;
  max-height: 600px;
  object-fit: contain;
}
table {
  border-collapse: collapse;
}
table td {
  padding: 8px 5px;
}
.page {
  width: 960px;
  margin: 0 auto;
  padding: 20px;
}
.header {
  margin-bottom: 1rem;
}
.header img {
  width: auto;
  height: 62px;
}
.row:after {
  content: "";
  clear: both;
  display: block;
}
.box-left {
  float: left;
}
.box-right {
  float: right;
}
.box-2,
.box-3,
.box-4,
.box-5,
.box-6,
.box-7 {
  float: left;
}
.box,
.box-2,
.box-3,
.box-4,
.box-6 {
  float: left;
}
.section {
  margin-top: 1rem;
}
.box {
  width: 100%;
}
.box-2 {
  width: 50%;
}
.box-3 {
  width: 33.3333%;
}
.box-4 {
  width: 40%;
}
.box-6 {
  width: 60%;
}
.box-5 {
  width: 41.46666666666%;
}
.box-7 {
  width: 58.3333333%;
}
.big-title {
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: rgb(30, 31, 150);
  display: block;
  margin-top: 15px;
  padding: 5px 0;
  text-transform: uppercase;
}
.title,
.title-trans {
  display: block;
  font-size: 16px;
  padding: 5px 0;
  font-weight: 700;
}
.title {
  color: red;
  border-bottom: 1px solid red;
  text-transform: capitalize;
}
.title-trans {
  color: transparent;
  border-bottom: 1px solid transparent;
  visibility: hidden;
}
.p-5 {
  padding: 5px;
}
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-10 {
  padding: 10px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pt-15,
.py-15 {
  padding-top: 15px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-15 {
  padding: 15px;
}
.py-15 {
  padding-bottom: 15px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.m-5 {
  margin: 5px;
}
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m-10 {
  margin: 10px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mt-15,
.my-15 {
  margin-top: 15px;
}
.mb-15,
.my-15 {
  margin-bottom: 15px;
}
.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.ml-15,
.mx-15 {
  margin-left: 15px;
}
.m-15 {
  margin: 15px;
}
.mx-15 {
  margin-right: 15px;
}
.unstyle li {
  margin: 2px 0;
  width: 33.333%;
  font-size: 13px;
}
.box-img {
  width: 25%;
  float: left;
  padding: 10px;
}
.box-img img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border: 1px solid #ededed;
}
.row-img {
  margin: 0 -10px;
}
h4 {
  font-size: 15px;
  margin: 20px 0 10px;
}
ol {
  padding-left: 20px;
}
.ReportNotes {
  word-break: break-word;
}
.containerError{
  background: rgb(253, 240, 240);
  padding: 10px;
  gap: 12px;
  display: flex
}
.mr10{
  margin-right: 10px;
}
`
interface HeaderProp {
  editorData?: AppEditorProps
  onChangePage: (pageIndex: number, page: PageProps) => void
  generateHtml: () => string
  currentRef: any
}
export const Header = ({
  editorData,
  onChangePage, generateHtml, currentRef
}: HeaderProp): JSX.Element => {
  const { enabled, canUndo, canRedo, actions, query } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo()
  }))
  const [pageJson, setPageJson] = React.useState<string[]>([])
  const [pageHtml, setPageHtml] = React.useState<string[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const currentPageIndex = editorData?.curentPage ?? 0
  React.useEffect(() => {
    const json = query.serialize()
    setPageJson((prevJsonArray) => {
      const newJsonArray = [...prevJsonArray]
      newJsonArray[currentPageIndex] = json
      return newJsonArray
    })
    setPageHtml((prevHtmlArray) => {
      const newHtmlArray = [...prevHtmlArray]
      newHtmlArray[currentPageIndex] = generateHtml()
      return newHtmlArray
    })
  }, [currentPageIndex, enabled])
  const handleAddPage = (): void => {
    const newPage = {
      ROOT: {
        type: {
          resolvedName: 'Container'
        },
        isCanvas: true,
        props: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          fillSpace: 'no',
          padding: ['20', '20', '20', '20'],
          margin: window.innerWidth >= 1440 ? ['40', '40', '40', '40'] : ['40', '10', '10', '10'],
          background: {
            r: 255,
            g: 255,
            b: 255,
            a: 1
          },
          color: {
            r: 0,
            g: 0,
            b: 0,
            a: 1
          },
          shadow: 0,
          radius: 0,
          width: window.innerWidth > 1440 ? '960px' : '650px',
          height: '842px'
        },
        displayName: 'Container',
        custom: {
          displayName: 'App'
        },
        hidden: false,
        nodes: [],
        linkedNodes: {}
      }

    }
    const newPageString = JSON.stringify(newPage)
    // Thêm trang mới vào mảng pages
    const updatedPages = [...editorData?.templateJson, newPageString]
    const updatedPagesHtml = [...editorData?.templateHtml, generateHtml()]

    // Cập nhật pageJson để chứa dữ liệu trống cho trang mới
    const updatedPageJson = [...pageJson, newPageString]
    const updatedPageHtml = [...pageHtml, generateHtml()]

    // Đặt chỉ số trang hiện tại thành trang mới được thêm
    const newPageIndex = updatedPages.length - 1
    setPageJson(updatedPageJson)
    setPageHtml(updatedPageHtml)
    onChangePage(newPageIndex, {
      newJson: updatedPages,
      newHtml: updatedPagesHtml
    })
    const jsonForSelectedPage = updatedPages[newPageIndex]
    actions.deserialize(jsonForSelectedPage)
  }
  const switchToPage = (pageIndex: number): void => {
    const arrayJson = editorData?.templateJson
    const arrayHtml = editorData?.templateHtml
    const serializedJson = query.serialize()
    const jsonForSelectedPage = JSON.parse(serializedJson)
    arrayJson[currentPageIndex] = jsonForSelectedPage
    arrayHtml[currentPageIndex] = currentRef.current?.firstChild?.outerHTML ?? ''
    // Deserialize the selected page
    actions.deserialize(arrayJson[pageIndex])

    // Notify the parent component about the page change
    onChangePage(pageIndex, {
      newJson: arrayJson,
      newHtml: arrayHtml
    })
  }

  const handleDeletePage = (pageIndex: number): void => {
    const updatedPages = [...editorData?.templateJson]
    const updatedPagesHtml = [...editorData?.templateHtml]
    if (updatedPages.length === 1) {
      alert(t(KeyTranslation.Cannot_delete_the_last_page))
      return
    }

    const userConfirmed = window.confirm(t(KeyTranslation.Delete_Page_ReportTemplate) ?? '')

    if (userConfirmed) {
      // Remove the page at the given index
      updatedPages.splice(pageIndex, 1)
      updatedPagesHtml.splice(pageIndex, 1)

      // Update the state with the new array
      onChangePage(pageIndex - 1, {
        newJson: updatedPages,
        newHtml: updatedPagesHtml
      })

      // Update pageJson after deletion
      setPageJson(updatedPages)
      // setPageHtml(updatedPagesHtml)
      // Deserialize the new selected page
      const jsonForSelectedPage = updatedPages[pageIndex - 1]
      actions.deserialize(jsonForSelectedPage)
    }
  }
  const processHtml = (htmlArray: any): any => {
    return htmlArray.map((html: any) => {
      const parser = new DOMParser()
      const wrapper = parser.parseFromString(html, 'text/html')

      wrapper.querySelectorAll('*[datatype]').forEach(userItem => {
        const dataTypeValue = userItem.getAttribute('datatype')
        if (dataTypeValue !== null && dataTypeValue !== '') {
          userItem.innerHTML = `Report_${dataTypeValue}`
        }
      })

      return wrapper.body.innerHTML
    })
  }
  const processJson = (jsonArr: any[]): any => {
    return jsonArr.map((item) => {
      const newItem: any = { ...item }
      const keys = Object.keys(item)
      const values = Object.values(item)
      values.forEach((value: any, index) => {
        if ((Boolean(value)) && value.displayName === 'Text' && (Boolean(value.props)) && (Boolean(value.props.dataKey)) && value.props.dataKey !== '') {
          const dataKey: string = value.props.dataKey
          newItem[keys[index]] = {
            ...(value),
            props: {
              ...value.props,
              text: `Report_${dataKey}`
            }
          }
        }
      })
      return newItem
    })
  }
  const saveEditedHtml = async (): Promise<void> => {
    setIsLoading(true)
    const modifiedJsonArray = processJson(editorData?.templateJson)
    const modifiedHtmlArray = processHtml(editorData?.templateHtml)
    const pageJsonString = JSON.stringify(modifiedJsonArray)
    const pageHtmlString = JSON.stringify(modifiedHtmlArray)
    const reportTemplate = {
      countryId: 1,
      json: pageJsonString,
      id: 1,
      reportTypeId: 1,
      html: pageHtmlString,
      style: styles
    }

    // Assuming the ReportTemplateApi returns a Promise
    try {
      await new ReportTemplateApi().apiV2ReportTemplatePut(reportTemplate)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleEditClick = async (): Promise<void> => {
    if (enabled) {
      setIsLoading(true)
      switchToPage(currentPageIndex)
      await saveEditedHtml()
      toast.success(t(KeyTranslation.Update_ReportTemplate_Successfully))
    }
    actions.setOptions((options) => (options.enabled = !enabled))
  }
  return (
    <HeaderDiv className="header text-white transition w-full">
      <div className="items-center flex w-full px-4 justify-end">
        {enabled && (
          <div className="flex-1 flex center">
            <Tooltip title="Undo" placement="bottom">
              <Item
                disabled={!canUndo}
                onClick={() => {
                  actions.history.undo()
                }}
              >
                <UndoSvg />
              </Item>
            </Tooltip>
            <Tooltip title="Redo" placement="bottom">
              <Item
                disabled={!canRedo}
                onClick={() => {
                  actions.history.redo()
                }}
              >
                <RedoSvg />
              </Item>
            </Tooltip>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  value={editorData?.curentPage}
                  onChange={(event) => {
                    switchToPage(Number(event.target.value))
                  }}
                  size="small"
                >
                  {editorData?.templateJson.map((page: any, index: number) => (
                    <MenuItem key={index} value={index}>
                      {t(KeyTranslation.Page)} {index + 1}
                    </MenuItem>
                  ))}
                </Select>
                <Button onClick={handleAddPage}>
                  <AddIcon />
                </Button>

                <Button onClick={() => { handleDeletePage(currentPageIndex) }}>
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="flex">
          <Button
            variant="contained"
            size="small"
            className={cx([
              'transition cursor-pointer',
              {
                'bg-green-400': enabled,
                'bg-primary': !enabled
              }
            ])}
            onClick={() => { void handleEditClick() }}
            disabled={isLoading}
          >
            {enabled ? t(KeyTranslation.Common_Save) : t(KeyTranslation.Btn_Edit)}
          </Button>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ marginBottom: '50px' }}
      />
    </HeaderDiv>
  )
}
