import React from 'react'
import { Box, Stepper, Step, StepLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface TrackingStepsProps {
  activeStep: number
}

export default function TrackingStepsReport ({ activeStep }: TrackingStepsProps): JSX.Element {
  const { t } = useTranslation()
  const steps = [t(KeyTranslation.General_Info), t(KeyTranslation.Systems), t(KeyTranslation.Attachments)]
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep - 1}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
