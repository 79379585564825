import { createTheme } from '@mui/material/styles'
// A custom theme for this app
const ITEM_HEIGHT = 35
const ITEM_PADDING_TOP = 8
export const Colors = {
  primary: '#1E1E96',
  secondary: '#00C8E6',
  danger: '#e84d48',
  warning: '#f5a623',
  info: '#1aa3ff',
  success: '#417505',
  black: '#000000',
  white: '#FFFFFF',
  gray100: 'rgba(0,0,0,.04)',
  gray200: '#E0E0E0',
  gray300: '#757575',
  backdropBg: 'rgba(33,33,33,.48)'
}

const theme = createTheme({
  // transitions: {
  //   // So we have `transition: none;` everywhere
  //   create: () => 'none'
  // },
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white
    },
    secondary: {
      main: Colors.secondary
    },
    error: {
      main: Colors.danger,
      contrastText: Colors.white
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white
    },
    info: {
      main: Colors.info,

      contrastText: Colors.white
    },
    success: {
      main: Colors.success,
      contrastText: Colors.white
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: '#7b7c7e'
    },
    divider: Colors.gray200,
    background: {
      default: Colors.white
    },
    grey: {
      100: Colors.gray100,
      200: Colors.gray200
    }
  },
  spacing: 4,
  shape: {
    borderRadius: 2
  },
  typography: {
    fontSize: 13,
    fontFamily:
      '"Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    body1: {
      fontSize: 13
    },
    body2: {
      fontSize: 13,
      color: '#7b7c7e'
    },
    button: {
      fontWeight: 500
    },
    h1: {
      color: Colors.primary,
      fontSize: 24,
      fontWeight: 700
    },
    h3: {
      color: Colors.primary,
      fontSize: 20,
      fontWeight: 700
    },
    h4: {
      color: Colors.primary,
      fontSize: 16,
      fontWeight: 700
    },
    h5: {
      fontSize: 15,
      fontWeight: 600
    },
    h6: {
      fontSize: 14,
      fontWeight: 600
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 13
    },
    caption: {
      fontSize: 13,
      color: '#7b7c7e'
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          bgcolor: 'white',
          boxShadow: '0px 1px 10px rgba(50,50,50, 0.2)',
          color: 'text.primary'
        }
      }
    },
    MuiGrid: {
      defaultProps: {
        sx: {
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none'
          }
        }
      }
    },
    MuiDrawer: {
      defaultProps: {
        sx: {
          height: '100vh',
          overflowY: 'auto',
          '& .MuiPaper-root': {
            borderRight: 0,
            height: 'auto'
          },
          '& .MuiListItemIcon-root': {
            minWidth: 36
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        sx: {
          minWidth: '36px',
          fontWeight: 600,
          boxShadow: 'none',
          '&.MuiButton-sizeMedium': {
            paddingY: 2
          },
          '&.MuiButton-sizeSmall': {
            paddingY: 1.5
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        sx: {
          '&:hover': {
            backgroundColor: 'grey.200'
          }
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        sx: {
          pl: 5,
          pr: 5
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          '& .MuiButtonBase-root': {
            fontWeight: 600,
            color: 'text.primary'
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey.200'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
            borderWidth: 1
          },
          '& .MuiInputBase-inputSizeSmall': {
            py: '10px'
          },
          '& legend': {
            fontSize: '0.85rem'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          '& .MuiFormHelperText-root': {
            color: Colors.gray300,
            ml: 0,
            fontSize: 11
          }
        }
      }
    },
    MuiAccordionDetails: {
      defaultProps: {
        sx: {
          p: 3,
          border: 'none'
        }
      }
    },
    MuiAccordionSummary: {
      defaultProps: {
        sx: {
          px: 3,
          border: 'none'
        }
      }
    },
    MuiFormLabel: {
      defaultProps: {
        sx: {
          '&.Mui-focused, &.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.95)'
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          '&.MuiPopover-paper': {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150
          }
        }
      }
    }
  }
})

export default theme
