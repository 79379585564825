/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ReportTemplate } from '../models';
/**
 * ReportTemplateApi - axios parameter creator
 * @export
 */
export const ReportTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplateGetByReportTypeIdGet: async (reportTypeId?: number, languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/ReportTemplate/GetByReportTypeId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (reportTypeId !== undefined) {
                localVarQueryParameter['reportTypeId'] = reportTypeId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplateGetDataTagGet: async (reportTypeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/ReportTemplate/GetDataTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (reportTypeId !== undefined) {
                localVarQueryParameter['reportTypeId'] = reportTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportTemplate} [reportTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplatePut: async (reportTemplate?: ReportTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/ReportTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportTemplateApi - functional programming interface
 * @export
 */
export const ReportTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId?: number, languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId, languageCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ReportTemplateGetDataTagGet(reportTypeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ReportTemplateGetDataTagGet(reportTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportTemplate} [reportTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2ReportTemplatePut(reportTemplate?: ReportTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2ReportTemplatePut(reportTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportTemplateApi - factory interface
 * @export
 */
export const ReportTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId?: number, languageCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [reportTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplateGetDataTagGet(reportTypeId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2ReportTemplateGetDataTagGet(reportTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportTemplate} [reportTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2ReportTemplatePut(reportTemplate?: ReportTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2ReportTemplatePut(reportTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportTemplateApi - object-oriented interface
 * @export
 * @class ReportTemplateApi
 * @extends {BaseAPI}
 */
export class ReportTemplateApi extends BaseAPI {
    /**
     * 
     * @param {number} [reportTypeId] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplateApi
     */
    public apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId?: number, languageCode?: string, options?: AxiosRequestConfig) {
        return ReportTemplateApiFp(this.configuration).apiV2ReportTemplateGetByReportTypeIdGet(reportTypeId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [reportTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplateApi
     */
    public apiV2ReportTemplateGetDataTagGet(reportTypeId?: number, options?: AxiosRequestConfig) {
        return ReportTemplateApiFp(this.configuration).apiV2ReportTemplateGetDataTagGet(reportTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportTemplate} [reportTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportTemplateApi
     */
    public apiV2ReportTemplatePut(reportTemplate?: ReportTemplate, options?: AxiosRequestConfig) {
        return ReportTemplateApiFp(this.configuration).apiV2ReportTemplatePut(reportTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}
