import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { type SelectionProduct } from '../../../common/services'
import { type SpanData, type CustomSpanDto, type CirclePoint } from '../Helpers/CanvasProps'
import cloneDeep from 'lodash/cloneDeep'
import { t } from 'i18next'
interface ModalComponentProps {
  spanData: SpanData
  products: SelectionProduct[] | null | undefined
  onClose: () => void
  updateComponent: (span?: CustomSpanDto, index?: number, spanIndex?: number) => void
  pointSelected: number
  positionXY: { x: number, y: number }
  circles?: CirclePoint[]
}

export default function modalComponent ({ spanData, products, onClose, updateComponent, pointSelected, positionXY, circles }: ModalComponentProps): JSX.Element {
  console.log(spanData.angle)
  const isFirst = Number(spanData?.index) + Number(spanData?.spanIndex) === 0
  const isLast = Number(spanData?.index) + Number(spanData?.spanIndex) === Number(circles?.flatMap(c => c.leg?.spans).length ?? 1) - 1
  const filteredProducts = products?.filter(product => {
    if (isFirst || isLast) {
      return product.productProperties?.some(property => property.value?.toString() === '3')
    } else {
      return (Math.abs(Number(spanData?.angle)) === 180)
        ? product.productProperties?.some(property => property.value?.toString() === '2')
        : product.productProperties?.some(property => property.value?.toString() === '1')
    }
  })
  const getSelectedProductId = useCallback(() => {
    if ((spanData.span == null) || (spanData.span.components == null) || spanData.span.components.length === 0) {
      return undefined
    }
    if (spanData.index !== 1) {
      return spanData.span.components[0]?.productId ?? undefined
    }
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!spanData.span.components[1]) {
      return undefined
    }
    return spanData.span.components[1].productId
  }, [spanData.index])
  const [selectedProductId, setSelectedProductId] = useState<number | undefined>(
    getSelectedProductId()
  )
  const handleProductChange = (value: number): void => {
    if (spanData.span !== undefined) {
      const clonedSpan = cloneDeep(spanData.span)

      if (clonedSpan.components == null || clonedSpan.components === undefined || !Array.isArray(clonedSpan.components)) {
        clonedSpan.components = []
      }

      if (clonedSpan.components.length <= 0) {
        clonedSpan.components.push({
          productId: value,
          number: 1
        })
      } else {
        switch (pointSelected) {
          case 0:
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (clonedSpan.components[0]) {
              clonedSpan.components[0].productId = value
            }
            break
          case 1:
            if (clonedSpan.components.length === 1) {
              clonedSpan.components.push({
                productId: value,
                number: 1
              })
            } else {
              clonedSpan.components[1].productId = value
            }
            break
          default:
            clonedSpan.components[0].productId = value
        }
      }
      setSelectedProductId(value)
      updateComponent(clonedSpan, spanData.index, spanData.spanIndex)
    }
  }
  const selectedProduct = filteredProducts?.find((product) => Number(product.id) === Number(selectedProductId))
  return (
    <Box>
      <Box
        className='component-canvas'
        sx={{ position: 'absolute', top: (Number(spanData.y ?? 0)) + 6 + positionXY.y, left: (Number(spanData.x ?? 0)) + 6 + positionXY.x }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ width: '150px' }}>
            {selectedProduct?.avatar != null
              ? <img style={{ width: '100px' }} className='component-image' src={(selectedProduct?.avatar) ?? ''} alt={(selectedProduct?.name) ?? ''} />
              : <Box sx={{ width: '100px', height: '100px', border: '1px solid #dee2e6' }}></Box>
            }
          </Box>
          <FormControl fullWidth>
            <InputLabel>Select Component</InputLabel>
            <Select
              label={'Select Component'}
              value={selectedProduct?.id ?? ''}
              onChange={(e) => {
                e.stopPropagation()
                handleProductChange(Number(e.target.value))
              }}
            >
              {filteredProducts?.map(product => (
                <MenuItem key={product.id} value={product.id}>
                  <Tooltip
                    placement="right-start"
                    title={product.name}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14]
                            }
                          }
                        ]
                      }
                    }}
                  >
                    <Typography className='ellipsisBaseComponent'>{product.name}</Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography fontWeight={'bold'}>{selectedProduct?.name}</Typography>
        <Typography fontWeight={'bold'}>
          {((selectedProduct?.modelNo) != null)
            ? `${t('Common_Model_No')}: ${selectedProduct?.modelNo ?? ''}`
            : ''}
        </Typography>

      </Box>
      <Box className='component-canvas-backdrop' onClick={onClose} />
    </Box>
  )
}
