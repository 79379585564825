import { FormControlLabel, Radio } from '@mui/material'
import React from 'react'
export const ToolbarRadio = ({ value, label }: any): JSX.Element => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
    />
  )
}
