import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ModalContent from '../../../common/components/Modal'
import { useTranslation } from 'react-i18next'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface equalSpanProps {
  open: boolean
  onClose: (value?: number) => void
}

export default function equalSpan ({ open, onClose }: equalSpanProps): JSX.Element {
  const { t } = useTranslation()
  const [spanNumber, setSpanNumber] = useState(0)
  const [error, setError] = useState(false)
  const handleSaveAndClose = (): void => {
    if (spanNumber > 0) {
      onClose(spanNumber)
    } else {
      setError(true)
    }
  }
  return (
        <Modal
            open={open}
        >
            <Box>
                <ModalContent onClose={onClose} title={t(KeyTranslation.System_Equal_Spans)}>
                    <Box sx={{ py: 4 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {t(KeyTranslation.System_Please_input_number)}
                        </Alert>
                    )}
                    <Box>
                        <Typography mb={2}>{t(KeyTranslation.System_Spans_Number)}</Typography>
                        <TextField
                            type='number'
                            fullWidth
                            size="small"
                            // inputProps={{
                            //   min: 0
                            // }}
                            onChange={(event) => {
                              setSpanNumber(Number(event.target.value))
                              setError(false)
                            }}
                        ></TextField>
                    </Box>
                    </Box>
                    <Box display="flex" justifyContent="end" gap={4}>
                        <Button onClick={() => { onClose() }}>{t(KeyTranslation.Common_Close)}</Button>
                        <Button onClick={handleSaveAndClose} variant="contained">
                            {t(KeyTranslation.Common_Save_Close)}
                        </Button>
                    </Box>
                </ModalContent>
            </Box>
        </Modal>
  )
}
