import Konva from 'konva'
import {
  type SpanResult,
  type SpanDto,
  type CalculationResultForUserPosture,
  type CalculationResult
} from '../../../common/services'
import {
  type Point,
  type CirclePoint,
  type CustomLegDto,
  type CustomSpanDto,
  type LocationProps
} from './CanvasProps'
export class Systemlayout {
  /**
   * Tính toán góc và độ dài giữa hai vị trí (location).
   *
   * @param {LocationProps} mousePos - Vị trí của con trỏ chuột.
   * @param {LocationProps} lastCircle - Vị trí của điểm trước đó.
   * @returns {Object} Đối tượng chứa thông tin góc và độ dài: { angle: number, length: number }.
   */
  calculateAngleAndLength (
    mousePos: LocationProps,
    lastCircle: LocationProps
  ): { angle: number, length: number } {
    const mx = mousePos.x - lastCircle.x
    const my = mousePos.y - lastCircle.y
    const angle = (Math.atan2(my, mx) * 180) / Math.PI
    const length = Math.sqrt(mx * mx + my * my)
    return { angle, length }
  }

  /**
   * Trích xuất danh sách các chân (legs) từ danh sách các hình tròn.
   *
   * @param {CirclePoint[]} listCircles - Danh sách các hình tròn chứa thông tin chân (legs).
   * @returns {CustomLegDto[] | undefined} Danh sách các chân được trích xuất hoặc undefined nếu không có chân nào.
   */
  extractLegs (listCircles: CirclePoint[]): CustomLegDto[] | undefined {
    const extractedLegs = []
    for (const item of listCircles) {
      if (item.leg != null) {
        extractedLegs.push(item.leg)
      }
    }
    return extractedLegs
  }

  /**
   * Tính tổng khoảng cách rơi (fallClearance) từ các bản ghi trong đối tượng SpanResult.
   *
   * @param {SpanResult | undefined} span - Đối tượng SpanResult chứa thông tin khoảng cách rơi.
   * @returns {number | undefined} Tổng khoảng cách rơi hoặc undefined nếu không có dữ liệu hoặc lỗi.
   */
  getTotalFallDistance (span: SpanResult | undefined): number | undefined {
    let subvariable
    if (span?.records != null) {
      for (let i = 0; i < span?.records.length; i++) {
        subvariable = parseFloat(String(span.records[i].fallClearance))
      }
    }
    return subvariable
  }

  /**
 * Lấy dữ liệu tính toán dựa trên vị trí người dùng chọn (sitting, layingDown, standing).
 *
 * @param {string} position - Vị trí người dùng chọn: 'sitting', 'layingDown', hoặc 'standing'.
 * @param {CalculationResultForUserPosture | undefined} systemCalculateResult - Đối tượng chứa kết quả tính toán cho các vị trí khác nhau.
 * @returns {CalculationResult | undefined} Dữ liệu tính toán cho vị trí người dùng chọn hoặc undefined nếu không có dữ liệu.
 */
  getDataSelectedFromPos (
    string: string,
    systemCalculateResult: CalculationResultForUserPosture | undefined
  ): CalculationResult | undefined {
    switch (string) {
      case 'sitting':
        return systemCalculateResult?.sitting
      case 'layingDown':
        return systemCalculateResult?.layingDown
      case 'standing':
        return systemCalculateResult?.standing
    }
  }

  /**
 * Lấy dữ liệu về kết quả tính toán của một đoạn (span) dựa trên vị trí người dùng chọn (sitting, layingDown, standing).
 *
 * @param {string} position - Vị trí người dùng chọn: 'sitting', 'layingDown', hoặc 'standing'.
 * @param {number} index - Chỉ số của đoạn (span) trong danh sách kết quả tính toán.
 * @param {CalculationResultForUserPosture | undefined} systemCalculateResult - Đối tượng chứa kết quả tính toán cho các vị trí khác nhau.
 * @returns {SpanResult | undefined} Dữ liệu về kết quả tính toán của đoạn (span) hoặc undefined nếu không có dữ liệu.
 */
  getSpanDataSelectedFromSpan (
    string: string,
    index: number,
    systemCalculateResult: CalculationResultForUserPosture | undefined
  ): SpanResult | undefined {
    switch (string) {
      case 'sitting':
        return systemCalculateResult?.sitting?.spanResults != null
          ? systemCalculateResult?.sitting?.spanResults[index]
          : {}
      case 'layingDown':
        return systemCalculateResult?.layingDown?.spanResults != null
          ? systemCalculateResult?.layingDown?.spanResults[index]
          : {}
      case 'standing':
        return systemCalculateResult?.standing?.spanResults != null
          ? systemCalculateResult?.standing?.spanResults[index]
          : {}
    }
  }

  /**
 * Tính toán tọa độ (coordinate) X và Y dựa trên tọa độ ban đầu, độ dài và góc.
 *
 * @param {number} startX - Tọa độ X ban đầu.
 * @param {number} startY - Tọa độ Y ban đầu.
 * @param {number} length - Độ dài vector.
 * @param {number} angle - Góc trong đơn vị độ.
 * @returns {Point} Đối tượng chứa tọa độ X và Y sau khi tính toán.
 */
  getCoordinateXY (
    startX: number,
    startY: number,
    length: number,
    angle: number
  ): Point {
    angle *= Math.PI / 180
    const xi = startX + length * Math.cos(angle)
    const yi = startY + length * Math.sin(angle)
    const coordinateX = Math.round(xi * 100) / 100
    const coordinateY = Math.round(yi * 100) / 100

    return {
      coordinateX,
      coordinateY
    }
  }

  /**
 * Tính tổng độ dài từ một danh sách các đối tượng SpanDto.
 *
 * @param {SpanDto[]} spans - Danh sách các đối tượng SpanDto chứa thông tin độ dài.
 * @returns {number} Tổng độ dài tính được (làm tròn đến 2 chữ số thập phân).
 */
  calculateTotalLength (spans: SpanDto[]): number {
    let totalLength = 0
    for (const span of spans) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      totalLength += span?.length ?? 0
    }

    return Number(totalLength.toFixed(2))
  }

  // get list span
  getAllSpansFromCircles (listCircles: CirclePoint[]): CustomSpanDto[] {
    const allSpans: CustomSpanDto[] = []
    listCircles.forEach((circle, index) => {
      if (index === 0) {
        const span = {
          x: circle.x,
          y: circle.y,
          name: `Sp${index + 1}`
        }
        allSpans.push(span)
      }
      if (circle.leg?.spans != null && circle.leg.spans.length > 0) {
        const spans = circle.leg.spans
        allSpans.push(...spans)
      }
    })
    const updatedSpans = allSpans.map((span, index) => ({
      ...span,
      name: `Sp${index + 1}`
    }))
    return updatedSpans
  }

  /**
 * Tính góc tương đối giữa hai góc của chân (leg angles).
 *
 * @param {number} legAngle1 - Góc của chân thứ nhất (đơn vị độ).
 * @param {number} legAngle2 - Góc của chân thứ hai (đơn vị độ).
 * @returns {number} Góc tương đối giữa hai góc chân (đơn vị độ).
 */
  getLegsRelativeAngle (legAngle1: number, legAngle2: number): number {
    let result = legAngle1 - legAngle2
    if (result > 360) result = result - 360
    if (result < -360) result = result + 360
    if (result !== 0 && result !== 180 && result !== -180) {
      result = result < 0 ? -180 - result : 180 - result
    }
    if (result === 0) result = 180
    else if (result === 180) result = 0
    return result
  }

  /**
 * Tính tỷ lệ giữa chiều rộng của bản đồ và tổng chiều rộng thực của bản đồ trên màn hình.
 *
 * @param {number} width - Chiều rộng của bản đồ (đơn vị tùy chọn).
 * @param {number} unitId - Đơn vị đo lường (unitId) của bản đồ (2 hoặc khác).
 * @param {number} realTotalCanvasWidth - Tổng chiều rộng thực của bản đồ trên màn hình.
 * @returns {number} Tỷ lệ giữa chiều rộng của bản đồ và tổng chiều rộng thực của bản đồ trên màn hình.
 */
  getMapGroundRatio (
    width: number,
    unitId: number | undefined,
    realTotalCanvasWidth: number
  ): number {
    return unitId === 2
      ? parseFloat(width.toString()) / (realTotalCanvasWidth * 3779.527559055)
      : parseFloat(width.toString()) / (realTotalCanvasWidth * 1151.9999999832)
  }

  /**
 * Chuyển đổi độ dài từ đơn vị mét thực sang đơn vị pixel trên bản đồ.
 *
 * @param {number} length - Độ dài cần chuyển đổi (đơn vị mét thực).
 * @param {number} unitId - Đơn vị đo lường (unitId) của bản đồ (1 hoặc 2).
 * @param {number} mapGroundRatio - Tỷ lệ giữa chiều rộng của bản đồ và tổng chiều rộng thực của bản đồ trên màn hình.
 * @returns {number} Độ dài chuyển đổi thành pixel.
 */
  ConvertFromRealMeterToCanvasPixels (
    length: number,
    unitId: number | undefined,
    mapGroundRatio: number
  ): number {
    let lengthToPixel = 1151.9999999832
    if (unitId === 2) lengthToPixel = 3779.527559055
    // return parseFloat(length.toString()) * lengthToPixel
    return (
      parseFloat(length.toString()) *
      parseFloat(mapGroundRatio.toString()) *
      lengthToPixel
    )
  }

  /**
 * Chuyển đổi độ dài từ đơn vị pixel trên bản đồ sang đơn vị mét thực.
 *
 * @param {number} length - Độ dài cần chuyển đổi (đơn vị pixel trên bản đồ).
 * @param {number} unitId - Đơn vị đo lường (unitId) của bản đồ (1 hoặc 2).
 * @param {number} mapGroundRatio - Tỷ lệ giữa chiều rộng của bản đồ và tổng chiều rộng thực của bản đồ trên màn hình.
 * @returns {number} Độ dài chuyển đổi thành mét thực.
 */
  ConvertFromCanvasPixelsToRealMeter (
    length: number,
    unitId: number = 1,
    mapGroundRatio: number
  ): number {
    let pixelToLength = 1 / 1151.9999999832
    if (unitId === 2) pixelToLength = 1 / 3779.527559055
    return (
      (parseFloat(length.toString()) * pixelToLength) /
      parseFloat(mapGroundRatio.toString())
    )
  }

  /**
 * Tính toán tọa độ (x, y) của một điểm dựa trên tọa độ ban đầu, độ dài và góc.
 *
 * @param {number} lastX - Tọa độ X ban đầu.
 * @param {number} lastY - Tọa độ Y ban đầu.
 * @param {number | undefined} length - Độ dài của đoạn đường (undefined nếu không có).
 * @param {number | undefined} angle - Góc trong đơn vị độ (undefined nếu không có).
 * @returns {Object} Đối tượng chứa tọa độ (x, y) của điểm mới.
 */
  lineToAngle (
    lastX: number,
    lastY: number,
    length: number | undefined,
    angle: number | undefined
  ): { x: number, y: number } {
    const newAngle = (angle !== undefined ? angle : 0) * (Math.PI / 180)
    const x = lastX + (length !== undefined ? length : 0) * Math.cos(newAngle)
    const y = lastY + (length !== undefined ? length : 0) * Math.sin(newAngle)
    return {
      x,
      y
    }
  }

  handleApiCallBack (message: string | null, type: string): string {
    if (message != null) {
      if (type === 'single') {
        switch (message) {
          case 'The_PPE_Connecting_Device_selected_is_not_long_enough_':
            return 'The_PPE_Connecting_Device_selected_is_not_long_enough_'
          case 'The_HLL_System_Tension_is_exceeding_':
            return 'The_HLL_System_Tension_is_exceeding_'
          case 'The_system_as_configured_does_not_protect_the_worker_':
            return 'The_system_as_configured_does_not_protect_the_worker_'
          case 'OtherLegUnsafe':
            alert(
              'Can not implement Max-span for this leg, Click re-calculate system to find out which leg is unsafe.'
            )
            break
          case 'Message_NoCalculationForNoLimit':
            return 'Message_NoCalculationForNoLimit'
          case 'Project_Unit_Has_Been_Changed':
            return 'Project_Unit_Has_Been_Changed'
          case 'LocationMetricsTooSmall':
            return 'LocationMetricsTooSmall'
          default:
            return 'SystemCalculationUnSuccessful'
        }
      } else {
        switch (message) {
          case 'The_PPE_Connecting_Device_selected_is_not_long_enough_':
            return 'The_PPE_Connecting_Device_selected_is_not_long_enough_'
          case 'The_HLL_System_Tension_is_exceeding_':
            return 'The_HLL_System_Tension_is_exceeding_'
          case 'The_system_as_configured_does_not_protect_the_worker_':
            return 'The_system_as_configured_does_not_protect_the_worker_'
          case 'Message_NoCalculationForNoLimit':
            return 'Message_NoCalculationForNoLimit'
          case 'Project_Unit_Has_Been_Changed':
            return 'Project_Unit_Has_Been_Changed'
          case 'LocationMetricsTooSmall':
            return 'LocationMetricsTooSmall'
          default:
            return 'SystemCalculationUnSuccessful'
        }
      }
    }
    return ''
  }

  handleRecalculateMessage (er: string): string {
    switch (er) {
      case 'The_PPE_Connecting_Device_selected_is_not_long_enough_':
        return 'The_PPE_Connecting_Device_selected_is_not_long_enough_'
      case 'The_HLL_System_Tension_is_exceeding_':
        return 'The_HLL_System_Tension_is_exceeding_'
      case 'The_system_as_configured_does_not_protect_the_worker_':
        return 'The_system_as_configured_does_not_protect_the_worker_'
      case 'Message_NoCalculationForNoLimit':
        return 'Message_NoCalculationForNoLimit'
      case 'Project_Unit_Has_Been_Changed':
        return 'Project_Unit_Has_Been_Changed'
      default:
        return 'SystemCalculationUnSuccessful'
    }
  }

  handleStandingMessage (er: string): string {
    switch (er) {
      case 'Maximum_45m_(150ft)':
        return 'Maximum_45m_(150ft)'
      case 'Maximum_15m_(50ft)':
        return 'Maximum_15m_(50ft)'
      case 'Maximum_30m_(100ft)':
        return 'Maximum_30m_(100ft)'
      case 'Maximum_60m_(200ft)':
        return 'Maximum_60m_(200ft)'
      case 'SpanLengthIsGreaterThanLmax':
      case 'LegsAndSpansAreRequired':
      case 'InvalidProjectId':
      case 'HlleaRopeAndPPEAreRequired':
      case 'NotFoundRopeProperties_PleaseSetPropertiesDataForThisRope':
      case 'NotFoundHEAProperties_PleaseSetPropertiesDataForThisHEA':
      case 'NotFoundPPEProperties_PleaseSetPropertiesDataForThisPPE':
      case 'InvalidApplicationLocationMetrics_PleaseSetPropertyApplicationMetrics':
      case 'StandardPropertiesAreNotEnough_PleaseSetPropertiesDataForThisStandard':
        return er
      default:
        return 'SystemCalculationUnSuccessful'
    }
  }

  handleSaveSystemMessage (er: string): string {
    switch (er) {
      case 'ProjectUnitHasBeenChanged':
        return 'Project_Unit_Has_Been_Changed'
      default:
        return 'System_UpdateFail'
    }
  }

  /**
 * Chuyển đổi góc hiển thị của chân (legAngle) thành góc trên bản đồ (canvasAngle).
 *
 * @param {number} legAngle - Góc hiển thị của chân (đơn vị độ).
 * @param {number} lastLegAngle - Góc hiển thị của chân trước đó (đơn vị độ).
 * @param {number} index - Chỉ số của chân trong danh sách.
 * @returns {number | undefined} Góc trên bản đồ (canvasAngle) hoặc undefined nếu không có dữ liệu.
 */
  convertDisplayAngleToCanvasAngle (
    legAngle: number,
    lastLegAngle: number,
    index: number
  ): number | undefined {
    let angleCal
    if (index === 0) {
      angleCal = -1 * legAngle
    } else {
      if (legAngle === 0) {
        angleCal = 180
      } else if (legAngle === 180 || legAngle === -180) {
        angleCal = 0
      } else if (legAngle > 360) {
        angleCal = legAngle - 360
      } else if (legAngle < -360) {
        angleCal = legAngle + 360
      } else if (legAngle !== 0 && legAngle !== 180 && legAngle !== -180) {
        angleCal = legAngle < 0 ? -180 - legAngle : 180 - legAngle
      }
      angleCal = lastLegAngle - (angleCal ?? 0)
    }
    return angleCal
  }

  /**
 * Xoay một điểm trong hệ tọa độ 2D quanh gốc tọa độ (0, 0) bằng một góc quay radian.
 *
 * @param {object} point - Đối tượng chứa tọa độ của điểm cần xoay.
 * @param {number} point.x - Tọa độ x của điểm.
 * @param {number} point.y - Tọa độ y của điểm.
 * @param {number} radian - Góc quay theo radian.
 * @returns {object} Đối tượng mới chứa tọa độ của điểm sau khi xoay.
 */
  rotatePoint ({ x, y }: { x: number, y: number }, rad: number): { x: number, y: number } {
    const rcos = Math.cos(rad)
    const rsin = Math.sin(rad)
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
  }

  /**
 * Xoay một đối tượng Konva.Image quanh trung tâm của nó.
 *
 * @param {Konva.Image} node - Đối tượng Konva.Image cần được xoay.
 * @param {number} rotation - Góc quay (đơn vị độ) mới mà bạn muốn áp dụng cho đối tượng.
 */
  rotateAroundCenter (node: Konva.Image, rotation: number): void {
    const topLeft = { x: -node.width() / 2, y: -node.height() / 2 }
    const current = systemLayoutClass.rotatePoint(topLeft, (Konva as any).getAngle(node.rotation()))
    const rotated = systemLayoutClass.rotatePoint(topLeft, (Konva as any).getAngle(rotation))
    const dx = rotated.x - current.x
    const dy = rotated.y - current.y

    node.rotation(rotation)
    node.x(node.x() + dx)
    node.y(node.y() + dy)
  }

  /**
 * Vẽ chân và các thành phần liên quan trên bản đồ.
 *
 * @param {number} xStart - Tọa độ X ban đầu của chân.
 * @param {number} yStart - Tọa độ Y ban đầu của chân.
 * @param {CustomLegDto[] | null | undefined} systemData - Dữ liệu chứa thông tin về chân và các thành phần.
 * @param {number} unitId - Đơn vị đo lường (unitId) của bản đồ.
 * @param {number} mapGroundRatio - Tỷ lệ giữa chiều rộng của bản đồ và tổng chiều rộng thực của bản đồ trên màn hình.
 * @param {boolean} type - Loại chân (true nếu là loại 1, false nếu là loại 2).
 * @param {string | undefined} componentColor - Màu sắc của các thành phần (tùy chọn).
 * @returns {Object} Đối tượng chứa danh sách các đối tượng và tọa độ các thành phần.
 */
  drawLeg (
    xStart: number,
    yStart: number,
    systemData: CustomLegDto[] | null | undefined,
    unitId: number | undefined,
    mapGroundRatio: number,
    type: boolean,
    componentColor?: string
  ): CirclePoint[] {
    const lastLeg: CirclePoint = { x: xStart, y: yStart }
    let circles: CirclePoint[]
    let lastAngle: number | undefined
    const lastCoordinate = {
      coordinateX: 0,
      coordinateY: 0
    }
    if (systemData?.[0].spans != null && systemData?.[0].spans?.length > 0 &&
      systemData?.[0].spans?.[0].components != null &&
      systemData?.[0].spans?.[0].components.length > 0
    ) {
      circles = [{ x: xStart, y: yStart, color: componentColor }]
    } else {
      circles = [{ x: xStart, y: yStart, color: undefined }]
    }
    if (systemData != null) {
      systemData.forEach((leg: CustomLegDto, index) => {
        const realLength = this.ConvertFromRealMeterToCanvasPixels(
          leg.length ?? 0,
          unitId,
          mapGroundRatio ?? 0
        )
        const realAngle = this.convertDisplayAngleToCanvasAngle(
          leg.angle ?? 0,
          lastAngle ?? 0,
          index
        )
        leg.spanType = 'variable'
        leg.realAngle = realAngle
        lastAngle = realAngle
        const { x, y } = this.lineToAngle(
          Number(lastLeg.x.toFixed(13)),
          Number(lastLeg.y.toFixed(13)),
          realLength,
          realAngle)
        const tempLegStart = {
          x: lastLeg.x,
          y: lastLeg.y
        }
        lastLeg.x = x
        lastLeg.y = y
        if (type) {
          if (index <= 0) {
            const coordinate = this.getCoordinateXY(
              0,
              0,
              leg.length ?? 0,
              leg.angle ?? 0
            )
            leg.coordinateX = coordinate.coordinateX
            leg.coordinateY = coordinate.coordinateY
            lastCoordinate.coordinateX = coordinate.coordinateX
            lastCoordinate.coordinateY = coordinate.coordinateY
          } else {
            const coordinate = this.getCoordinateXY(
              lastCoordinate.coordinateX,
              lastCoordinate.coordinateY,
              leg.length ?? 0,
              -1 * (realAngle ?? 0)
            )
            leg.coordinateX = coordinate.coordinateX
            leg.coordinateY = coordinate.coordinateY
            lastCoordinate.coordinateX = coordinate.coordinateX
            lastCoordinate.coordinateY = coordinate.coordinateY
          }
        }
        if (leg.spans != null && leg.spans !== undefined) {
          let lastSpanLength = 0
          let tempSpanEnd = {
            x: tempLegStart.x,
            y: tempLegStart.y
          }
          leg.spans.forEach((span: CustomSpanDto) => {
            const spanValue = parseFloat(span.length?.toString() ?? '0')
            lastSpanLength =
              spanValue <= 0 || Number.isNaN(spanValue) ? 0 : spanValue
            lastSpanLength = this.ConvertFromRealMeterToCanvasPixels(
              lastSpanLength,
              unitId,
              mapGroundRatio ?? 0
            )
            const { x, y } = this.lineToAngle(
              Number(tempSpanEnd.x.toFixed(13)),
              Number(tempSpanEnd.y.toFixed(13)),
              lastSpanLength,
              realAngle)
            tempSpanEnd = {
              x,
              y
            }
            if (
              span.components !== null &&
              span.components !== undefined &&
              span.components?.length > 0
            ) {
              span.x = x
              span.y = y
              span.color = componentColor
            } else {
              span.x = x
              span.y = y
            }
          })
        }
        circles.push({
          x,
          y,
          leg
        })
      })
    }
    return circles
  }
}

export const systemLayoutClass = new Systemlayout()
