import React from 'react'
import { Box, Breadcrumbs, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { type GridColDef, type GridRowsProp } from '@mui/x-data-grid'

export default function SystemDetail (): JSX.Element {
  return (
    <Box>
      <Typography variant='h1' mb={4}>Crane Fall Protection Walkway</Typography>
      <Grid container spacing={2} mb={4} alignItems="center">
        <Grid xs={4}>
          <Typography variant='caption'>Customer Name: </Typography>
          <Typography component={'span'}>Hysafe</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography component={'span'} variant='caption'>Project Ref: </Typography>
          <Typography component={'span'}>Aircraft Fall Protection</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography component={'span'} variant='caption'>Project Url: </Typography>
          <Typography component={'span'}>https://esdsdev.azurewebsites.net/projects/detail/Hysafe-Canada-Project-HN-22066/2651/Aircraft-Fal.html</Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component='div'
          label="Projects list"
        />
        <Breadcrumb
          component='div'
          label="Project overview"
        />
        <Breadcrumb
          component='div'
          label="System Info"
        />
      </Breadcrumbs>
      <Grid container spacing={4} alignItems="center">
        <Grid xs={12} md={5}>
          <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
            <Box className="tableTools">
              <Typography variant='h4'>Notes</Typography>
              <Button size="small">View all</Button>
            </Box>
            <Box>
              <StyledDataGrid autoHeight rows={rowsDummy} columns={columnsDummy} rowSelection={false} disableColumnMenu={true} />
            </Box>
          </Box>
          <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
            <Box className="tableTools">
              <Typography variant='h4'>Reports</Typography>
              <Button size="small">View all</Button>
            </Box>
            <Box>
              <StyledDataGrid autoHeight rows={rowsDummy} columns={columnsDummy} rowSelection={false} disableColumnMenu={true} />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={7}>
          <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
            <Box className="tableTools">
              <Typography variant='h4'>Reports</Typography>
              <Button size="small">View all</Button>
            </Box>
            <Box>
              <StyledDataGrid autoHeight rows={rowsDummy} columns={columnsDummy} rowSelection={false} disableColumnMenu={true} />
            </Box>
          </Box>
          <Box sx={{ bgcolor: 'white', mt: 4 }} className="main">
            <Box className="tableTools">
              <Typography variant='h4'>Notes</Typography>
              <Button size="small">View all</Button>
            </Box>
            <Box>
              <StyledDataGrid autoHeight rows={rowsDummy} columns={columnsDummy} rowSelection={false} disableColumnMenu={true} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const columnsDummy: GridColDef[] = [
  {
    field: 'id',
    headerName: '#',
    width: 100
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1
  }
]

const rowsDummy: GridRowsProp = [
  {
    id: 12966,
    name: 'Corporis porro rerum id et voluptate eos sed.'
  },
  {
    id: 12965,
    name: 'Autem est nemo id a quibusdam ipsum ut error dicta.'
  },
  {
    id: 12885,
    name: 'Rerum ipsum dolore perspiciatis.'
  },
  {
    id: 12859,
    name: 'Aut repellendus explicabo '
  },
  {
    id: 12834,
    name: 'Excepturi odio adipisci '
  }
]
