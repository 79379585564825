import { useNode, useEditor } from '@craftjs/core'
import React from 'react'
import ContentEditable from 'react-contenteditable'

import { TextSettings } from './TextSettings'

export interface TextProps {
  fontSize: string
  textAlign: string
  fontWeight: string
  color: Record<'r' | 'g' | 'b' | 'a', string>
  shadow: number
  text: string
  textTransform: string
  borderBottom: string
  margin: [string, string, string, string]
  dataKey: string
}
const defaultMargin: [string, string, string, string] = ['0', '0', '0', '0']
export const Text = ({
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadow,
  text,
  margin = defaultMargin,
  textTransform,
  borderBottom,
  dataKey
}: Partial<TextProps>): any => {
  const {
    connectors: { connect },
    setProp
  } = useNode()
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled
  }))
  return (
    <ContentEditable
      innerRef={connect}
      html={String(text)}
      disabled={!enabled}
      onChange={(e) => {
        setProp((prop) => (prop.text = e.target.value), 500)
      }}
      datatype={dataKey}
      style={{
        width: '100%',
        margin,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        color: `rgba(${Object.values(color ?? { r: 0, g: 0, b: 0, a: 1 })})`,
        fontSize: `${fontSize ?? ''}px`,
        textShadow: `0px 0px 2px rgba(0,0,0,${(shadow ?? 0) / 100})`,
        fontWeight,
        textAlign,
        textTransform: textTransform === 'uppercase' ? 'uppercase' : 'none',
        borderBottom: borderBottom === 'yes' ? '1px solid' : 'none'
      }}
    />
  )
}

Text.craft = {
  displayName: 'Text',
  props: {
    fontSize: '13',
    textAlign: 'left',
    fontWeight: '400',
    color: '',
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: 'Text',
    dataKey: ''
  },
  related: {
    toolbar: TextSettings
  }
}
