import React, { useCallback } from 'react'
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  IconButton,
  Typography,
  type TablePaginationProps
} from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import {
  CommonApi,
  type CommonDto,
  RegionApi,
  type RegionDto
} from '../../common/services'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import * as Yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { RegionModal } from './components'
import AlertDelete from '../../common/components/AlertDelete'
import {
  type GridColDef,
  GridRowModes,
  type GridRowModesModel,
  type GridRowId,
  type GridEventListener,
  GridRowEditStopReasons,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import MuiPagination from '@mui/material/Pagination'

export default function SystemDetail (): JSX.Element {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(false)
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  )
  const { data, isFetching, refetch } = useQuery(
    ['/api/v2/Region/GetAll'],
    async () => {
      const { data } = await new RegionApi().apiV2RegionGetAllGet()
      return data
    },
    { enabled: false }
  )
  const common = useQuery(
    ['/api/v2/Common'],
    async () => {
      const res = await new CommonApi().apiV2CommonGet()
      return res.data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void refetch()
    void common.refetch()
  }, [])
  const [rows, setRows] = React.useState(data?.data)
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])

  const handleDelete = async (id: number): Promise<void> => {
    try {
      const response = await new RegionApi().apiV2RegionDeleteIdDelete(id)
      if (response.status === 209) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        await refetch()
        handleDialogClose()
        toast.success(t(KeyTranslation.Message_Delete_Success))
      }
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }

  const [regionModalProps, setRegionModalProps] = React.useState<{
    visible: boolean
    data?: RegionDto
  }>({
    visible: false,
    data: undefined
  })
  const [searchTerm, setSearchTerm] = React.useState('')
  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm.length === 0) {
      return data.data
    }

    return data.data?.filter((region: RegionDto) => {
      const searchTermLower = searchTerm.toLowerCase()
      return (
        (region.name?.toLowerCase().includes(searchTermLower) ?? false) ||
        (region.code?.toLowerCase().includes(searchTermLower) ?? false) ||
        (region.displayName?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (region.standardName?.toLowerCase().includes(searchTermLower) ??
          false) ||
        (region.unitName?.toLowerCase().includes(searchTermLower) ?? false)
      )
    })
  }, [data, searchTerm])
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows?.find((row) => row.id === id)
    if (editedRow?.id != null) {
      setRows(rows?.filter((row) => row.id !== id))
    }
  }
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ): void => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }
  const handleRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ): void => {
    setRowModesModel(newRowModesModel)
  }
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_RegionName_invalid) ?? ''),
    code: Yup.string().required(t(KeyTranslation.Message_RegionCode_invalid) ?? ''),
    dRingHeightAbovePlatform: Yup.number()
      .typeError(t(KeyTranslation.Validate_Dring_Height) ?? '')
      .positive(t(KeyTranslation.Validate_Dring_Height) ?? '')
      .required(t(KeyTranslation.Validate_Dring_Height) ?? '')
  })
  const processRowUpdate = async (newRow: RegionDto): Promise<any> => {
    try {
      setIsLoading(true)
      await validationSchema.validate(newRow)
      const updatedRow = {
        ...newRow,
        isNew: false,
        unitId: common.data?.data?.units?.find(
          (unit: CommonDto) => unit.name === newRow.unitName
        )?.id,
        standardId: common.data?.data?.standards?.find(
          (standard: CommonDto) => standard.name === newRow.standardName
        )?.id
      }
      setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)))
      const response = await new RegionApi().apiV2RegionUpdatePut(updatedRow)
      if (response.status === 209) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${response.data.error}`))
      } else {
        toast.success(t(KeyTranslation.Message_Update_success))
      }
      return updatedRow
    } catch (ex: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    } finally {
      setIsLoading(false)
    }
  }

  const columnsDummy: Array<GridColDef<RegionDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Region_RegionName) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'code',
      headerName: t(KeyTranslation.Region_RegionCode) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'displayName',
      headerName: t(KeyTranslation.Region_DisplayName) ?? '',
      flex: 1,
      editable: true
    },
    {
      field: 'standardName',
      headerName: t(KeyTranslation.Common_Standard) ?? '',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: common.data?.data?.standards?.map(
        (standard: CommonDto) => ({
          label: standard.name,
          value: standard.name,
          key: standard.id
        })
      )
    },
    {
      field: 'unitName',
      headerName: t(KeyTranslation.Common_Units) ?? '',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: common.data?.data?.units?.map((unit: CommonDto) => ({
        label: unit.name,
        value: unit.name,
        key: unit.id
      }))
    },
    {
      field: 'dRingHeightAbovePlatform',
      headerName: t(KeyTranslation.Header_DRingHeight) ?? '',
      flex: 1,
      editable: true,
      type: 'number',
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      headerName: '',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <Tooltip title={t(KeyTranslation.Save)} key={`${id}-save`}>
              <IconButton onClick={handleSaveClick(id)} >
                <SaveOutlinedIcon/>
              </IconButton>
            </Tooltip>,
            <Tooltip title={t(KeyTranslation.Common_Cancel)} key={`${id}-cancel`}>
              <IconButton onClick={handleCancelClick(id)} >
                <CloseOutlinedIcon/>
              </IconButton>
            </Tooltip>
          ]
        }

        return [
          <><Tooltip title={t(KeyTranslation.Btn_Edit)} key={`${id}-edit`}>
            <IconButton onClick={handleEditClick(id)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Btn_Delete)} key={`${id}-delete`}>
                <IconButton onClick={() => { setDialogOpen({ open: true, id: Number(id) }) }}>
              <DeleteOutlinedIcon/>
            </IconButton>
          </Tooltip></>
        ]
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext()
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }
  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }
  return (
    <Box>
      <Typography variant="h1">
        {t(KeyTranslation.Organisation_Region)}
      </Typography>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5 }} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t(KeyTranslation.Common_Search) ?? ''}
            size="small"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setRegionModalProps({
                visible: true,
                data: undefined
              })
            }}
          >
            {t(KeyTranslation.Region_New_Region)}
          </Button>
        </Box>
        <StyledDataGrid
          autoHeight
          loading={isLoading || isFetching}
          rows={filteredData ?? []}
          columns={columnsDummy}
          rowSelection={false}
          disableColumnMenu={true}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            pagination: CustomPagination
          }}
        />
        {regionModalProps.visible && (
          <RegionModal
            open={regionModalProps.visible}
            onClose={(): void => {
              void refetch()
              setRegionModalProps({
                visible: false,
                data: undefined
              })
            }}
          />
        )}
        <AlertDelete
          handleDelete={() => {
            void handleDelete(dialogOpen.id)
          }}
          dialogOpen={dialogOpen.open}
          handleDialogClose={handleDialogClose}
        />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Box>
  )
}
