import { useEditor } from '@craftjs/core'
import cx from 'classnames'
import React, { useEffect } from 'react'

import { Sidebar } from './Sidebar'
// import { Toolbox } from './Toolbox'
import { type PageProps, type AppEditorProps } from '../../..'
import { Header } from './Header'
import { Toolbox } from './Toolbox'
export const Viewport: React.FC<{
  children?: React.ReactNode
  editorData?: AppEditorProps
  generateHtml: () => string
  currentRef: any
  onChangePage: (pageIndex: number, page: PageProps) => void }> = ({
  children,
  onChangePage,
  editorData,
  generateHtml, currentRef
}) => {
  const {
    enabled,
    connectors,
    actions: { setOptions }
  } = useEditor((state) => ({
    enabled: state.options.enabled
  }))

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!window) {
      return
    }

    window.requestAnimationFrame(() => {
      // Notify doc site
      window.parent.postMessage(
        {
          LANDING_PAGE_LOADED: true
        },
        '*'
      )

      setTimeout(() => {
        setOptions((options) => {
          options.enabled = true
        })
      }, 200)
    })
  }, [setOptions])
  return (
    <div className="viewport">
      <div
        className={cx(['flex h-full overflow-hidden flex-row w-calc fixed '])}
      >
        <Toolbox editorData={editorData}/>
        <div className="page-container flex flex-1 h-full flex-col pb-150">
        <Header
          editorData={editorData}
           onChangePage={onChangePage}
           generateHtml={generateHtml}
           currentRef={currentRef}
          />
          <div
            className={cx([
              'craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto',
              {
                'bg-renderer-gray': enabled
              }
            ])}
            ref={(ref) => {
              if (ref != null) {
                connectors.select(ref, '')
              }
            }}
          >
            <div className="relative flex-col flex items-center pt-8 mb-54">
              {children}
            </div>
            <div
              className={
                'flex items-center justify-center w-full pt-6 text-xs text-light-gray-2'
              }
            ></div>
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  )
}
