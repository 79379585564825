import React from 'react'
import { Box, Button, FormControlLabel, FormGroup, Radio } from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import bgLocation from '../../../assets/images/Application.png'
import { type UserChoicesProps } from './modalContainer'
import KeyTranslation from '../../../common/KeyTranslation.json'

interface ApplicationModalProps {
  open: boolean
  onNextStep: () => void
  onBackStep: () => void
  saveUserChoice: (choice: any) => void
  userChoices?: UserChoicesProps | null
}

export default function ApplicationModal ({
  onNextStep,
  onBackStep,
  saveUserChoice,
  userChoices
}: ApplicationModalProps): JSX.Element {
  const { t } = useTranslation()
  const [projectData] = React.useState({
    applicationLocationId: 0
  })
  React.useEffect(() => {
    void formik.setValues({
      ...projectData,
      applicationLocationId: userChoices?.applicationLocationId ?? 0
    })
  }, [userChoices])

  const validationSchema = Yup.object().shape({
    applicationLocationId: Yup.number()
      .oneOf([4, 3, 2, 1], t(KeyTranslation.ApplicationLocationId_invalid) ?? '')
      .required(t(KeyTranslation.ApplicationLocationId_invalid) ?? '')
  })

  const handleSubmit = (): void => {
    saveUserChoice(formik.values)
    onNextStep()
  }

  const formik = useFormik({
    initialValues: projectData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  const handleRadioChange = (value: number): void => {
    void formik.setFieldValue('applicationLocationId', value)
  }

  return (
    <><form onSubmit={formik.handleSubmit}>
      <AlertError formik={formik} />
      <Box mt={4} mb={7} sx={{ textAlign: 'center', position: 'relative' }}>
        <img src={bgLocation} alt="Background" className='img480' />
        <FormGroup className="containerCheckbox">
          <FormControlLabel
            control={<Radio
              checked={formik.values.applicationLocationId === 4}
              onChange={() => { handleRadioChange(4) } }
              value={4}
              disabled={false}
              size="small" />}
            label={t(KeyTranslation.System_Popup_Floor)}
            className="floor" />
          <FormControlLabel
            control={<Radio
              checked={formik.values.applicationLocationId === 3}
              onChange={() => { handleRadioChange(3) } }
              value={3}
              disabled={false}
              size="small" />}
            label={t(KeyTranslation.System_Popup_Floor_raise)}
            className="floorRaise" />
          <FormControlLabel
            control={<Radio
              checked={formik.values.applicationLocationId === 2}
              onChange={() => { handleRadioChange(2) } }
              value={2}
              disabled={false}
              size="small" />}
            label={t(KeyTranslation.System_Popup_Wall_middle)}
            className="wall" />
          <FormControlLabel
            control={<Radio
              checked={formik.values.applicationLocationId === 1}
              onChange={() => { handleRadioChange(1) } }
              value={1}
              disabled={false}
              size="small" />}
            label={t(KeyTranslation.System_Popup_Roof_hanging)}
            className="roofHanging" />
        </FormGroup>
      </Box>
      <Box display="flex" justifyContent="end" gap={4}>
        <Button onClick={onBackStep}>{t(KeyTranslation.Common_Back)}</Button>
        <Button type="submit" variant="contained" onClick={() => { formik.handleSubmit() }}>
          {t(KeyTranslation.next)}
        </Button>
      </Box>
    </form></>
  )
}
