import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import KeyTranslation from '../KeyTranslation.json'
interface AlertProps {
  dialogOpen: boolean
  handleDialogClose: () => void
  handleDelete: () => void
}
export default function AlertDialog ({ dialogOpen, handleDialogClose, handleDelete }: AlertProps): JSX.Element {
  const { t } = useTranslation()
  return (
        <Dialog open={dialogOpen} onClose={() => { handleDialogClose() }}>
            <DialogTitle>{t(KeyTranslation.Common_ConfirmDelete)}</DialogTitle>
            <DialogActions>
                <Button size='small' onClick={() => { handleDialogClose() }}>{t(KeyTranslation.Common_Cancel)}</Button>
                <Button variant="contained" size='small' color="error" onClick={() => { handleDelete() }}>
                {t(KeyTranslation.delete)}
                </Button>
            </DialogActions>
        </Dialog>
  )
}
