import React from 'react'
import { Box, Card, CardActionArea, CardContent, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ModalContent } from '../../common/components'
import { t } from 'i18next'
import KeyTranslation from '../../common/KeyTranslation.json'
interface userRolesProp {
  roleId: string
  roleName: string
}
interface helpModalProps {
  open: boolean
  onClose: () => void
}
const softwarePackages = [
  { moduleName: '@craftjs/core@0.2.0-beta.12', license: 'MIT', projectDetail: 'https://github.com/prevwong/craft.js' },
  { moduleName: '@craftjs/layers@0.2.0-beta.12', license: 'MIT', projectDetail: 'https://github.com/prevwong/craft.js' },
  { moduleName: '@emotion/react@11.10.6', license: 'MIT', projectDetail: 'https://github.com/emotion-js/emotion' },
  { moduleName: '@emotion/styled@11.10.6', license: 'MIT', projectDetail: 'https://github.com/emotion-js/emotion' },
  { moduleName: '@material-ui/core@4.12.4', license: 'MIT', projectDetail: 'https://github.com/mui/material-ui' },
  { moduleName: '@mui/icons-material@5.11.16', license: 'MIT', projectDetail: 'https://github.com/mui/material-ui' },
  { moduleName: '@mui/material@5.12.1', license: 'MIT', projectDetail: 'https://github.com/mui/material-ui' },
  { moduleName: '@mui/x-data-grid@6.2.1', license: 'MIT', projectDetail: 'https://github.com/mui/mui-x' },
  { moduleName: '@mui/x-data-grid-generator@7.1.1', license: 'MIT', projectDetail: 'https://github.com/mui/mui-x' },
  { moduleName: '@mui/x-data-grid-pro@6.8.0', license: 'MIT', projectDetail: 'https://github.com/mui/mui-x' },
  { moduleName: '@mui/x-date-pickers@6.5.0', license: 'MIT', projectDetail: 'https://github.com/mui/mui-x' },
  { moduleName: '@mui/x-license-pro@6.6.0', license: 'MIT', projectDetail: 'https://github.com/mui/mui-x' },
  { moduleName: '@react-google-maps/api@2.19.2', license: 'MIT', projectDetail: 'https://github.com/JustFly1984/react-google-maps-api' },
  { moduleName: '@tanstack/react-query@4.29.5', license: 'MIT', projectDetail: 'https://github.com/TanStack/query' },
  { moduleName: '@testing-library/jest-dom@5.16.5', license: 'MIT', projectDetail: 'https://github.com/testing-library/jest-dom' },
  { moduleName: '@testing-library/react@13.4.0', license: 'MIT', projectDetail: 'https://github.com/testing-library/react-testing-library' },
  { moduleName: '@testing-library/user-event@13.5.0', license: 'MIT', projectDetail: 'https://github.com/testing-library/user-event' },
  { moduleName: '@types/jest@27.5.2', license: 'MIT', projectDetail: 'https://github.com/DefinitelyTyped/DefinitelyTyped' },
  { moduleName: '@types/node@16.18.24', license: 'MIT', projectDetail: 'https://github.com/DefinitelyTyped/DefinitelyTyped' },
  { moduleName: '@types/react@18.0.37', license: 'MIT', projectDetail: 'https://github.com/DefinitelyTyped/DefinitelyTyped' },
  { moduleName: '@types/react-dom@18.0.11', license: 'MIT', projectDetail: 'https://github.com/DefinitelyTyped/DefinitelyTyped' },
  { moduleName: 'classnames@2.3.2', license: 'MIT', projectDetail: 'https://github.com/JedWatson/classnames' },
  { moduleName: 'dayjs@1.11.7', license: 'MIT', projectDetail: 'https://github.com/iamkun/dayjs' },
  { moduleName: 'eslint-config-next@13.3.0', license: 'MIT', projectDetail: 'https://github.com/vercel/next.js' },
  { moduleName: 'formik@2.2.9', license: 'MIT', projectDetail: 'https://github.com/formium/formik' },
  { moduleName: 'i18next@22.4.15', license: 'MIT', projectDetail: 'https://github.com/i18next/i18next' },
  { moduleName: 'i18next-http-backend@2.2.0', license: 'MIT', projectDetail: 'https://github.com/i18next/i18next-http-backend' },
  { moduleName: 'jszip@3.10.1', license: 'MIT', projectDetail: 'https://github.com/Stuk/jszip' },
  { moduleName: 'konva@9.2.0', license: 'MIT', projectDetail: 'https://github.com/konvajs/konva' },
  { moduleName: 'localforage@1.10.0', license: 'Apache-2.0', projectDetail: 'https://github.com/localForage/localForage' },
  { moduleName: 'lodash@4.17.21', license: 'MIT', projectDetail: 'https://github.com/lodash/lodash' },
  { moduleName: 'match-sorter@6.3.1', license: 'MIT', projectDetail: 'https://github.com/kentcdodds/match-sorter' },
  { moduleName: 'material-ui-color-picker@3.5.1', license: 'MIT', projectDetail: 'https://github.com/nitin42/material-ui-color-picker' },
  { moduleName: 'moment@2.29.4', license: 'MIT', projectDetail: 'https://github.com/moment/moment' },
  { moduleName: 'next@13.3.0', license: 'MIT', projectDetail: 'https://github.com/vercel/next.js' },
  { moduleName: 'pdfjs-dist@3.9.179', license: 'Apache-2.0', projectDetail: 'https://github.com/mozilla/pdf.js' },
  { moduleName: 're-resizable@6.9.9', license: 'MIT', projectDetail: 'https://github.com/bokuweb/re-resizable' },
  { moduleName: 'react@18.2.0', license: 'MIT', projectDetail: 'https://github.com/facebook/react' },
  { moduleName: 'react-contenteditable@3.3.7', license: 'MIT', projectDetail: 'https://github.com/lovasoa/react-contenteditable' },
  { moduleName: 'react-copy-to-clipboard@5.1.0', license: 'MIT', projectDetail: 'https://github.com/nkbt/react-copy-to-clipboard' },
  { moduleName: 'react-dom@18.2.0', license: 'MIT', projectDetail: 'https://github.com/facebook/react' },
  { moduleName: 'react-dropzone@14.2.3', license: 'MIT', projectDetail: 'https://github.com/react-dropzone/react-dropzone' },
  { moduleName: 'react-i18next@12.2.2', license: 'MIT', projectDetail: 'https://github.com/i18next/react-i18next' },
  { moduleName: 'react-konva@18.2.10', license: 'MIT', projectDetail: 'https://github.com/konvajs/react-konva' },
  { moduleName: 'react-router-dom@6.10.0', license: 'MIT', projectDetail: 'https://github.com/remix-run/react-router' },
  { moduleName: 'react-scripts@5.0.1', license: 'MIT', projectDetail: 'https://github.com/facebook/create-react-app' },
  { moduleName: 'react-spring@9.7.2', license: 'MIT', projectDetail: 'https://github.com/pmndrs/react-spring' },
  { moduleName: 'react-toastify@9.1.3', license: 'MIT', projectDetail: 'https://github.com/fkhadra/react-toastify' },
  { moduleName: 'react-virtualized@9.22.5', license: 'MIT', projectDetail: 'https://github.com/bvaughn/react-virtualized' },
  { moduleName: 'react-virtualized-auto-sizer@1.0.17', license: 'MIT', projectDetail: 'https://github.com/bvaughn/react-virtualized-auto-sizer' },
  { moduleName: 'react-window@1.8.9', license: 'MIT', projectDetail: 'https://github.com/bvaughn/react-window' },
  { moduleName: 'react-youtube@10.1.0', license: 'MIT', projectDetail: 'https://github.com/tjallingt/react-youtube' },
  { moduleName: 'react-zoom-pan-pinch@3.1.0', license: 'MIT', projectDetail: 'https://github.com/prc5/react-zoom-pan-pinch' },
  { moduleName: 'sass@1.62.0', license: 'MIT', projectDetail: 'https://github.com/sass/dart-sass' },
  { moduleName: 'sort-by@1.2.0', license: 'MIT', projectDetail: 'https://github.com/stayradiated/sort-by' },
  { moduleName: 'styled-components@6.0.5', license: 'MIT', projectDetail: 'https://github.com/styled-components/styled-components' },
  { moduleName: 'uuid@9.0.0', license: 'MIT', projectDetail: 'https://github.com/uuidjs/uuid' },
  { moduleName: 'web-vitals@2.1.4', license: 'MIT', projectDetail: 'https://github.com/GoogleChrome/web-vitals' },
  { moduleName: 'yup@1.1.1', license: 'MIT', projectDetail: 'https://github.com/jquense/yup' }
]

export default function HelpModal ({ open, onClose }: helpModalProps): JSX.Element {
  const loginStore = localStorage.getItem('LOGIN')
  const parsedData = JSON.parse(loginStore ?? '')
  const buildVersion = process.env.REACT_APP_VERSION
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalContent onClose={onClose} title={t(KeyTranslation.About)}>
          <Card style={{ marginTop: 16 }}>
            <CardActionArea>
              <CardContent>
                <Box>
                  <Typography variant="h4">
                  {t(KeyTranslation.Build_Version)}
                  </Typography>
                </Box>
                <Grid pt={3} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {buildVersion}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ marginTop: 16 }}>
            <CardActionArea>
              <CardContent>
                <Box>
                  <Typography variant="h4">
                    {t(KeyTranslation.System_BasicInformation)}
                  </Typography>
                </Box>
                <Grid pt={3} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t(KeyTranslation.Header_UserName)}:</Typography>
                    <Typography variant="subtitle1">
                      {parsedData.userName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t(KeyTranslation.Header_FirstName)}:</Typography>
                    <Typography variant="subtitle1">
                      {parsedData.firstName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t(KeyTranslation.Header_LastName)}:</Typography>
                    <Typography variant="subtitle1">
                      {parsedData.lastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">{t(KeyTranslation.Menu_Roles_management)}:</Typography>
                    {(Boolean((parsedData?.userRoles))) && (
                      <Typography variant="subtitle1">
                        {parsedData.userRoles.map((userRoles: userRolesProp) => userRoles.roleName).join(', ')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card style={{ marginTop: 16 }}>
            <CardActionArea>
              <CardContent>
                <Box mb={4}>
                  <Typography variant="h4">
                  {t(KeyTranslation.List_Of_Software)}
                  </Typography>
                </Box>
                <TableContainer component={Paper} style={{ height: 400 }}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t(KeyTranslation.Module_Name)}</TableCell>
                        <TableCell>{t(KeyTranslation.License)}</TableCell>
                        <TableCell>{t(KeyTranslation.Project_Detail)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {softwarePackages.map((pkg, index) => (
                        <TableRow
                          key={index}
                        >
                          <TableCell>
                            {pkg.moduleName}
                          </TableCell>
                          <TableCell>{pkg.license}</TableCell>
                          <TableCell><a href={pkg.projectDetail}>{pkg.projectDetail}</a></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </CardActionArea>
          </Card>
        </ModalContent>
      </Box>
    </Modal>
  )
}
