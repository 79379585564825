import React from 'react'
import { Box, Button, TextField } from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { type UserChoicesReportProps } from './modalContainerReport'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface SystemModalProps {
  open: boolean
  onClose: () => void
  onNextStep: () => void
  saveUserChoice: (choice: any) => void
  userChoices?: UserChoicesReportProps | null
}

export default function SystemModal ({
  onClose,
  onNextStep,
  saveUserChoice,
  userChoices
}: SystemModalProps): JSX.Element {
  const { t } = useTranslation()
  const [reportData] = React.useState({
    name: '',
    description: '',
    nextInspectionDate: dayjs()
  })
  React.useEffect(() => {
    void formik.setValues({
      ...reportData,
      name: userChoices?.name ?? '',
      description: userChoices?.description ?? '',
      nextInspectionDate: userChoices?.nextInspectionDate ?? dayjs()
    })
  }, [userChoices])
  const validationSchema = Yup.object({
    name: Yup.string().required(t(KeyTranslation.Message_ReportName_invalid) ?? ''),
    description: Yup.string().required(t(KeyTranslation.Message_Description_invalid) ?? '')
  })
  const formik = useFormik({
    initialValues: reportData,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveUserChoice(values)
      onNextStep()
    },
    validationSchema
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box mt={4} mb={7}>
          <TextField
            label={t(KeyTranslation.Report_ReportName)}
            variant="outlined"
            fullWidth
            helperText={t(KeyTranslation.Eg_Internal_Report_1)}
            value={formik.values.name}
            onChange={(event) => {
              void formik.setFieldValue('name', event.target.value)
            }}
          />
        </Box>
        <Box mt={4} mb={7}>
          <TextField
            label={t(KeyTranslation.Header_Description)}
            variant="outlined"
            fullWidth
            helperText={t(KeyTranslation.Report_DescHelp)}
            rows={1}
            multiline
            value={formik.values.description}
            onChange={(event) => {
              void formik.setFieldValue('description', event.target.value)
            }}
          />
        </Box>
        <Box mt={4} mb={7}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                value={formik.values.nextInspectionDate}
                onChange={(newValue) => {
                  void formik.setFieldValue('nextInspectionDate', newValue)
                }}
                sx={{ width: '100%' }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          <Button
            onClick={() => {
              onClose()
            }}
          >
            {t(KeyTranslation.Common_Back)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t(KeyTranslation.Common_Next)}
          </Button>
        </Box>
      </form>
    </>
  )
}
