import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link, useParams } from 'react-router-dom'
import {
  ProjectApi,
  ProjectDocumentApi,
  type ProjectDocumentDto
} from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumbs, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { ToastContainer, toast } from 'react-toastify'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import Grid from '@mui/material/Unstable_Grid2'
import { type GridColDef } from '@mui/x-data-grid-pro'
import DropzoneDocumentProject from './components/DropzoneDocumentProject'
import pdf from '../../assets/images/mc-file-pdf.svg'
import unKnown from '../../assets/images/mc-file-unknown.svg'
import sheet from '../../assets/images/mc-file-spreadsheet.svg'
// eslint-disable-next-line import/no-duplicates
import png from '../../assets/images/mc-file-image.svg'
// eslint-disable-next-line import/no-duplicates
import jpeg from '../../assets/images/mc-file-image.svg'
import doc from '../../assets/images/mc-file-document.svg'
import plain from '../../assets/images/mc-file-text.svg'
import AlertDelete from '../../common/components/AlertDelete'
import { useCallback, useState } from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import KeyTranslation from '../../common/KeyTranslation.json'

const icons: Record<string, string> = {
  pdf,
  unKnown,
  sheet,
  png,
  jpeg,
  doc,
  plain
}
const drawerWidth = 320
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  width: 102,
  ...((open ?? false) && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  position: 'relative'
}))
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))
export default function documents (): JSX.Element {
  const id = useParams()
  const projectId = Number(id.id)
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<
  ProjectDocumentDto | undefined
  >(undefined)
  const fileType = selectedItem?.type?.split('/').pop()?.toLowerCase()
  const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ? icons.sheet
    : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
      ? icons.doc
      : icons[fileType ?? 'unKnown'] ?? icons.unKnown
  const isImage = fileType === 'png' || fileType === 'jpeg'
  const filename =
    selectedItem?.blobUrl?.split('/').pop()?.replace('_xs', '') ?? ''
  const transformedImageSrc = selectedItem?.blobUrl
    ?.replace('esdsqa/', 'esdsprivate/')
    .replace(/\/[^/]+$/, `/${filename}`)
  const handleDrawerOpen =
    (open: boolean, id: number) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return
        }

        setOpen(open)
        const selectedItem = ProjectDocumentData?.data?.find(
          (item) => item.id === id
        )
        setSelectedItem(selectedItem)
      }
  const handleDrawerClose = (): void => {
    setOpen(false)
  }
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleDialogClose = useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const { data: projectData, refetch: projectRefetch } = useQuery(
    ['/api/v2/Project/GetProjectById', projectId],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  const {
    data: ProjectDocumentData,
    refetch: ProjectDocumentRefetch,
    isFetching: ProjectDocumentFetching,
    isLoading: ProjectDocumentLoading
  } = useQuery(
    [
      '/api/v2/ProjectDocument/ProjectAndProductDocuments/{projectId}',
      projectId
    ],
    async () => {
      const { data } =
        await new ProjectDocumentApi().apiV2ProjectDocumentProjectAndProductDocumentsProjectIdGet(
          projectId
        )
      return data
    },
    { enabled: false }
  )
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        setLoading(true)
        const response = await new ProjectDocumentApi().apiV2ProjectDocumentIdDelete(id)
        if (response.status === 209) {
          handleDialogClose()
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          toast.error(t(`${(response.data as any).error}`))
        } else {
          await ProjectDocumentRefetch()
          handleDialogClose()
          handleDrawerClose()
          toast.success(t(KeyTranslation.Message_Delete_Success))
        }
      } catch (ex: any) {
        handleDialogClose()
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toast.error(t(`${ex.response.data.error}`))
      } finally {
        setLoading(false)
      }
    },
    [ProjectDocumentRefetch, handleDialogClose]
  )
  const handleDownload = async (blobUrl: string): Promise<void> => {
    const filename = blobUrl.split('/').pop()?.replace('_xs', '') ?? ''
    const transformedImageSrc = blobUrl
      .replace('esdsqa/', 'esdsprivate/')
      .replace(/\/[^/]+$/, `/${filename}`)
    try {
      setLoading(true)
      if (blobUrl != null) {
        const url = transformedImageSrc
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    void projectRefetch()
    void ProjectDocumentRefetch()
  }, [])
  const columnsDummy: Array<GridColDef<ProjectDocumentDto>> = [
    {
      field: 'name',
      headerName: t(KeyTranslation.Common_Name) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        const fileType = row.type?.split('/').pop()?.toLowerCase()
        const iconUrl = fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? icons.sheet
          : fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
            ? icons.doc
            : icons[fileType ?? 'unKnown'] ?? icons.unKnown
        return (
          <>
            <div className="flexCenter">
              <img
                width={'100%'}
                height={30}
                style={{ objectFit: 'contain' }}
                src={iconUrl}
                alt={row.name ?? ''}
              />
              <Tooltip title={row.name}>
                <Typography>{row.name}</Typography>
              </Tooltip>
            </div>
          </>
        )
      }
    },
    {
      field: 'projectId',
      headerName: t(KeyTranslation.Document_Type) ?? '',
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.projectId === 0
            ? t(KeyTranslation.Product_Documents)
            : t(KeyTranslation.Project_Documents)}>
            <Typography>{row.projectId === 0
              ? t(KeyTranslation.Product_Documents)
              : t(KeyTranslation.Project_Documents)}</Typography>
          </Tooltip>
        )
      }
    },

    {
      field: 'size',
      headerName: t(KeyTranslation.Size) ?? '',
      width: 150,
      renderCell: ({ row }) => {
        const sizeInBytes = row.size ?? 0
        const sizeInKB = sizeInBytes / 1024
        return <Tooltip title={`${sizeInKB.toFixed(2)} KB`}>
          <Typography>{`${sizeInKB.toFixed(2)} KB`}</Typography>
        </Tooltip>
      }
    },

    {
      field: 'type',
      headerName: t(KeyTranslation.File_Type) ?? '',
      width: 150,
      renderCell: ({ row }) => {
        let fileTypeLabel = row.type

        if ((fileTypeLabel?.includes('image/png')) ?? false) {
          fileTypeLabel = 'PNG'
        } else if ((fileTypeLabel?.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) ?? false) {
          fileTypeLabel = 'DOCUMENT'
        } else if ((fileTypeLabel?.includes('application/pdf')) ?? false) {
          fileTypeLabel = 'PDF'
        } else if ((fileTypeLabel?.includes('image/jpeg')) ?? false) {
          fileTypeLabel = 'JPEG'
        } else if ((fileTypeLabel?.includes('text/plain')) ?? false) {
          fileTypeLabel = 'TXT'
        } else if ((fileTypeLabel?.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) ?? false) {
          fileTypeLabel = 'SPREADSHEET'
        }
        return <Tooltip title={fileTypeLabel ?? row.type}>
          <Typography>{fileTypeLabel ?? row.type}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'updatedBy',
      headerName: t(KeyTranslation.Modified) ?? '',
      width: 150,
      renderCell: ({ row }) => {
        return <Tooltip title={row.updatedBy}>
          <Typography>{row.updatedBy}</Typography>
        </Tooltip>
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" gap={3}>
            <Tooltip
              title={t(KeyTranslation.Btn_View)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton onClick={handleDrawerOpen(true, row.id ?? 0)}>
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_Download)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                disabled={loading}
                onClick={() => {
                  void handleDownload(row.blobUrl ?? '')
                }}
              >
                <DownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(KeyTranslation.Btn_Delete)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14]
                      }
                    }
                  ]
                }
              }}
            >
              <IconButton
                onClick={() => {
                  setDialogOpen({
                    open: true,
                    id: row.id ?? 0
                  })
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ]
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Main open={open}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid xs={6}>
            <Typography variant="h1" mb={4}>
              {projectData?.data?.projectName}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography variant="caption">
              {t(KeyTranslation.Common_CustomerName)}:{' '}
            </Typography>
            <Typography component={'span'}>
              {projectData?.data?.customerName}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography component={'span'} variant="caption">
              {t(KeyTranslation.Common_System_Ref)}:{' '}
            </Typography>
            <Typography component={'span'}>
              {projectData?.data?.reference}
            </Typography>
          </Grid>
        </Grid>
        <Breadcrumbs aria-label="breadcrumb">
          <Breadcrumb
            component="div"
            label={
              <Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>
            }
          />
          <Breadcrumb
            component="div"
            label={
              <Link to={`/projects/${projectId}`}>
                {t(KeyTranslation.Common_ProjectOverview)}
              </Link>
            }
          />
          <Breadcrumb
            component="div"
            label={t(KeyTranslation.DocumentPhoto_DocumentsAndPhotos)}
          />
        </Breadcrumbs>
        <Box>
          <Box className="main" sx={{ mt: 5 }}>
            <Box sx={{ bgcolor: 'white' }}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h1">
                  {t(KeyTranslation.DocumentPhoto_DocumentsAndPhotos)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <DropzoneDocumentProject
                    projectId={projectId}
                    ProjectDocumentRefetch={ProjectDocumentRefetch}
                    toast={toast}
                  />
                </Box>
              </Box>
              <StyledDataGrid
                autoHeight
                loading={ProjectDocumentLoading || ProjectDocumentFetching}
                rows={ProjectDocumentData?.data ?? []}
                columns={columnsDummy}
                rowSelection={false}
                disableColumnMenu={true}
              />
              <AlertDelete
                handleDelete={() => {
                  void handleDelete(dialogOpen.id)
                }}
                dialogOpen={dialogOpen.open}
                handleDialogClose={handleDialogClose}
              />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Box>
          </Box>
        </Box>
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box
          sx={{
            marginTop: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            p: 5,
            alignItems: 'center',
            height: '76px'
          }}
        >
          <Typography variant="h4" sx={{ background: '#ffff' }}>
            {t(KeyTranslation.General_Info)}
          </Typography>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl'
                ? (
                  <ChevronLeftIcon />
                  )
                : (
                  <ChevronRightIcon />
                  )}
            </IconButton>
          </DrawerHeader>
        </Box>
        <Box>
          <Box className="infoDocument">
            <Box>
              {isImage
                ? (
                  <div className="flex">
                    <img
                      width={280}
                      height={'auto'}
                      style={{ objectFit: 'contain' }}
                      src={transformedImageSrc ?? ''}
                      alt={selectedItem?.name ?? ''}
                    />
                  </div>
                  )
                : (
                  <div className="flex">
                    <img
                      width={40}
                      height={40}
                      style={{ objectFit: 'contain' }}
                      src={iconUrl}
                      alt={selectedItem?.name ?? ''}
                    />
                  </div>
                  )}
            </Box>
            <Typography style={{ margin: '20px 0 24px 0', overflow: 'hidden', wordBreak: 'break-all' }} variant="h5">
              {selectedItem?.name}
            </Typography>
            <Box className="properties">
              <Typography variant="h6">{t(KeyTranslation.Properties)}</Typography>
              <Box className="flex">
                <Typography className="w80px">{t(KeyTranslation.Size)}</Typography>
                <Typography className="w200px">
                  {`${((selectedItem?.size ?? 0) / 1024).toFixed(2)} KB`}
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="w80px">{t(KeyTranslation.File_Type)}</Typography>
                <Typography className="w200px">{selectedItem?.type}</Typography>
              </Box>
              <Box className="flex">
                <Typography className="w80px">{t(KeyTranslation.Modified)}</Typography>
                <Typography className="w200px">
                  {selectedItem?.updatedBy}
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="w80px">{t(KeyTranslation.Document_Type)}</Typography>
                <Typography className="w200px">
                  {selectedItem?.projectId === 0
                    ? t(KeyTranslation.Product_Documents)
                    : t(KeyTranslation.Project_Documents)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}
