import React from 'react'
import {
  DataGrid, gridClasses, type DataGridProps
} from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: theme.palette.text.primary,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '--unstable_DataGrid-headWeight': 600,
  '& .MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.divider
  },
  '& .MuiDataGrid-cell': {
    paddingLeft: 12,
    paddingRight: 12
  },
  '& .MuiDataGrid-footerContainer': {
    border: 0
  },
  '& .MuiDataGrid-row--editing': {
    boxShadow: 'none'
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    padding: 0
  }
}))

export default function DataGridCustom ({ columns, rows, ...otherProps }: DataGridProps): JSX.Element {
  return <StyledDataGrid sx={{
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none'
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: 'none'
      }
  }} initialState={{
    pagination: { paginationModel: { pageSize: 10 } }
  }} columns={columns} rows={rows} rowSelection={false} disableColumnMenu={true} pageSizeOptions={[10, 15, 25]} {...otherProps} />
}
