import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography
} from '@mui/material'
import { AlertError } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { type UserChoicesReportProps } from './modalContainerReport'
import { useQuery } from '@tanstack/react-query'
import { NoteApi, type NoteDto, SystemApi, type SystemDto } from '../../../common/services'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface SystemReportModalProps {
  open: boolean
  onNextStep: () => void
  onBackStep: () => void
  saveUserChoice: (choice: any) => void
  userChoices?: UserChoicesReportProps | null
}

export default function SystemReportModal ({
  onNextStep,
  onBackStep,
  saveUserChoice,
  userChoices
}: SystemReportModalProps): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const projectId = Number(id.id)
  const { data: SystemData, refetch: SystemRefetch } = useQuery(
    ['/api/v2/System/{id}', projectId],
    async () => {
      const { data } = await new SystemApi().apiV2SystemGetAllProjectIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  const { data: NoteData, refetch: NoteRefetch } = useQuery(
    ['/api/v2/Note/GetByProjectId', projectId],
    async () => {
      const { data } = await new NoteApi().apiV2NoteGetByProjectIdGet(
        projectId
      )
      return data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void SystemRefetch()
    void NoteRefetch()
  }, [])
  const [systemReportData] = React.useState({
    reportSystems: [],
    reportNotes: []
  })
  React.useEffect(() => {
    void formik.setValues({
      ...systemReportData,
      reportSystems: userChoices?.reportSystems ?? [],
      reportNotes: userChoices?.reportNotes ?? []
    })
  }, [userChoices])

  const handleSubmit = (): void => {
    saveUserChoice(formik.values)
    onNextStep()
  }
  const validationSchema = Yup.object().shape({
    reportSystems: Yup.array()
      .of(Yup.object().shape({
        systemId: Yup.number().required(t(KeyTranslation.Systems_Invalid) ?? '')
      }))
      .min(1, t(KeyTranslation.Systems_Invalid) ?? '')
  })

  const formik = useFormik({
    initialValues: systemReportData,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <AlertError formik={formik} />
        <Box my={4}>
          <Typography>
            {t(KeyTranslation.Choose_The_Systems_And_Project_Notes)}
          </Typography>
        </Box>
        <Box mt={4} mb={2}>
          <Box>
            <Typography pb={2} variant="h4">{t(KeyTranslation.Report_Systems)}</Typography>
          </Box>
          <Box
           maxHeight={185}
           sx={{ overflow: 'scroll', overflowX: 'hidden' }}
          >
            {SystemData?.data?.map((system: SystemDto) => (
              <MenuItem key={system.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.reportSystems.some(
                        (doc: any) => doc.systemId === system.id
                      )}
                      onChange={(event) => {
                        const isChecked = event.target.checked
                        const existingSelected = formik.values.reportSystems
                        if (isChecked) {
                          const newSelected = [
                            ...existingSelected,
                            { systemId: system.id }
                          ]
                          void formik.setFieldValue(
                            'reportSystems',
                            newSelected
                          )
                        } else {
                          const newSelected = existingSelected.filter(
                            (doc: any) => doc.systemId !== system.id
                          )
                          void formik.setFieldValue(
                            'reportSystems',
                            newSelected
                          )
                        }
                      }}
                    />
                  }
                  label={system.name}
                />
              </MenuItem>
            ))}
          </Box>
          { SystemData?.data?.length === 0 &&
              (
                <Typography variant="body2" color="textSecondary">
                 {t(KeyTranslation.No_Data_Found)}
                </Typography>
              )}
        </Box>
        <Box my={4}>
          <Box>
            <Typography pb={2} variant="h4">{t(KeyTranslation.Note_Notes)}</Typography>
          </Box>
          <Box
           maxHeight={185}
           sx={{ overflow: 'scroll', overflowX: 'hidden' }}
          >
            {NoteData?.data?.map((note: NoteDto) => (
              <MenuItem key={note.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.reportNotes.some(
                        (doc: any) => doc.noteId === note.id
                      )}
                      onChange={(event) => {
                        const isChecked = event.target.checked
                        const existingSelected = formik.values.reportNotes
                        if (isChecked) {
                          const newSelected = [
                            ...existingSelected,
                            { noteId: note.id }
                          ]
                          void formik.setFieldValue('reportNotes', newSelected)
                        } else {
                          const newSelected = existingSelected.filter(
                            (doc: any) => doc.noteId !== note.id
                          )
                          void formik.setFieldValue('reportNotes', newSelected)
                        }
                      }}
                    />
                  }
                  label={note.title}
                />
              </MenuItem>
            ))}
            { NoteData?.data?.length === 0 &&
              (
                <Typography variant="body2" color="textSecondary">
                 {t(KeyTranslation.No_Data_Found)}
                </Typography>
              )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" gap={4}>
          <Button onClick={onBackStep}>{t(KeyTranslation.Common_Back)}</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t(KeyTranslation.next)}
          </Button>
        </Box>
      </form>
    </>
  )
}
